import { z } from "zod";

export const FileNumber = z.string().min(1);
export type FileNumber = z.infer<typeof FileNumber>;

export const ClientID = z.string().min(1);
export type ClientID = z.infer<typeof ClientID>;

export const InvoiceNumber = z.string().min(1);
export type InvoiceNumber = z.infer<typeof InvoiceNumber>;

export const AssociatedJob = z.object({
  fileNumber: FileNumber,
  assocType: z.enum(["client", "billTo", "intendedUser"]),
});
export type AssociatedJob = z.infer<typeof AssociatedJob>;

export const Client = z.object({
  id: ClientID,
  name: z.string().optional(),
  nameLower: z.string().optional().describe("Used for searching case insensitive"),
  address1: z.string().optional(),
  address2: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zipcode: z.string().optional(),
  phone: z.string().optional(),
  fax: z.string().optional(),
  email: z.string().optional(),
  contactName: z.string().optional(),
  title: z.string().optional(),
  associatedJobs: z.array(AssociatedJob).optional(),
  associatedInvoices: z.array(InvoiceNumber).optional(),
});
export type Client = z.infer<typeof Client>;

export const Transaction = z.object({
  id: z.string(),
  invoiceNumber: InvoiceNumber,
  // This is positive for payments, negative for credits
  amountDollars: z.number().optional(),
  appliedOn: z.date().nullish(),
  description: z.string().optional(),
});
export type Transaction = z.infer<typeof Transaction>;

export const TimeBlock = z.object({
  hoursSpent: z.number().positive(),
  day: z.date().nullish(),
  description: z.string().optional(),
});
export type TimeBlock = z.infer<typeof TimeBlock>;

export const LineItem = z.object({
  id: z.string(),
  invoiceNumber: InvoiceNumber,
  description: z.string().optional(),
  amountDollars: z.number().optional(),
});
export type LineItem = z.infer<typeof LineItem>;

export const InvoiceStatus = z.enum([
  "generated",
  "sent",
  "closed",
]);
export type InvoiceStatus = z.infer<typeof InvoiceStatus>;

export const Invoice = z.object({
  invoiceNumber: InvoiceNumber,
  title: z.string().optional(),
  createdOn: z.date().optional(),
  deliveredOn: z.date().nullish(),
  billToId: ClientID.nullish(),
  // Can be set even with an outstanding balance if the invoice is going to be written off as
  // uncollectable.
  closedOn: z.date().nullish(),

  status: InvoiceStatus.optional(),
  totalDollars: z.number().nullish().describe("total price of the items in the invoice"),
  paidDollars: z.number().nullish(),
  associatedFileNumbers: z.array(FileNumber).optional(),
  billToName: z.string().nullish(),
  billToNameLower: z.string().nullish(),
})
export type Invoice = z.infer<typeof Invoice>;

export function invoiceStatus(i: Invoice): InvoiceStatus {
  if (i.closedOn) {
    return "closed";
  } else if (i.deliveredOn) {
    return "sent";
  } else {
    return "generated";
  }
}

export function invoiceIsPaid(i: Invoice): boolean {
  if (!i.closedOn) return false;
  // Check if invoice should be marked as paid now.
  const totalDollars = i.totalDollars ?? 0;
  const paidDollars = i.paidDollars ?? 0;
  return (totalDollars > 0 || paidDollars > 0) && totalDollars - paidDollars <= 0;
}

export const AppraiserInitials = z.string().min(2);
export type AppraiserInitials = z.infer<typeof AppraiserInitials>;

export const Appraiser = z.object({
  initials: AppraiserInitials,
  name: z.string().optional(),
  commissionPercent: z.number().nullish(),
  hourlyRateDollars: z.number().nullish(),
  isActive: z.boolean().optional(),
  hireDate: z.date().nullish(),
  licenseNumber: z.string().optional(),
});
export type Appraiser = z.infer<typeof Appraiser>;

export const PayoutID = z.string().min(1);
export type PayoutID = z.infer<typeof PayoutID>;

export const Payout = z.object({
  id: PayoutID,
  initials: AppraiserInitials,
  createdOn: z.date().optional(),
  paidOn: z.date().nullish(),
  includeDelivered: z.boolean().optional(),
  earnedDollars: z.number().nullish().describe("The total $s earned in commission that should be paid out"),
  paidDollars: z.number().nullish().describe("This is tracked separately in case the earned $s changes after the payment (due to a correction, etc.)"),
  billedDollars: z.number().nullish().describe("The dollars billed to clients backing this payout"),
  description: z.string().optional(),
  associatedInvoices: z.array(InvoiceNumber).optional(),
  associatedFileNumbers: z.array(FileNumber).optional(),
});
export type Payout = z.infer<typeof Payout>;

export const JobStatus = z.enum([
  "received",
  "assigned",
  "inspected",
  "reviewed",
  "delivered",
  "onHold",
  "closed",
]);
export type JobStatus = z.infer<typeof JobStatus>;

export const OpenJobStatuses: JobStatus[] = [
  "assigned",
  "received",
  "reviewed",
  "delivered",
  "inspected",
];

export const Assignment = z.object({
  initials: AppraiserInitials,
  fileNumber: FileNumber,
  commissionPercent: z.number().nullish(),
  hourlyRateDollars: z.number().nullish(),
  assignedOn: z.date().nullish(),
  isReviewer: z.boolean().optional(),
  timesheet: z.array(TimeBlock).optional(),
  isInvoicePaid: z.boolean().optional().describe("whether the associated job's invoice is paid or not"),
  status: JobStatus.optional(),
  commissionDollars: z.number().nullish().describe("amount be earned on commission"),
  payoutId: z.string().nullish(),
});
export type Assignment = z.infer<typeof Assignment>;

export function totalHourlyFee(a?: Assignment): number {
  return totalHoursSpent(a?.timesheet ?? []) * (a?.hourlyRateDollars ?? 0);
}

export function totalHoursSpent(timeSheet?: TimeBlock[]): number {
  return timeSheet?.reduce((totalHours, tb) => totalHours + tb.hoursSpent, 0) ?? 0;
}

export function totalCommissionOwed(job: Job, assignment: Assignment): number | undefined {
  if (!assignment.commissionPercent) return undefined;

  if (job.fee?.model === "fixed") {
    return (job.fee.priceDollars ?? 0) * (assignment.commissionPercent / 100);
  } else if (job.fee?.model === "hourly") {
    return totalHourlyFee(assignment) * (assignment.commissionPercent / 100);
  }
  return;
}

export const TagSet = z.array(z.string());
export type TagSet = z.infer<typeof TagSet>;

const FixedFee = z.object({
  model: z.literal("fixed"),
  priceDollars: z.number().optional(),
});

const HourlyFee = z.object({
  model: z.literal("hourly"),
});

export const Fee = z.discriminatedUnion("model", [FixedFee, HourlyFee]);
export type Fee = z.infer<typeof Fee>;

export const Job = z.object({
  fileNumber: FileNumber,
  project: z.string().nullish(),
  projectLower: z.string().nullish(),
  title: z.string().optional().describe("short title for the job"),
  comments: z.string().optional(),
  streetAddress: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zipcode: z.string().optional(),
  county: z.string().optional(),
  subdivisionOrProject: z.string().optional(),
  tags: TagSet.optional(),
  status: JobStatus.optional(),

  clientId: ClientID.nullish(),
  // DERIVED
  clientName: z.string().nullish().describe("Derived in cloud function from latest client name"),
  clientNameLower: z.string().nullish().describe("Derived in cloud function from latest client name"),

  clientRefNumber: z.string().nullish().describe("The ID that the client uses for this job"),

  billToId: ClientID.nullish(),
  // DERIVED
  billToName: z.string().nullish().describe("Derived in cloud function from latest name"),
  billToNameLower: z.string().nullish().describe("Derived in cloud function from latest name"),

  intendedUserId: ClientID.nullish(),
  // DERIVED
  intendedUserName: z.string().nullish().describe("Derived in cloud function from latest name"),
  intendedUserNameLower: z.string().nullish().describe("Derived in cloud function from latest name"),

  occupantName: z.string().optional(),
  occupantAddress: z.string().optional(),
  occupantCity: z.string().optional(),
  occupantState: z.string().optional(),
  occupantZipcode: z.string().optional(),
  occupantPhone: z.string().optional(),
  occupantEmail: z.string().optional(),

  ownerName: z.string().optional(),
  ownerAddress: z.string().optional(),
  ownerCity: z.string().optional(),
  ownerState: z.string().optional(),
  ownerZipcode: z.string().optional(),
  ownerPhone: z.string().optional(),
  ownerEmail: z.string().optional(),

  contactName: z.string().optional(),
  contactAddress: z.string().optional(),
  contactCity: z.string().optional(),
  contactState: z.string().optional(),
  contactZipcode: z.string().optional(),
  contactPhone: z.string().optional(),
  contactEmail: z.string().optional(),

  createdOn: z.date().nullish(),
  lastUpdatedOn: z.date().nullish(),
  receivedOn: z.date().nullish(),
  contactedOn: z.date().nullish(),
  inspectedOn: z.date().nullish(),
  reviewedOn: z.date().nullish(),
  dueOn: z.date().nullish(),
  deliveredOn: z.date().nullish(),
  effectiveDate: z.date().nullish(),
  onHoldOn: z.date().nullish(),
  offHoldOn: z.date().nullish(),
  closedOn: z.date().nullish(),

  fee: Fee.optional(),
  totalFeeDollars: z.number().optional().describe("derived from fixed fee or assignment timesheets if hourly"),
  totalHourlyDollars: z.number().optional().describe("derived from assignment timesheets if an hourly rate is present, regardless of fee model of job"),
  invoiceNumber: z.string().nullish(),
  appraisedValueDollars: z.number().optional(),

  // Derived
  paidOn: z.date().nullish(),
  isInvoicePaid: z.boolean().optional().describe("Reflects the state of the associated invoice"),
  mainAssignedInitials: z.array(AppraiserInitials).optional(),
  reviewerAssignedInitials: z.array(AppraiserInitials).optional(),
});
export type Job = z.infer<typeof Job>;

// Used to derive the totalFeeDollars field
export function jobTotalFee(appr: Job): number {
  if (!appr.fee?.model) {
    return 0;
  }

  if (appr.fee?.model === "fixed") {
    return appr.fee.priceDollars ?? 0;
  } else if (appr.fee?.model === "hourly") {
    return appr.totalHourlyDollars ?? 0;
  } else {
    return 0;
  }
}

export function jobStatus(a: Job): JobStatus {
  if (a.closedOn || a.paidOn) return "closed";
  else if (a.onHoldOn && !a.offHoldOn) return "onHold";
  else if (a.deliveredOn) return "delivered";
  else if (a.reviewedOn) return "reviewed";
  else if (a.inspectedOn) return "inspected";
  else if ((a.mainAssignedInitials?.length ?? 0) > 0) return "assigned";
  else return "received";
}

export const LocalUser = z.object({
  uid: z.string(),
  displayName: z.string().default(""),
  email: z.string().default(""),
  isAdmin: z.boolean().default(false),
});
export type LocalUser = z.infer<typeof LocalUser>;
