import { useContext, useEffect, useState } from "react";
import { Err, Ok, Result } from "ts-results-es";
import { StoreContext } from "./useStore";
import { Store, Unsubscriber, WatchOneCallback, WatchCollectionCallback } from "@/store/store";

// useStoreWatch(store => store.watchJob, fileNumber)
export function useStoreWatchOne<
  T,
  V,
>(
  f: (store: Store) => (arg: V, cb: WatchOneCallback<T>) => Unsubscriber,
  v: V,
): Result<T, any> | undefined {
  const store = useContext(StoreContext);
  const [res, setRes] = useState<Result<T, any> | undefined>(undefined);

  useEffect(() => {
    return f(store).call(store, v, (res: Result<T | undefined, any>) => {
      setRes(res.andThen(v => {
        if (v === undefined) {
          return Err("Item does not exist");
        }
        return Ok(v);
      }))
    });
  }, [store, v]);

  return res;
}

export function useStoreWatchCollection<
  T,
  V,
>(
  f: (store: Store) => (arg: V, cb: WatchCollectionCallback<T>) => Unsubscriber,
  v: V,
): Result<T[], any> | undefined {
  const store = useContext(StoreContext);
  const [res, setRes] = useState<Result<T[], any> | undefined>(undefined);

  console.log(v);
  useEffect(() => {
    return f(store).call(store, v, (res: Result<T[], unknown>) => {
      setRes(res);
    });
  }, [store, v]);

  return res;
}
