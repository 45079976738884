import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React from "react";
import { Controller, FieldPath, FieldValues, useFormContext } from "react-hook-form";

interface Props<F extends FieldValues, V extends FieldPath<F>> {
  name: V;
  label: string;
}

export default function DatePickerField<F extends FieldValues, V extends FieldPath<F> = FieldPath<F>>(props: Props<F, V>) {
  const { control, formState: { errors } } = useFormContext<F>();

  return <Controller
    name={props.name}
    control={control}
    render={({ field }) =>
      <DatePicker
        {...field}
        onChange={v => field.onChange(v?.toDate() ?? null)}
        value={field.value ? dayjs(field.value) : null}
        sx={{ width: '12em' }}
        slotProps={{
          field: { clearable: true },
          textField: {
            error: !!errors[props.name],
            variant: 'standard',
            helperText: errors[props.name]?.message?.toString() ?? "",
          },
        }}
        label={props.label} />
    } />;
}
