import { Appraiser, Job, Client, Invoice, FileNumber, Assignment, AppraiserInitials, LineItem, Transaction, InvoiceNumber, AssociatedJob, Payout, jobTotalFee, jobStatus, invoiceStatus, invoiceIsPaid, totalHourlyFee, totalCommissionOwed } from "@/model";
import { onSnapshot, Firestore, QueryFieldFilterConstraint, QueryFilterConstraint, and, getDocs, limit, orderBy, query, where, setDoc, runTransaction, getDoc, arrayUnion } from "firebase/firestore";
import { AppraiserConverter, ClientConverter, JobConverter, InvoiceConverter, AssignmentConverter, LineItemConverter, TransactionConverter, Converter, ConverterWithParent, PayoutConverter } from "./converters";
import { JobFilter, AppraiserFilter, InvoiceFilter, ClientFilter, Store, UNASSIGNED, Unsubscriber, WatchOneCallback, WatchCollectionCallback, PayoutFilter, AssignmentFilter } from "../store";
import { Err, Ok } from "ts-results-es";
import { ManagedTransaction } from "./tx";
import { diff } from "fast-array-diff";

//interface QueryInterface<T, F> {
//  list(...filters: F[]): Promise<T[]>;
//  watchOne(o: T, cb: (o?: T) => void): Unsubscriber;
//  addOrUpdate(o: T): Promise<void>;
//}

function watchOne<T>(converter: Converter<T>, db: Firestore, o: T, cb: WatchOneCallback<T>): Unsubscriber {
  return onSnapshot(converter.docRef(db, o), doc => {
    cb(Ok(doc.data()));
  }, err => {
    console.error(err);
    cb(Err(err));
  });
}

async function list<T, F>(converter: Converter<T> | ConverterWithParent<T, any>, db: Firestore, filterConverter: (f: F) => QueryFilterConstraint[], filters: F[], resultLimit: number): Promise<T[]> {
  const wheres: QueryFilterConstraint[] = [];
  for (const f of filters) {
    wheres.push(...filterConverter(f));
  }

  const q = query(converter.collectionRef(db), and(...wheres), limit(resultLimit));
  const qs = await getDocs(q);
  return qs.docs.map(d => d.data());
}

function addOrUpdate<T>(converter: Converter<T>, db: Firestore, o: T): Promise<void> {
  return setDoc(converter.docRef(db, o), o, { merge: true });
}

function watchCollection<T, F>(
  converter: Converter<T>,
  db: Firestore,
  filterConverter: (f: F) => QueryFilterConstraint[],
  filters: F[],
  cb: WatchCollectionCallback<T>,
): Unsubscriber {
  const wheres: QueryFilterConstraint[] = [];
  for (const f of filters) {
    wheres.push(...filterConverter(f));
  }

  return onSnapshot(
    query(converter.collectionRef(db), and(...wheres)),
    snapshot => {
      cb(Ok(snapshot.docs.map(d => d.data())));
    }, err => {
      console.error(err);
      cb(Err(err));
    });
}

async function listSubcollection<T, P>(
  converter: ConverterWithParent<T, P>,
  db: Firestore,
  p: P,
) {
  const q = query(converter.subcollectionRef(db, p));
  const qs = await getDocs(q);
  return qs.docs.map(d => d.data());
}

function watchSubcollection<T, P>(
  converter: ConverterWithParent<T, P>,
  db: Firestore,
  p: P,
  cb: WatchCollectionCallback<T>,
): Unsubscriber {
  return onSnapshot(converter.subcollectionRef(db, p), snapshot => {
    cb(Ok(snapshot.docs.map(d => d.data())));
  }, err => {
    console.error(err);
    cb(Err(err));
  });
}

export default class FireStoreStore implements Store {
  constructor(readonly db: Firestore) { }

  async listAppraisers(...filters: AppraiserFilter[]): Promise<Appraiser[]> {
    return list(AppraiserConverter, this.db, convertAppraiserFilter, filters, 1000);
  }

  watchAppraiser(initials: string, cb: WatchOneCallback<Appraiser>): Unsubscriber {
    return watchOne(AppraiserConverter, this.db, { initials }, cb);
  }

  async addOrUpdateAppraiser(appraiser: Appraiser): Promise<void> {
    return addOrUpdate(AppraiserConverter, this.db, appraiser);
  }

  watchPayout(id: string, cb: WatchOneCallback<Payout>): Unsubscriber {
    return watchOne(PayoutConverter, this.db, { id, initials: "" }, cb);
  }

  addOrUpdatePayout(payout: Payout): Promise<void> {
    return runTransaction(this.db, async tx => {
      const managedTx = new ManagedTransaction(tx);
      await this._updatePayoutTx(managedTx, payout);
      managedTx.commit();
    });
  }

  private async _updatePayoutTx(managedTx: ManagedTransaction, d: Payout) {
    const ref = PayoutConverter.docRef(this.db, d);
    const initials = d.initials;
    const existing = await managedTx.getCached(ref);

    // Make sure createdOn gets set
    if (!existing?.createdOn && !d.createdOn) {
      d.createdOn = new Date();
    }
    d.paidOn = d.paidOn ?? existing?.paidOn ?? null;

    managedTx.deferSet(ref, d, { merge: true });

    if (d.associatedFileNumbers) {
      for (const fileNumber of d.associatedFileNumbers ?? []) {
        await this._updateAssignmentTx(managedTx, {
          fileNumber,
          initials,
          payoutId: d.id,
        });
      }
    }
  }

  async updatePayoutWithPaidJobs({ id, initials, includeDelivered }: Payout): Promise<void> {
    const existingAssignments = await list(AssignmentConverter, this.db, convertAssignmentFilter, [
      { type: "appraiserInitials", initials: [initials] },
      { type: "payoutId", id: id },
    ], 1000);

    const newAssignments = await list(AssignmentConverter, this.db, convertAssignmentFilter, [
      { type: "appraiserInitials", initials: [initials] },
      { type: "payoutId", id: null },
      { type: "status", statuses: ["delivered", "closed"] },
    ], 1000);

    return await this.addOrUpdatePayout({
      id,
      initials,
      associatedFileNumbers: [...existingAssignments, ...newAssignments]
        .filter(a => includeDelivered || a.status === "closed")
        .filter(a => (a.commissionDollars ?? 0) > 0)
        .map(a => a.fileNumber),
    });
  }

  listPayouts(resultLimit: number, ...filters: PayoutFilter[]): Promise<Payout[]> {
    return list(PayoutConverter, this.db, convertPayoutFilter, filters, resultLimit);
  }

  async listJobs(...filters: JobFilter[]): Promise<Job[]> {
    return list(JobConverter, this.db, convertJobFilter, filters, 1000);
  }

  async addOrUpdateJob(job: Job): Promise<void> {
    return runTransaction(this.db, async tx => {
      const managedTx = new ManagedTransaction(tx);
      await this._updateJobTx(managedTx, job);
      managedTx.commit();
    });
  }

  private async _updateJobTx(managedTx: ManagedTransaction, job: Job) {
    const ref = JobConverter.docRef(this.db, job);
    const fileNumber = job.fileNumber;
    const existing = await managedTx.getCached(ref);
    const merged = { ...existing, ...job };

    merged.totalFeeDollars = jobTotalFee(merged);

    // Only lookup the invoice paid status and propagate it if the invoice number is new and the
    // job hasn't been marked as paid already.
    if (existing?.invoiceNumber !== merged.invoiceNumber && merged.invoiceNumber) {
      const invoiceNumber = merged.invoiceNumber;
      const invoice = await managedTx.getCached(InvoiceConverter.docRef(this.db, { invoiceNumber }));
      merged.isInvoicePaid = invoice ? invoiceIsPaid(invoice) : false;
    }

    // Clear paidOn if invoice is not paid
    merged.paidOn = merged.isInvoicePaid ?
      (merged.paidOn ?? (merged.isInvoicePaid ? new Date() : undefined)) :
      null;
    merged.status = jobStatus(merged);

    if (merged.project) {
      merged.projectLower = merged.project.toLowerCase();
    }
    if (merged.clientName) {
      merged.clientNameLower = merged.clientName.toLowerCase();
    }
    if (merged.billToName) {
      merged.billToNameLower = merged.billToName.toLowerCase();
    }
    if (merged.intendedUserName) {
      merged.intendedUserNameLower = merged.intendedUserName.toLowerCase();
    }

    if (existing?.totalFeeDollars !== undefined && (merged.totalFeeDollars !== existing?.totalFeeDollars) && existing?.isInvoicePaid) {
      throw new Error(`Cannot change job fee once the job has been paid. Pay went from ${existing?.totalFeeDollars} to ${merged.totalFeeDollars} for job ${merged.fileNumber}`);
    }

    for (const assocType of ["client", "billTo", "intendedUser"] as const) {
      const oldId = existing?.[`${assocType}Id`];
      const newId = merged?.[`${assocType}Id`];
      if (oldId !== newId) {
        if (oldId) {
          const clientRef = ClientConverter.docRef(this.db, { id: oldId });
          const existingClient = await managedTx.getCached(clientRef);
          merged[`${assocType}Name`] = null;
          merged[`${assocType}NameLower`] = null;
          await this._updateClientTx(managedTx, {
            id: oldId,
            associatedJobs: (existingClient?.associatedJobs ?? []).filter(s => {
              return s.fileNumber !== fileNumber && s.assocType !== assocType
            }),
          });
        }
        if (newId) {
          const clientRef = ClientConverter.docRef(this.db, { id: newId });
          const existingClient = await managedTx.getCached(clientRef);
          merged[`${assocType}Name`] = existingClient?.name;
          merged[`${assocType}NameLower`] = existingClient?.name?.toLowerCase();
          await this._updateClientTx(managedTx, {
            id: newId,
            associatedJobs: [...existingClient?.associatedJobs ?? [], { fileNumber, assocType }],
          });
        }
      }
    }

    managedTx.deferSet(JobConverter.docRef(this.db, { fileNumber }), Job.parse(merged), { merge: true });

    const existingFee = existing ? jobTotalFee(existing) : undefined;
    if (merged.invoiceNumber !== existing?.invoiceNumber || existingFee !== jobTotalFee(merged)) {
      if (existing?.invoiceNumber) {
        const invoiceNumber = existing.invoiceNumber;
        const invoice = await managedTx.getCached(InvoiceConverter.docRef(this.db, { invoiceNumber }));
        await this._updateInvoiceTx(managedTx, {
          invoiceNumber,
          associatedFileNumbers: invoice?.associatedFileNumbers?.filter(fn => fn !== fileNumber),
          totalDollars: (invoice?.totalDollars ?? 0) - (existingFee ?? 0),
        });
      }
      if (merged.invoiceNumber) {
        const invoiceNumber = merged.invoiceNumber;
        const invoice = await managedTx.getCached(InvoiceConverter.docRef(this.db, { invoiceNumber }));
        await this._updateInvoiceTx(managedTx, {
          invoiceNumber,
          associatedFileNumbers: dedup([...(invoice?.associatedFileNumbers ?? []), fileNumber]),
          totalDollars: (invoice?.totalDollars ?? 0) + (merged.totalFeeDollars ?? 0),
        });
      }
    }

    for (const initials of [...merged.mainAssignedInitials ?? [], ...merged.reviewerAssignedInitials ?? []]) {
      await this._updateAssignmentTx(managedTx, {
        fileNumber,
        initials,
      });
    }
  }

  watchJob(fileNumber: FileNumber, cb: WatchOneCallback<Job>): Unsubscriber {
    return watchOne(JobConverter, this.db, { fileNumber }, cb);
  }

  async suggestNextFileNumber(): Promise<string> {
    const q = query(JobConverter.collectionRef(this.db), and(where("fileNumber", "<=", "90")), orderBy("fileNumber", "desc"), limit(1));
    const qs = await getDocs(q);
    if (qs.empty) {
      return "1";
    }
    return qs.docs.map(d =>
      (Number.parseInt(d.data().fileNumber.replace(/\D/g, '')) + 1).toString()
    )[0]!;
  }

  watchAssignments(filters: AssignmentFilter[], cb: WatchCollectionCallback<Assignment>): Unsubscriber {
    return watchCollection(AssignmentConverter, this.db, convertAssignmentFilter, filters, cb);
  }

  listAssignments(filters: AssignmentFilter[]): Promise<Assignment[]> {
    return list(AssignmentConverter, this.db, convertAssignmentFilter, filters, 100);
  }

  async addOrUpdateAssignment(assignment: Assignment): Promise<void> {
    return runTransaction(this.db, async tx => {
      const managedTx = new ManagedTransaction(tx);
      await this._updateAssignmentTx(managedTx, assignment);
      managedTx.commit();
    });
  }

  private async _updateAssignmentTx(managedTx: ManagedTransaction, assignment: Assignment) {
    const fileNumber = assignment.fileNumber;
    const ref = AssignmentConverter.docRef(this.db, assignment);
    const existing = await managedTx.getCached(ref);
    const merged = { ...existing, ...assignment };

    let isReviewer = assignment.isReviewer;
    if (isReviewer === undefined) {
      isReviewer = existing?.isReviewer ?? false;
    }
    const job = await managedTx.getCached(JobConverter.docRef(this.db, { fileNumber }));

    merged.isInvoicePaid = job?.isInvoicePaid;
    merged.status = job?.status;

    // Make sure payout id is at least set to null for querying.
    if (!merged.payoutId) {
      merged.payoutId = null;
    }
    merged.commissionDollars = job ? totalCommissionOwed(job, merged) : null;

    managedTx.deferSet(ref, merged, { merge: true });

    const field = `${isReviewer ? "reviewer" : "main"}AssignedInitials` as const;
    const totalHourlyDollars = (job?.totalHourlyDollars ?? 0) + (
      totalHourlyFee({ ...existing, ...assignment }) - totalHourlyFee(existing)
    );
    if (!job?.[field]?.includes(assignment.initials) || totalHourlyDollars !== job?.totalHourlyDollars) {
      await this._updateJobTx(managedTx, {
        fileNumber,
        [field]: [...new Set([
          ...(job?.[field] ?? []),
          assignment.initials
        ])],
        totalHourlyDollars,
      });
    }

    merged.payoutId ||= null;

    if (existing?.payoutId) {
      const id = existing.payoutId;
      const initials = existing.initials;
      const d = await managedTx.getCached(PayoutConverter.docRef(this.db, { id, initials }));
      const earnedDollars = (d?.earnedDollars ?? 0) - (existing.commissionDollars ?? 0);
      await this._updatePayoutTx(managedTx, {
        id: existing.payoutId,
        initials: existing.initials,
        earnedDollars,
      });
    }
    if (merged.payoutId) {
      const id = merged.payoutId;
      const initials = merged.initials;
      const d = await managedTx.getCached(PayoutConverter.docRef(this.db, { id, initials }));
      const earnedDollars = (d?.earnedDollars ?? 0) + (merged.commissionDollars ?? 0);
      await this._updatePayoutTx(managedTx, {
        id: merged.payoutId,
        initials: merged.initials,
        earnedDollars,
      });
    }
  }

  async removeAssignment(fileNumber: FileNumber, initials: AppraiserInitials): Promise<void> {
    const ref = AssignmentConverter.docRef(this.db, { fileNumber, initials });
    return runTransaction(this.db, async tx => {
      const managedTx = new ManagedTransaction(tx);
      const existing = await managedTx.getCached(ref);
      const job = await managedTx.getCached(JobConverter.docRef(this.db, { fileNumber }));
      await this._updateJobTx(managedTx, {
        fileNumber,
        mainAssignedInitials: job?.mainAssignedInitials?.filter(i => i !== initials),
        reviewerAssignedInitials: job?.reviewerAssignedInitials?.filter(i => i !== initials),
        totalHourlyDollars: (job?.totalHourlyDollars ?? 0) - totalHourlyFee(existing),
      });
      tx.delete(ref);
      managedTx.commit();
    });
  }

  listLineItems(invoiceNumber: string): Promise<LineItem[]> {
    return listSubcollection(LineItemConverter, this.db, { invoiceNumber });
  }

  watchLineItems(invoiceNumber: string, cb: WatchCollectionCallback<LineItem>): Unsubscriber {
    return watchSubcollection(LineItemConverter, this.db, { invoiceNumber }, cb);
  }

  async addOrUpdateLineItem(lineItem: LineItem): Promise<void> {
    const ref = LineItemConverter.docRef(this.db, lineItem);
    return runTransaction(this.db, async tx => {
      const managedTx = new ManagedTransaction(tx);

      managedTx.deferSet(ref, lineItem, { merge: true });

      if (lineItem.amountDollars !== undefined) {
        const invoiceNumber = lineItem.invoiceNumber;
        const invoiceRef = InvoiceConverter.docRef(this.db, { invoiceNumber });
        const invoice = await tx.get(invoiceRef).then(d => d.data());
        await this._updateInvoiceTx(managedTx, {
          invoiceNumber,
          totalDollars: (invoice?.totalDollars ?? 0) + lineItem.amountDollars,
        });
      }

      managedTx.commit();
    });
  }

  async removeLineItem(invoiceNumber: string, id: LineItem['id']): Promise<void> {
    const ref = LineItemConverter.docRef(this.db, { invoiceNumber, id });
    return runTransaction(this.db, async tx => {
      const managedTx = new ManagedTransaction(tx);
      const li = await tx.get(ref).then(d => d.data());
      if (li?.amountDollars) {
        const invoiceRef = InvoiceConverter.docRef(this.db, { invoiceNumber });
        const invoice = await tx.get(invoiceRef).then(d => d.data());
        await this._updateInvoiceTx(managedTx, {
          invoiceNumber,
          totalDollars: (invoice?.totalDollars ?? 0) - li.amountDollars,
        });
      }
      tx.delete(ref);
      managedTx.commit();
    });
  }

  listTransactions(invoiceNumber: string): Promise<Transaction[]> {
    return listSubcollection(TransactionConverter, this.db, { invoiceNumber });
  }

  watchTransactions(invoiceNumber: string, cb: WatchCollectionCallback<Transaction>): Unsubscriber {
    return watchSubcollection(TransactionConverter, this.db, { invoiceNumber }, cb);
  }

  async addOrUpdateTransaction(transaction: Transaction): Promise<void> {
    const ref = TransactionConverter.docRef(this.db, transaction);
    return runTransaction(this.db, async tx => {
      const managedTx = new ManagedTransaction(tx);
      const existing = await managedTx.getCached(ref);

      managedTx.deferSet(ref, transaction, { merge: true });

      if (transaction.amountDollars) {
        const invoiceNumber = transaction.invoiceNumber;
        const invoiceRef = InvoiceConverter.docRef(this.db, { invoiceNumber });
        const invoice = await tx.get(invoiceRef).then(d => d.data());
        await this._updateInvoiceTx(managedTx, {
          invoiceNumber,
          paidDollars: (invoice?.paidDollars ?? 0) + transaction.amountDollars - (existing?.amountDollars ?? 0),
        });
      }
      managedTx.commit();
    });
  }

  async removeTransaction(invoiceNumber: string, id: Transaction['id']): Promise<void> {
    const ref = TransactionConverter.docRef(this.db, { invoiceNumber, id });
    return runTransaction(this.db, async tx => {
      const managedTx = new ManagedTransaction(tx);
      const transaction = await tx.get(ref).then(d => d.data());
      if (transaction?.amountDollars) {
        const invoiceRef = InvoiceConverter.docRef(this.db, { invoiceNumber });
        const invoice = await tx.get(invoiceRef).then(d => d.data());
        await this._updateInvoiceTx(managedTx, {
          invoiceNumber,
          paidDollars: (invoice?.paidDollars ?? 0) - transaction.amountDollars,
        });
      }
      tx.delete(ref);
      managedTx.commit();
    });
  }

  async listClients(resultLimit: number, ...filters: ClientFilter[]): Promise<Client[]> {
    return list(ClientConverter, this.db, convertClientFilter, filters, resultLimit);
  }

  addOrUpdateClient(client: Client): Promise<void> {
    const db = this.db;
    return runTransaction(db, async tx => {
      const managedTx = new ManagedTransaction(tx);
      await this._updateClientTx(managedTx, client);
      managedTx.commit();
    });
  }

  private async _updateClientTx(managedTx: ManagedTransaction, client: Client) {
    const ref = ClientConverter.docRef(this.db, client);
    const existing = await managedTx.getCached(ref);
    const merged = { ...existing, ...client };

    merged.nameLower = merged.name?.toLowerCase();

    managedTx.deferSet(ref, merged, { merge: true });

    const db = this.db;
    async function updateInvoiceNames(billToName: string, invoiceNumbers: InvoiceNumber[]) {
      for (const invoiceNumber of invoiceNumbers) {
        managedTx.deferSet(InvoiceConverter.docRef(db, { invoiceNumber }), {
          invoiceNumber,
          billToName,
          billToNameLower: billToName.toLowerCase(),
        }, { merge: true });
      }
    }

    async function updateJobNames(name: string, associated: AssociatedJob[]) {
      for (const { fileNumber, assocType } of associated) {
        managedTx.deferSet(JobConverter.docRef(db, { fileNumber }), {
          fileNumber,
          [`${assocType}Name`]: name,
          [`${assocType}NameLower`]: name.toLowerCase(),
        }, { merge: true });
      }
    }
    if (merged.name) {
      await updateInvoiceNames(merged.name, merged.associatedInvoices ?? []);
      await updateJobNames(merged.name, merged.associatedJobs ?? []);
    }
  }

  watchClient(id: string, cb: WatchOneCallback<Client>): Unsubscriber {
    return watchOne(ClientConverter, this.db, { id }, cb);
  }

  async getClient(id: string): Promise<Client> {
    const doc = await getDoc(ClientConverter.docRef(this.db, { id }));
    if (!doc.exists()) {
      throw new Error(`Client ${id} does not exist`);
    }
    return doc.data();
  }

  async listInvoices(...filters: InvoiceFilter[]): Promise<Invoice[]> {
    return list(InvoiceConverter, this.db, convertInvoiceFilter, filters, 1000);
  }

  async getInvoice(invoiceNumber: string): Promise<Invoice> {
    const doc = await getDoc(InvoiceConverter.docRef(this.db, { invoiceNumber }));
    if (!doc.exists()) {
      throw new Error(`Invoice ${invoiceNumber} does not exist`);
    }
    return doc.data();
  }

  async addOrUpdateInvoice(invoice: Invoice): Promise<void> {
    return runTransaction(this.db, async tx => {
      const managedTx = new ManagedTransaction(tx);
      await this._updateInvoiceTx(managedTx, invoice);
      managedTx.commit();
    });
  }

  private async _updateInvoiceTx(managedTx: ManagedTransaction, invoice: Invoice) {
    const ref = InvoiceConverter.docRef(this.db, invoice);
    const existing = await managedTx.getCached(ref);
    const invoiceNumber = invoice.invoiceNumber;
    const merged = { ...existing, ...invoice };

    merged.status = invoiceStatus(merged);

    if (merged.billToName) {
      merged.billToNameLower = merged.billToName.toLowerCase();
    }
    if (!merged.billToId) {
      merged.billToName = null;
      merged.billToNameLower = null;
    }

    if (!merged.createdOn) {
      merged.createdOn = new Date();
    }

    managedTx.deferSet(InvoiceConverter.docRef(this.db, { invoiceNumber }), merged, { merge: true });

    if (existing?.billToId !== merged.billToId) {
      if (existing?.billToId) {
        const clientRef = ClientConverter.docRef(this.db, { id: existing.billToId });
        const client = await managedTx.getCached(clientRef);
        await this._updateClientTx(managedTx, {
          id: existing.billToId,
          associatedInvoices: (client?.associatedInvoices ?? []).filter(i => i !== merged.invoiceNumber),
        });
      }

      if (merged.billToId) {
        const clientRef = ClientConverter.docRef(this.db, { id: merged.billToId });
        const client = await managedTx.getCached(clientRef);
        await this._updateClientTx(managedTx, {
          id: merged.billToId,
          associatedInvoices: [...(client?.associatedInvoices ?? []), merged.invoiceNumber],
        });
      }
    }

    if (merged.closedOn !== existing?.closedOn) {
      for (const fileNumber of merged.associatedFileNumbers ?? []) {
        await this._updateJobTx(managedTx, {
          fileNumber,
          isInvoicePaid: invoiceIsPaid(merged),
        });
      }
    }

    if (merged.associatedFileNumbers) {
      const fileNumberChanges = arrayDiffSorted(existing?.associatedFileNumbers ?? [], merged.associatedFileNumbers ?? []);
      for (const fileNumber of fileNumberChanges.added) {
        await this._updateJobTx(managedTx, {
          fileNumber,
          invoiceNumber,
        });
      }
      for (const fileNumber of fileNumberChanges.removed) {
        await this._updateJobTx(managedTx, {
          fileNumber,
          invoiceNumber: null,
        });
      }
    }
  }

  watchInvoice(invoiceNumber: string, cb: WatchOneCallback<Invoice>): Unsubscriber {
    return watchOne(InvoiceConverter, this.db, { invoiceNumber }, cb);
  }

  async suggestNextInvoiceNumber(): Promise<string> {
    const q = query(InvoiceConverter.collectionRef(this.db), orderBy("invoiceNumber", "desc"), limit(1));
    const qs = await getDocs(q);
    if (qs.empty) {
      return "1";
    }
    return qs.docs.map(d =>
      (Number.parseInt(d.data().invoiceNumber.replace(/\D/g, '')) + 1).toString()
    )[0]!;
  }

  async updateJobTags(fileNumber: FileNumber, ...newTags: string[]): Promise<void> {
    return setDoc(JobConverter.docRef(this.db, { fileNumber }), {
      fileNumber,
      tags: arrayUnion(...newTags),
    }, { merge: true });
  }
}

function convertJobFilter(f: JobFilter): (QueryFilterConstraint)[] {
  switch (f.type) {
    case "fileNumber":
      return [where("fileNumber", "in", f.fileNumbers.length ? f.fileNumbers : ["NONEXISTENT"])];
    case "fileNumberPrefix":
      return textRangeQuery("fileNumber", f.prefix);
    case "project":
      return textRangeQuery("projectLower", f.projectPrefix.toLowerCase());
    case "status":
      return [where("status", "in", f.statuses)];
    case "appraiserInitials":
      return [f.initials === UNASSIGNED ? where("mainAssignedInitials", "==", []) : where("mainAssignedInitials", "array-contains-any", f.initials)];
    case "createdAfter":
      return [where("createdOn", ">=", f.startDate)];
    case "createdBefore":
      return [where("createdOn", "<=", f.endDate)];
    case "tags":
      return [where("tags", "array-contains-any", f.tags)];
    case "clientName":
      return [
        where("clientNameLower", "==", f.clientName.toLowerCase()),
      ]
    case "clientNamePrefix":
      return textRangeQuery("clientNameLower", f.clientNamePrefix.toLowerCase());
    case "isPaid":
      return [where("isInvoicePaid", "==", f.isPaid)];
  }
}

function convertClientFilter(f: ClientFilter): QueryFieldFilterConstraint[] {
  switch (f.type) {
    case "clientName":
      return [where("nameLower", "==", f.clientName.toLowerCase())];
    case "clientNamePrefix":
      return textRangeQuery("nameLower", f.clientNamePrefix.toLowerCase());
  }
}

function convertInvoiceFilter(f: InvoiceFilter): QueryFilterConstraint[] {
  switch (f.type) {
    case "invoiceNumbers":
      return [where("invoiceNumber", "in", f.invoiceNumbers)];
    case "clientName":
      return [where("billToNameLower", "==", f.clientName.toLowerCase())];
    case "clientNamePrefix":
      return textRangeQuery("billToNameLower", f.clientNamePrefix.toLowerCase());
    case "invoiceStatus":
      return [where("status", "in", f.statuses)];
    case "createdAfter":
      return [where("createdOn", ">=", f.startDate)];
  }
}

function convertAssignmentFilter(f: AssignmentFilter): QueryFilterConstraint[] {
  switch (f.type) {
    case "appraiserInitials":
      if (f.initials === UNASSIGNED) {
        throw new Error("Cannot use unassigned filter in assignment filter");
      }
      if (f.initials.length === 1) {
        // Do this special case so that we don't have unnecessary "in" clauses which can be limited
        return [where("initials", "==", f.initials[0])];
      } else {
        return [where("initials", "in", f.initials)];
      }
    case "fileNumber":
      return [where("fileNumber", "in", f.fileNumbers)];
    case "status":
      return [where("status", "in", f.statuses)];
    case "isPaid":
      return [where("isInvoicePaid", "==", f.isPaid)];
    case "payoutId":
      return [where("payoutId", "==", f.id)];
  }
}

function convertAppraiserFilter(f: AppraiserFilter): QueryFilterConstraint[] {
  switch (f.type) {
    case "appraiserInitials":
      if (f.initials === UNASSIGNED) {
        throw new Error("Cannot use unassigned filter in appraiser filter");
      }
      return [where("initials", "in", f.initials)];
    case "appraiserIsActive":
      return [where("isActive", "==", f.isActive)];
  }
}

function convertPayoutFilter(f: PayoutFilter): QueryFilterConstraint[] {
  switch (f.type) {
    case "appraiserInitials":
      if (f.initials === UNASSIGNED) {
        throw new Error("Cannot use unassigned filter in payout filter");
      }
      return [where("initials", "in", f.initials)];
    case "paidAfter":
      return [where("paidOn", ">=", f.startDate)];
    case "payoutId":
      return [where("id", "==", f.id)];
    case "isPaid":
      return [where("paidOn", f.isPaid ? "!=" : "==", null)];
  }
}

function textRangeQuery(field: string, prefix: string): QueryFieldFilterConstraint[] {
  return [
    where(field, ">=", prefix),
    where(field, "<=", prefix + '\uf8ff'),
  ];
}

export function arrayDiffSorted<T>(a: T[], b: T[]): { added: T[], removed: T[] } {
  const sorter = (x: T, y: T) => JSON.stringify(x).localeCompare(JSON.stringify(y));
  a.sort(sorter);
  b.sort(sorter);
  return diff(a, b, (x, y) => JSON.stringify(x) === JSON.stringify(y));
}

export function arraysEqual<T>(a: T[], b: T[]): boolean {
  const { added, removed } = arrayDiffSorted(a, b);
  return added.length === 0 && removed.length === 0;
}

export function dedup<T>(a: T[]): T[] {
  return [...(new Set(a)).values()];
}
