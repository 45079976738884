import { Button, Stack, Typography } from "@mui/material";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import ItemWithTitle from "./helpers/ItemWithTitle";
import VisuallyHiddenInput from "./helpers/VisuallyHiddenInput";
import { getInvoiceTemplate, saveInvoiceTemplate } from "@/invoices/template";

export default function Settings() {
  const [curTemplate, setCurTemplate] = useState<Blob | undefined>();

  const selectTemplate = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files.length) return;
    const f = e.target.files.item(0)!;
    await saveInvoiceTemplate(f);
    e.target.value = "";
    setCurTemplate(f);
  }, []);

  useEffect(() => {
    getInvoiceTemplate().then(res => {
      if (res.isOk()) {
        setCurTemplate(res.value);
      }
    });
  });

  return <Stack direction="column" spacing={2}>
    <Typography variant="h4">Settings</Typography>

    <ItemWithTitle title="Invoice Template">
      <Stack direction="row" spacing={1} alignItems="center">
        {curTemplate ?
          <Typography><a download="invoice.template.docx" href={URL.createObjectURL(curTemplate)}>Current Template</a></Typography> :
          <Typography color="text.secondary">None</Typography>}
        <Button component="label" variant="outlined">
          <VisuallyHiddenInput onChange={selectTemplate} accept=".docx" type="file" />
          Upload Template
        </Button>
      </Stack>
    </ItemWithTitle>
  </Stack>
}
