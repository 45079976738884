import { errorToString } from "@/format/errors";
import { Appraiser } from "@/model";
import { Alert, Button, CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";
import ItemWithTitle from "./helpers/ItemWithTitle";
import { formatDate } from "./helpers/DateDisplay";
import formatDollarsCents from "@/format/formatDollarsCents";
import { Link, useLocation } from "wouter";
import { JobStatusOption } from "./JobList";
import { useStoreWatchOne } from "@/hooks/useStoreWatch";

export default function AppraiserDetailByInitials(props: { initials: string }) {
  const appraiserResult = useStoreWatchOne(s => s.watchAppraiser, props.initials);

  if (!appraiserResult) {
    return <CircularProgress />;
  }

  if (appraiserResult.isErr()) {
    return <Alert severity="error">Failed to load appraiser {props.initials}: {errorToString(appraiserResult.error)}</Alert>
  }

  const appraiser = appraiserResult.value;
  if (!appraiser) {
    return <Typography color="text.error">Appraiser {props.initials} not found</Typography>;
  }

  return <AppraiserDetail appraiser={appraiser} />;
}

function AppraiserDetail({ appraiser }: { appraiser: Appraiser }) {
  const [_, setLocation] = useLocation();

  return <Stack direction="column" spacing={2}>
    <Stack direction="row" spacing={2}>
      <Button variant="contained" onClick={() => setLocation(`/appraisers/${appraiser.initials}/edit`)}>Edit</Button>
    </Stack>
    <Stack direction="row" spacing={2}>
      <ItemWithTitle title="initials">
        <Typography>{appraiser.initials}</Typography>
      </ItemWithTitle>
      <ItemWithTitle title="name">
        <Typography>{appraiser.name}</Typography>
      </ItemWithTitle>
      <ItemWithTitle title="hire date">
        <Typography>{appraiser.hireDate ? formatDate(appraiser.hireDate) : "Unknown"}</Typography>
      </ItemWithTitle>
      <ItemWithTitle title="license number">
        <Typography>{appraiser.licenseNumber ?? "Unknown"}</Typography>
      </ItemWithTitle>
    </Stack>
    <Stack direction="row" spacing={2}>
      <ItemWithTitle title="is active">
        <Typography fontWeight="bold">{appraiser.isActive ? "Yes" : "No"}</Typography>
      </ItemWithTitle>
      <ItemWithTitle title="default commission">
        <Typography>{appraiser.commissionPercent ? `${appraiser.commissionPercent}%` : "None"}</Typography>
      </ItemWithTitle>
      <ItemWithTitle title="default hourly rate">
        <Typography>{appraiser.hourlyRateDollars ? `${formatDollarsCents(appraiser.hourlyRateDollars)}/hr` : "None"}</Typography>
      </ItemWithTitle>
    </Stack>
    <Stack direction="row" spacing={2}>
      <ItemWithTitle title="open jobs">
        <Link href="/jobs" state={{ filterOptions: { initials: appraiser.initials, status: JobStatusOption.OPEN } }}><Typography>See Open Jobs</Typography></Link>
      </ItemWithTitle>
    </Stack>
  </Stack>;
}
