import { useStoreValue } from "@/hooks/useStore";
import { InvoiceNumber } from "@/model";
import { Alert, Button, CircularProgress, Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import { DialogProps } from "@/hooks/useDialog";
import { errorToString } from "@/format/errors";
import { AttachMoney } from "@mui/icons-material";


export function LinkInvoiceDialog({ open, onClose, onSave, error }: DialogProps<InvoiceNumber | null>) {
  const openInvoices = useStoreValue(s =>
    s.listInvoices({ type: "invoiceStatus", statuses: ["generated"] }).then(inv => inv.sort((a, b) => b.invoiceNumber.localeCompare(a.invoiceNumber))),
    []);

  return <Dialog onClose={() => { onClose(); }} open={open}>
    <DialogTitle>Link Job to Invoice</DialogTitle>
    {!openInvoices ?
      <CircularProgress /> :
      openInvoices.isErr() ?
        <Alert severity="error">Failed to load open invoices: {errorToString(openInvoices.error)}</Alert> :
        openInvoices.value.length === 0 ?
          <Typography p={2}>No open invoices currently (not sent or closed)</Typography> :
          <List dense>
            {openInvoices.value.map(inv => <ListItem disablePadding key={inv.invoiceNumber}>
              <ListItemButton onClick={() => {
                onSave(inv.invoiceNumber);
              }}>
                <ListItemIcon>
                  <AttachMoney />
                </ListItemIcon>
                <ListItemText
                  primary={<>
                    <Typography fontWeight="bold" component="span">Invoice #{inv.invoiceNumber}</Typography>
                    <Typography component="span"> - {inv.title ?? ""}</Typography>
                  </>}
                  secondary={`Bill to: ${inv.billToName}`}
                />
              </ListItemButton>
            </ListItem>
            )}
          </List>}
    <Button variant="text" onClick={() => { onSave(null) }}>Create New Invoice</Button>
    {error ? <Alert severity="error">{error}</Alert> : null}
  </Dialog>;
}

