import formatDollarsCents from "@/format/formatDollarsCents";
import useHover from "@/hooks/useHover";
import { useStoreWatchOne } from "@/hooks/useStoreWatch";
import { FileNumber } from "@/model"
import { Delete } from "@mui/icons-material";
import { IconButton, ListItem, ListItemText } from "@mui/material";
import React from "react";
import { Link } from "wouter";

interface Props {
  fileNumber: FileNumber;
  onDelete: () => void;
}
export default function JobListItem({ fileNumber, onDelete }: Props) {
  const [hovering, hoverProps] = useHover();

  const jobRes = useStoreWatchOne(s => s.watchJob, fileNumber);
  const job = jobRes?.unwrapOr(null);

  return <ListItem {...hoverProps} secondaryAction={
    hovering ? <IconButton onClick={onDelete} title="Delete Assignment"><Delete /></IconButton> : null
  }>
    <ListItemText
      sx={{ pr: 1 }}
      primary={<Link href={`/jobs/${fileNumber}`}>Job #{fileNumber}</Link>}
      secondary={job?.totalFeeDollars ? formatDollarsCents(job.totalFeeDollars) : ""}
    />

  </ListItem>;
}

