
type TagKey = string;
type TagDescription = string;

export const Tags: Map<TagKey, TagDescription> = new Map(Object.entries({
  "#APT": "# of Unit Apartments",
  "#DUP": "# of Duplexes",
  "#HSE": "#of houses (several houses rented & owned by same owner)",
  "AE": "Aviation Easement",
  "AGRI": "Agriculture",
  "AIR": "Airport",
  "APT": "Apartment",
  "BAK": "Bakeries",
  "BANK": "Bank",
  "BH": "Boiler House",
  "BOTT": "Bottling Plants",
  "BOWL": "Bowling Centers",
  "BP": "Borrow Pit",
  "BROKER": "Brokerage File",
  "BV": "Business Valuation",
  "CANN": "Canneries",
  "CHURCH": "Church",
  "CLUB": "Club",
  "COLD": "Cold Storage",
  "COLL": "College",
  "COMM": "Commercial",
  "COMPS": "Comp Data Only",
  "CONDO": "Condos",
  "CONSER": "Conservation Easement",
  "CONST": "Construction Company",
  "CONSULT": "Consultation",
  "CONTAM": "Contamination",
  "CONV": "Convent",
  "COPY": "Copy of Report",
  "COURT": "Court Testimony",
  "CP": "Commercial Property (land and building)",
  "CSITE": "Commercial Site (land only)",
  "CS": "Convenience Store",
  "CWASH": "Car Wash",
  "DAIR": "Dairy",
  "DAM": "Damage Appraisal",
  "DC": "Daycare",
  "DEFCON": "Defective Construction",
  "DEALER": "Dealership (auto, truck, tractor, boat, etc..)",
  "DEMO": "Demolition Cost",
  "DEV": "Development land (residential, commercial, industrial, etc...)",
  "DI": "Drive Ins",
  "DIST": "Distribution",
  "DORM": "Dormitory",
  "DREV": "Desk Review",
  "DUPLEX": "Duplex",
  "ESTATE": "Estate Purposes",
  "FARM": "Farms",
  "FEC": "Family Entertainment Center",
  "FH": "Funeral Home",
  "FINAL": "Final Inspection",
  "FIRE": "Fire Station, Rescue Squad",
  "FLOOD": "Flood Plain",
  "FRACT": "Fractional Interest",
  "FREV": "Field Reveiws",
  "FS": "Franchise Site (restaurant, bank, chain store, etc...)",
  "GAR": "Garages, Parking, Body Shops",
  "GE": "Grain Elevators",
  "GH": "Group Home",
  "GIFT": "Gift Tax",
  "GOLF": "Golf Courses or Country Clubs with Golf Courses",
  "GROC": "Grocery Store, Supermarket",
  "GYM": "Gymnasium",
  "HOG": "Hog or Pig Farms",
  "HORSE": "Horse Farm",
  "HOSP": "Hospital",
  "HOTEL": "Hotel",
  "INDUST": "Industrial",
  "INSP": "Inspection (Re-Inspection, 1st Inspection)",
  "INST": "Institutional",
  "INVFRM": "Investment Firm",
  "IP": "Industrial Property (land and building)",
  "IS": "Industrial Site (land only)",
  "LAUND": "Laundry Mats",
  "LB": "Loss of Business",
  "LEASE": "Lease Purpose",
  "LIB": "Library",
  "LIGHT": "Lights",
  "LISTINGS": "Listings Only",
  "LS": "Lumber Storage",
  "LT": "Land in Transition",
  "LUMB": "Lumber Company",
  "MC": "Medical Clinic",
  "MCS": "Medical Clinic Site",
  "MF": "Multi Family",
  "MFG": "Manufacturing",
  "MFS": "Multi Family Site",
  "MHP": "Mobile Home Park",
  "MINING": "Mining Company, Mining Operations",
  "MISC": "Other Files",
  "MOTEL": "Motel",
  "MOVIE": "Movie Theatre",
  "MRENTR": "Market Rental Rates",
  "MS": "Moving and Storage Company",
  "NOISE": "Noise (Highway, train, etc...)",
  "NURS": "Nursing Home",
  "OFF": "Office",
  "OIL": "Oil Company",
  "PE": "Pipeline Easement",
  "PI": "Partial Interest",
  "PLE": "Power Line Easement",
  "PND": "Planned Neighborhood Development",
  "PNUR": "Plant Nursery",
  "PO": "Post Office",
  "POULT": "Poultry",
  "PRE-EN": "Pre-Engineered Building",
  "PROP": "Personal Property",
  "PT": "Partial Taking (before & after appraisals)",
  "RADIO": "Radio Station",
  "RAIL": "Railroad",
  "RE": "Roadway Easement (Highway, Driveway. etc...)",
  "REA": "Real Estate Agency",
  "REC": "Recreation Facility",
  "RECERT": "Recertification",
  "RECT": "Rectory",
  "REH": "Real Estate Management Company",
  "REIT": "Real Estate Investment Trust",
  "RESORT": "Resort",
  "REST": "Restaurant",
  "RESTRICT": "Restricted Report",
  "RETAIL": "Retail Stores or Shops (paper Co. office supply, furniture, etc...)",
  "RH": "Rest Home",
  "RP": "Residential Property",
  "RPR": "Repair Company",
  "RR": "Railroad Easement",
  "RS": "Residential Site",
  "RT": "Radio Tower",
  "S&L": "Savings and Loan",
  "SC": "Shopping Center",
  "SCH": "School",
  "SE": "Sight Easement",
  "SFR": "Single Family Residential",
  "SLE": "Sewer Line Easement",
  "SMOKE": "Smoke",
  "SPEC": "Special Use",
  "SPEEDW": "Speedways, Race Tracks",
  "SS": "Service Station",
  "SSHOP": "Service Shop",
  "STIG": "Stigma (Murder, etc...)",
  "SUPPLY": "Supply House",
  "SUR": "Survey Company",
  "TAXAPP": "Tax Appeal",
  "TEXT": "Textile Mills",
  "TIME": "Time on Assignment",
  "TK": "DOT Marking/Taking",
  "TRANSF": "Transfer",
  "TREE": "Tree Farm",
  "TRUCK": "Trucking Company",
  "TT": "Truck Terminal",
  "UPDATE": "Updated changes on appraisal & additional time/Revisions",
  "VERBAL": "Verbal Report",
  "VIEW": "Loss of View",
  "VL": "Vacant Lot (under 3 acres)",
  "VS": "Vacant Site (over 3 acres)",
  "WATER": "Water/Waste Treatment Plant/Tower",
  "WF": "Waterfront",
  "WHOLES": "Wholesale Company (Auto Auctions, etc...)",
  "WHSE": "Warehouse (mini-storage buildings)",
  "WP": "Water Parks",
  "WT": "Waste Treatment",
  "WWD": "Water Well Driller",
}));
