import { useActiveAppraisers } from "@/hooks/useStore";
import { Assignment } from "@/model";
import React, { useEffect } from "react";
import { Alert, Autocomplete, Button, Checkbox, Dialog, DialogTitle, FormControlLabel, FormGroup, InputAdornment, Stack, TextField } from "@mui/material";
import { DialogProps } from "@/hooks/useDialog";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import formatDollarsCents from "@/format/formatDollarsCents";

export function AddOrEditAssignmentDialog({ open, onClose, onSave, value, error }: DialogProps<Assignment>) {
  const activeApprisers = useActiveAppraisers();
  const methods = useForm<Assignment>({
    resolver: zodResolver(Assignment),
  });
  const { handleSubmit, formState: { errors }, register, reset, watch } = methods;
  const initials = watch("initials");

  let defaultCommission: number | undefined;
  if (initials) {
    defaultCommission = activeApprisers?.get(initials)?.commissionPercent ?? undefined;
  }

  let defaultHourlyRate: number | undefined;
  if (initials) {
    defaultHourlyRate = activeApprisers?.get(initials)?.hourlyRateDollars ?? undefined;
  }

  useEffect(() => {
    reset(value ?? { initials: "" });
  }, [value, open]);

  return <Dialog onClose={() => { onClose(); }} open={open}>
    <DialogTitle>{value?.initials ? "Edit" : "Add"} Assignment</DialogTitle>
    <form onSubmit={handleSubmit(vals => onSave(vals), err => console.error(err))}>
      <FormProvider {...methods}>
        <Stack direction="column" spacing={2} sx={{ padding: 2 }} useFlexGap>
          <Controller
            name="initials"
            render={({ field }) =>
              <Autocomplete
                {...field}
                value={field.value ? field.value : null}
                onChange={(_, v) => field.onChange(v)}
                loading={activeApprisers === undefined}
                filterOptions={(options) => options}
                selectOnFocus
                autoHighlight
                clearOnBlur
                includeInputInList
                handleHomeEndKeys
                options={activeApprisers ? [...activeApprisers.values()].map(a => a.initials) : []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled={!!value?.initials}
                    error={!!errors.initials}
                    helperText={errors.initials?.message}
                    variant="standard"
                    label="Appraiser Initials" />
                )} />}
          />
          <TextField
            {...register("commissionPercent", {
              required: false,
              setValueAs: v => v ? Number.parseFloat(v) : 0,
            })}
            variant="standard"
            label="Commission"
            error={!!errors.commissionPercent}
            helperText={errors.commissionPercent ?
              errors.commissionPercent?.message :
              defaultCommission ?
                `Standard for ${initials} is ${defaultCommission}%` :
                null
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }} />
          <TextField
            {...register("hourlyRateDollars", {
              required: false,
              setValueAs: v => v ? Number.parseFloat(v) : 0,
            })}
            variant="standard"
            error={!!errors.hourlyRateDollars}
            helperText={errors.hourlyRateDollars ?
              errors.hourlyRateDollars?.message :
              defaultHourlyRate ? 
               `Standard for ${initials} is ${formatDollarsCents(defaultHourlyRate)}` :
               null
            }
            label="Hourly Rate"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              endAdornment: <InputAdornment position="end">/hr</InputAdornment>,
            }} />
          <FormGroup>
            <FormControlLabel control={
              <Controller
                name="isReviewer"
                render={({ field }) =>
                  <Checkbox {...field} checked={!!field.value} />
                } />
            } label="Is Reviewer" />
          </FormGroup>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button variant="text" onClick={() => onClose()}>Cancel</Button>
            <Button type="submit">{value?.initials ? "Edit" : "Add"} Assignment</Button>
          </Stack>
          {error ? <Alert severity="error">{error}</Alert> : null}
        </Stack>
      </FormProvider>
    </form>
  </Dialog>;
}

