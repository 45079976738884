import { Button, Stack, Typography } from "@mui/material";
import { Auth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React, { useCallback, useMemo, useState } from "react";

interface Props {
  firebaseAuth: Auth;
}

export default function Login(props: Props) {
  const [err, setErr] = useState<null | string>(null);
  const googleProvider = useMemo(() => {
    return new GoogleAuthProvider();
  }, [props.firebaseAuth]);

  const doLogin = useCallback(async () => {
    try {
      await signInWithPopup(props.firebaseAuth, googleProvider);
    } catch (e) {
      setErr(JSON.stringify(e));
    }
  }, [googleProvider]);

  return <Stack sx={{ maxWidth: 600 }} m="2em auto">
    <Typography>Sign in by clicking the button below</Typography>
    <Button variant="contained" onClick={doLogin}>Login with Google</Button>
    {err ? <Typography color="error">{err}</Typography> : null}
  </Stack>;
}
