import { FileNumber, Invoice } from "@/model";
import { Alert, Button, CircularProgress, List, Snackbar, Stack, Typography } from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import ItemWithTitle from "@/components/helpers/ItemWithTitle";
import { formatDate } from "@/components/helpers/DateDisplay";
import { Link, useLocation } from "wouter";
import InvoiceStatusChip from "@/components/helpers/InvoiceStatusChip";
import { errorToString } from "@/format/errors";
import { LineItemList } from "./LineItemList";
import { TransactionList } from "./TransactionList";
import { useStoreWatchOne } from "@/hooks/useStoreWatch";
import { StoreContext } from "@/hooks/useStore";
import wrapPromise from "@/util/wrapPromise";
import useDialog from "@/hooks/useDialog";
import formatDollarsCents from "@/format/formatDollarsCents";
import { generateInvoiceDocx } from "@/invoices/generate";
import { getInvoiceTemplate } from "@/invoices/template";
import LinkedJobListItem from "./LinkedJobListItem";
import { LinkJobDialog } from "./LinkJobDialog";
import ActionButton from "../helpers/ActionButton";

interface Props {
  invoiceNumber: string;
}

export default function InvoiceDetailByNumber({ invoiceNumber }: Props) {
  const invoiceResult = useStoreWatchOne(s => s.watchInvoice, invoiceNumber);

  if (!invoiceResult) {
    return <CircularProgress />;
  }

  if (invoiceResult.isErr()) {
    return <Alert severity="error">Failed to load invoice: {errorToString(invoiceResult.error)}</Alert>
  }

  const invoice = invoiceResult.value;
  if (!invoice) {
    return <Typography>Invoice {invoiceNumber} not found</Typography>;
  }

  return <InvoiceDetail invoice={invoice} />
}

export function InvoiceDetail({ invoice }: { invoice: Invoice }) {
  const [_, setLocation] = useLocation();
  const store = useContext(StoreContext);

  const linkJob = useCallback(async (fileNumber: FileNumber) => {
    return wrapPromise(store.addOrUpdateJob({
      fileNumber,
      invoiceNumber: invoice.invoiceNumber,
    }));
  }, [store, invoice.invoiceNumber]);

  const markBilled = useCallback(async (isBilled: boolean) => {
    return await store.addOrUpdateInvoice({
      invoiceNumber: invoice.invoiceNumber,
      deliveredOn: isBilled ? new Date() : null,
    });
  }, [store, invoice.invoiceNumber]);

  const markIsClosed = useCallback(async (isClosed: boolean) => {
    return await store.addOrUpdateInvoice({
      invoiceNumber: invoice.invoiceNumber,
      closedOn: isClosed ? new Date() : null,
    });
  }, [store, invoice.invoiceNumber]);

  const { showDialog: showLinkJobDialog, props: linkDialogProps } = useDialog((v: FileNumber) => linkJob(v), [linkJob]);

  return <Stack direction="column" spacing={2}>
    <Stack direction="row" spacing={2}>
      <Button variant="contained" onClick={() => setLocation(`/invoices/${invoice.invoiceNumber}/edit`)}>Edit</Button>
      {invoice.status !== "closed" ? <ActionButton onClick={() => { showLinkJobDialog() }}>Link Job</ActionButton> : null}
      {invoice.deliveredOn ?
        <ActionButton onClick={() => { markBilled(false) }}>Mark Not Billed</ActionButton> :
        <ActionButton onClick={() => { markBilled(true) }}>Mark Billed</ActionButton>
      }
      {invoice.closedOn ?
        <ActionButton onClick={() => { markIsClosed(false) }}>Reopen Invoice</ActionButton> :
        <ActionButton onClick={() => { markIsClosed(true) }}>Close Invoice</ActionButton>
      }
      <GenerateInvoiceButton invoiceNumber={invoice.invoiceNumber} />
      <LinkJobDialog {...linkDialogProps} />
    </Stack>
    <Typography variant="h6">Invoice #{invoice.invoiceNumber} - {invoice.title}</Typography>
    <Stack direction="row" spacing={2}>
      <ItemWithTitle title="billed on">
        {invoice.deliveredOn ?
          <Typography>{formatDate(invoice.deliveredOn)}</Typography> :
          <Typography color="text.secondary">Not Billed</Typography>}
      </ItemWithTitle>
      <ItemWithTitle title="closed on">
        {invoice.closedOn ?
          <Typography>{formatDate(invoice.closedOn)}</Typography> :
          <Typography color="text.secondary">Not Closed</Typography>}
      </ItemWithTitle>
      <ItemWithTitle title="status">
        <InvoiceStatusChip invoice={invoice} />
      </ItemWithTitle>
    </Stack>
    <Stack direction="row" spacing={2}>
      <ItemWithTitle title="Bill to">
        {invoice.billToId ?
          <Link href={`/clients/${invoice.billToId}`}><Typography>{invoice.billToName}</Typography></Link> :
          "Unknown"}
      </ItemWithTitle>
      <ItemWithTitle title="total fee">
        <Typography>{invoice.totalDollars ? formatDollarsCents(invoice.totalDollars) : "$0"}</Typography>
      </ItemWithTitle>
      <ItemWithTitle title="total paid">
        <Typography>{invoice.paidDollars ? formatDollarsCents(invoice.paidDollars) : "$0"}</Typography>
      </ItemWithTitle>
    </Stack>

    <ItemWithTitle title="Jobs">
      <JobsLinked invoice={invoice} />
    </ItemWithTitle>
    <ItemWithTitle title="Additional Line Items">
      <LineItemList invoiceNumber={invoice.invoiceNumber} />
    </ItemWithTitle>
    <ItemWithTitle title="Payments">
      <TransactionList invoiceNumber={invoice.invoiceNumber} />
    </ItemWithTitle>
  </Stack>
}

function JobsLinked({ invoice }: { invoice: Invoice }) {
  const store = useContext(StoreContext);
  const removeLink = useCallback((fileNumber: FileNumber) => {
    store.addOrUpdateInvoice({
      invoiceNumber: invoice.invoiceNumber,
      associatedFileNumbers: invoice!.associatedFileNumbers!.filter(fn => fn !== fileNumber),
    });
  }, [store, invoice]);

  return <Stack direction="column" spacing={2}>
    {invoice.associatedFileNumbers ?
      <List>
        {invoice.associatedFileNumbers.map(fn => <LinkedJobListItem fileNumber={fn} onDelete={() => { removeLink(fn) }} key={fn} />)}
      </List> :
      <Typography>No job linked to this invoice.</Typography>
    }
  </Stack>;
}

function GenerateInvoiceButton({ invoiceNumber }: { invoiceNumber: string }) {
  const store = useContext(StoreContext);
  const [err, setErr] = useState<React.ReactNode | undefined>();

  const generateDoc = useCallback(async () => {
    const templateRes = await getInvoiceTemplate();
    if (templateRes.isErr()) {
      setErr(`Invoice template is missing. Go to settings and upload it.`);
      return;
    }

    await generateInvoiceDocx(store, invoiceNumber, templateRes.value);
  }, [store, invoiceNumber]);

  return <>
    <Button variant="contained" onClick={generateDoc}>
      Export to Word
    </Button>
    <Snackbar open={!!err} autoHideDuration={6000}>
      <Alert severity="error" variant="filled" onClose={() => { setErr(undefined) }}>
        {err}
      </Alert>
    </Snackbar>
  </>;
}
