
// Format the given number as dollars with the fraction converted to cents. The $ sign is also
// prepended.
export default function formatDollarsCents(n: number, forceDecimals: boolean = true): string {
  return n.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: (Number.isInteger(n) && !forceDecimals) ? 0 : 2,
    currency: "USD",
    style: "currency",
  });
}
