import { Tags } from "@/tags";
import { Label } from "@mui/icons-material";
import { List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import React from "react";

export default function TagList() {
  return <Stack direction="column" spacing={2}>
    <Typography variant="h5">Tags Defined</Typography>
    <List dense>
      {[...Tags.values()].map(t => <ListItem key={t}>
        <ListItemIcon>
          <Label />
        </ListItemIcon>
        <ListItemText primary={t} />
      </ListItem>)}
    </List>
  </Stack>;
}
