import React, { MouseEvent, useCallback, useState } from "react";
import { StoreContext } from "@/hooks/useStore";
import { Store } from "@/store/store";
import { useHashLocation } from "wouter/use-hash-location";
import { Router, Route, Switch, Redirect, useLocation } from "wouter";
import { Alert, AppBar, Box, Button, Container, CssBaseline, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import { Auth } from "firebase/auth";
import InvoiceEdit, { InvoiceEditByInvoiceNumber } from "./InvoiceEdit";
import InvoiceDetail from "./InvoiceDetail";
import InvoiceList, { InvoiceStatusOption } from "./InvoiceList";
import AppraiserList from "./AppraiserList";
import AppraiserDetail from "./AppraiserDetail";
import TagList from "./TagList";
import ClientList from "./ClientList";
import { ClientDetailByID } from "./ClientDetail";
import AppraiserEdit, { AppraiserEditByInitials } from "./AppraiserEdit";
import { ClientEditByID } from "./ClientEdit";
import Import from "./admin/Import";
import PayoutList, { PayoutStatusOption } from "./PayoutList";
import PayoutDetailByID from "./PayoutDetail";
import { LocalUser } from "@/model";
import { UserContext } from "@/hooks/useUser";
import { AccountCircle } from "@mui/icons-material";
import Settings from "./Settings";
import { PayoutEditByID } from "./PayoutEdit";
import JobEdit, { JobEditByFileNumber } from "./JobEdit";
import { JobDetailByFileNumber } from "./JobDetail";
import { JobList, JobStatusOption } from "./JobList";

interface Props {
  auth: Auth;
  store: Store;
  user: LocalUser;
  isDevMode: boolean;
}

export default function App(props: Props) {
  return <StoreContext.Provider value={props.store}>
    <UserContext.Provider value={props.user}>
      <CssBaseline />
      <Container maxWidth="xl" disableGutters>
        {location.origin.includes("dev") || location.origin.includes("test") ?
          <Alert severity="error" sx={{ fontSize: "2em" }}>
            This is the test deployment instance, not for real data!
          </Alert> : null}
        <Router hook={useHashLocation}>
          <TopBar auth={props.auth} user={props.user} />

          <Box my={2} mx={1}>
            <Switch>
              <Route path="/jobs/new"><JobEdit /></Route>
              <Route path="/jobs/:fileno/edit">{params => <JobEditByFileNumber fileNumber={params.fileno} />}</Route>
              <Route path="/jobs/:fileno">{params => <JobDetailByFileNumber fileNumber={params.fileno} />}</Route>
              <Route path="/jobs"><JobList defaultFilters={{
                status: JobStatusOption.OPEN,
              }} /></Route>
              {props.user.isAdmin ? <>
                <Route path="/settings"><Settings /></Route>
                <Route path="/import"><Import /></Route>
                <Route path="tags"><TagList /></Route>
                <Route path="/clients"><ClientList /></Route>
                <Route path="/clients/:id/edit">{params => <ClientEditByID id={params.id} />}</Route>
                <Route path="/clients/:id">{params => <ClientDetailByID id={params.id} />}</Route>
                <Route path="/invoices/new"><InvoiceEdit invoice={null} /></Route>
                <Route path="/invoices/:invoice_num">{params => <InvoiceDetail invoiceNumber={params.invoice_num} />}</Route>
                <Route path="/invoices/:invoice_num/edit">{params => <InvoiceEditByInvoiceNumber invoiceNumber={params.invoice_num} />}</Route>
                <Route path="/invoices"><InvoiceList defaultFilters={{ status: InvoiceStatusOption.OPEN }} /></Route>
                <Route path="/appraisers"><AppraiserList /></Route>
                <Route path="/appraisers/new"><AppraiserEdit appraiser={null} /></Route>
                <Route path="/appraisers/:initials/edit">{params => <AppraiserEditByInitials initials={params.initials} />}</Route>
                <Route path="/appraisers/:initials">{params => <AppraiserDetail initials={params.initials} />}</Route>
                <Route path="/payroll"><PayoutList defaultFilters={{
                  status: PayoutStatusOption.PENDING,
                }} /></Route>
                <Route path="/payroll/:id">{params => <PayoutDetailByID id={params.id} />}</Route>
                <Route path="/payroll/:id/edit">{params => <PayoutEditByID id={params.id} />}</Route>
              </> : null}
              <Route><Redirect to="/jobs" /></Route>
            </Switch>
          </Box>
        </Router>
      </Container>
    </UserContext.Provider>
  </StoreContext.Provider>;
}

export function TopBar({ auth, user }: { auth: Auth, user: LocalUser }) {
  const [_, setLocation] = useLocation();
  const logout = useCallback(async () => {
    await auth.signOut();
    location.reload();
  }, [auth]);

  const [anchorEl, setAnchorElm] = useState<null | HTMLElement>(null);

  const openMenu = useCallback((e: MouseEvent<HTMLElement>) => {
    setAnchorElm(e.currentTarget);
  }, [setAnchorElm]);


  const closeMenu = useCallback(() => {
    setAnchorElm(null);
  }, [setAnchorElm]);

  return (
    <Box sx={{ flexGrow: 1 }} className="hidden-print">
      <AppBar position="static">
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
            <Button
              sx={{ my: 2, color: 'white', display: 'block' }}
              onClick={() => setLocation("/jobs")}
            >
              Jobs
            </Button>
            {user.isAdmin ? <>
              <Button
                sx={{ my: 2, color: 'white', display: 'block' }}
                onClick={() => setLocation("/invoices")}
              >
                Invoices
              </Button>
              <Button
                sx={{ my: 2, color: 'white', display: 'block' }}
                onClick={() => setLocation("/clients")}
              >
                Clients
              </Button>
              <Button
                sx={{ my: 2, color: 'white', display: 'block' }}
                onClick={() => setLocation("/appraisers")}
              >
                Appraisers
              </Button>
              <Button
                sx={{ my: 2, color: 'white', display: 'block' }}
                onClick={() => setLocation("/payroll")}
              >
                Payroll
              </Button>
              <Button
                sx={{ my: 2, color: 'white', display: 'block' }}
                onClick={() => setLocation("/tags")}
              >
                Tag List
              </Button>
            </> : null}
          </Box>
          <Box>
            <IconButton
              size="large"
              onClick={openMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={closeMenu}
            >
              {user.isAdmin ? <MenuItem onClick={() => {
                setLocation("/settings");
                closeMenu();
              }}>Settings</MenuItem> : null}
              <MenuItem onClick={() => {
                logout();
                closeMenu();
              }}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
