import { useStoreValue } from "@/hooks/useStore";
import { Alert, Box, Button, CircularProgress, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useLocation } from "wouter";
import { formatDate } from "./helpers/DateDisplay";
import { Invoice } from "@/model";
import { InvoiceFilter, InvoiceStatusFilter } from "@/store/store";
import InvoiceStatusChip from "./helpers/InvoiceStatusChip";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { errorToString } from "@/format/errors";
import formatDollarsCents from "@/format/formatDollarsCents";
import { LocalSaver } from "@/util/localSaver";
import { z } from "zod";
import ClientNameAutocomplete from "./helpers/ClientNameAutocomplete";
import { Result } from "ts-results-es";
import { exportInvoicesToExcel } from "@/export/excel";
import { useSingleFieldSorting, useSorting } from "./helpers/sorting";

export enum InvoiceStatusOption {
  OPEN = "Open",
  GENERATED = "Generated",
  BILLED = "Billed",
  CLOSED = "Paid/Closed",
}

export const InvoiceFilterSet = z.object({
  status: z.nativeEnum(InvoiceStatusOption).optional(),
  startDate: z.coerce.date().optional(),
  billToNamePrefix: z.string().optional(),
});
export type InvoiceFilterSet = z.infer<typeof InvoiceFilterSet>;

interface Props {
  defaultFilters?: InvoiceFilterSet;
}

export default function InvoiceList(props: Props) {
  const [_, setLocation] = useLocation();

  const filterSaver = new LocalSaver("invoice-filters", InvoiceFilterSet);

  const [filterOptions, setFilterOptions] = useState<InvoiceFilterSet>(() => {
    if (history.state?.filterOptions) {
      return history.state.filterOptions;
    }
    return filterSaver.load(props.defaultFilters ?? {});
  });

  const invoicesResult = useStoreValue(async s => {
    const filters: InvoiceFilter[] = [];
    if (filterOptions.status) {
      filters.push(statusOptionToFilter(filterOptions.status));
    }
    if (filterOptions.startDate) {
      filters.push({ type: 'createdAfter', startDate: filterOptions.startDate });
    }
    if (filterOptions.billToNamePrefix) {
      filters.push({ type: 'clientNamePrefix', clientNamePrefix: filterOptions.billToNamePrefix });
    }
    const invoices = await s.listInvoices(...filters);
    return invoices.sort((a, b) => b.invoiceNumber.localeCompare(a.invoiceNumber))
  }, [filterOptions]);

  return <Stack direction="column" spacing={2}>
    <Typography variant="h5">Invoices</Typography>
    <Stack direction="row" className="hidden-print" alignItems="center">
      <FilterBar initialFilters={filterOptions} onChange={fs => {
        setFilterOptions(fs);
        filterSaver.save(fs);
      }} />
      <Box>
        {invoicesResult?.isOk() ? <Button onClick={() => { exportInvoicesToExcel(invoicesResult.value) }} variant="text">Export to Excel</Button> : null}
        <Button onClick={() => { setLocation("/invoices/new") }} variant="contained">New Invoice</Button>
      </Box>
    </Stack>
    <InvoiceTable invoicesResult={invoicesResult} />
  </Stack>;
}

function InvoiceTable({ invoicesResult }: { invoicesResult: Result<Invoice[], any> | undefined }) {
  const [_, setLocation] = useLocation();
  const [sortField, direction, SortingLabel] = useSingleFieldSorting<keyof Invoice>("invoiceNumber", "desc");
  const invoices = useSorting(invoicesResult?.unwrapOr(undefined), sortField, direction);

  if (!invoicesResult) {
    return <CircularProgress />;
  }

  if (invoicesResult.isErr()) {
    return <Alert severity="error">Failed to fetch invoices: {errorToString(invoicesResult.error)}</Alert>;
  }

  return <Stack direction="column" spacing={2}>
    <Stack direction="row" justifyContent="flex-end" spacing={2}>
      <Typography>Showing <b>{invoices.length}</b> invoices</Typography>
      <Typography>Total billed: <b>{formatDollarsCents(invoices.reduce((acc, i) => (i.totalDollars ?? 0) + acc, 0))}</b></Typography>
    </Stack>
    <TableContainer component={Paper}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell><SortingLabel field="invoiceNumber">Invoice #</SortingLabel></TableCell>
            <TableCell align="right">File #s</TableCell>
            <TableCell align="right"><SortingLabel field="billToName">Bill To</SortingLabel></TableCell>
            <TableCell align="right"><SortingLabel field="createdOn">Created On</SortingLabel></TableCell>
              <TableCell align="right"><SortingLabel field="deliveredOn">Billed On</SortingLabel></TableCell>
            <TableCell align="right"><SortingLabel field="totalDollars">$ Billed</SortingLabel></TableCell>
            <TableCell align="right">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.length === 0 ?
            <TableRow><TableCell>No invoices yet</TableCell></TableRow> :
            invoices.map(inv => {
              return <TableRow
                hover
                sx={{ cursor: 'pointer' }}
                key={inv.invoiceNumber}
                onClick={() => setLocation(`/invoices/${inv.invoiceNumber}`)}
              >
                <TableCell><Typography fontWeight="bold">{inv.invoiceNumber}</Typography></TableCell>
                <TableCell align="right" sx={{maxWidth:"15em"}}><Typography noWrap>{inv.associatedFileNumbers?.join(", ") ?? "None"}</Typography></TableCell>
                <TableCell align="right" sx={{maxWidth:"15em"}}><Typography noWrap>{inv.billToName ?? "None"}</Typography></TableCell>
                <TableCell align="right"><Typography>{formatDate(inv.createdOn ?? new Date())}</Typography></TableCell>
                <TableCell align="right"><Typography>{formatDate(inv.deliveredOn ?? new Date())}</Typography></TableCell>
                <TableCell align="right"><Typography>{formatDollarsCents(inv.totalDollars ?? 0)}</Typography></TableCell>
                <TableCell align="right">
                  <Stack direction="row" spacing={1} justifyContent="right">
                    <InvoiceStatusChip invoice={inv} />
                  </Stack>
                </TableCell>
              </TableRow>;
            })}
        </TableBody>
      </Table>
    </TableContainer>
  </Stack>;
}

function statusOptionToFilter(o: InvoiceStatusOption): InvoiceStatusFilter {
  switch (o) {
    case InvoiceStatusOption.OPEN:
      return {
        type: 'invoiceStatus', statuses: [
          "sent",
          "generated",
        ]
      }
    case InvoiceStatusOption.GENERATED:
      return { type: 'invoiceStatus', statuses: ["generated"] };
    case InvoiceStatusOption.BILLED:
      return { type: 'invoiceStatus', statuses: ["sent"] };
    case InvoiceStatusOption.CLOSED:
      return { type: 'invoiceStatus', statuses: ["closed"] };
  }
}

interface FilterBarProps {
  onChange: (filters: InvoiceFilterSet) => void;
  initialFilters: InvoiceFilterSet;
}

function FilterBar({ onChange, initialFilters }: FilterBarProps) {
  const [pendingFilters, setPendingFilters] = useState(initialFilters);

  const isChanged = useMemo(() => {
    return JSON.stringify(initialFilters) !== JSON.stringify(pendingFilters);
  }, [initialFilters, pendingFilters]);

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap" flexGrow={1}>
      {/* Status filter */}
      <TextField
        sx={{ minWidth: '10em' }}
        select
        value={pendingFilters.status ?? ""}
        onChange={e => {
          setPendingFilters({ ...pendingFilters, status: e.target.value ? e.target.value as InvoiceStatusOption : undefined });
        }}
        label="Status"
      >
        <MenuItem value="">
          <em>Any</em>
        </MenuItem>
        {Object.values(InvoiceStatusOption).map((v) => <MenuItem key={v} value={v}><Typography fontWeight="bold">{v}</Typography></MenuItem>)}
      </TextField>

      {/* Since date filter */}
      <DatePicker
        label="Created Since Date"
        onChange={v => setPendingFilters({ ...pendingFilters, startDate: v?.toDate() })}
        value={pendingFilters.startDate ? dayjs(pendingFilters.startDate) : null}
        slotProps={{
          field: { clearable: true },
        }}
      />

      <ClientNameAutocomplete
        value={pendingFilters.billToNamePrefix ?? ""}
        onChange={v => {
          setPendingFilters({ ...pendingFilters, billToNamePrefix: v ?? undefined });
        }}
        label="Bill To Name"
        placeholder="Starts with"
      />

      {isChanged ? <Button variant="outlined" onClick={() => { onChange(pendingFilters) }}>Apply Filters</Button> : null}
    </Stack>
  </LocalizationProvider>;
}
