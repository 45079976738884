import { Paper, PaperProps, Stack, Typography } from "@mui/material";
import React from "react";


const Section = ({ sx, ...props }: PaperProps & { title: string }) => <Paper square sx={{ p: 2, width: 'fit-content', height: 'fit-content', ...sx }} variant="outlined" {...props}>
  <Stack direction="column" spacing={1} useFlexGap flexWrap="wrap">
    <Typography variant="h6">{props.title}</Typography>
    {props.children}
  </Stack>
</Paper>;
export default Section;
