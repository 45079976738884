import { Stack, Typography } from "@mui/material";
import React from "react";


export default function ItemWithTitle({ title, children }: { title: string } & React.PropsWithChildren) {
  return <Stack direction="column" spacing={0} alignItems="flex-start">
    <Typography color="text.secondary" sx={{ fontVariant: "small-caps", fontVariantCaps: "all-petite-caps", fontWeight: "bold" }}>{title}</Typography>
    {children}
  </Stack>;
}
