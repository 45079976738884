import formatDollarsCents from "@/format/formatDollarsCents";
import { Job, InvoiceNumber, LineItem, Transaction } from "@/model";
import { Store } from "@/store/store";
import wrapPromise from "@/util/wrapPromise";
import dayjs from "dayjs";
import { convertInchesToTwip, Paragraph, patchDocument, PatchType, Table, TableCell, TableRow, TextRun, VerticalAlign, WidthType } from "docx";
import saveAs from "file-saver";
import { Ok, Result } from "ts-results-es";


export async function generateInvoiceDocx(store: Store, invoiceNumber: InvoiceNumber, template: Blob) {
  let res = Result.all(await Promise.all([
    wrapPromise(store.getInvoice(invoiceNumber)),
    wrapPromise(store.listTransactions(invoiceNumber)),
    wrapPromise(store.listLineItems(invoiceNumber)),
  ] as const));
  if (res.isErr()) {
    return res;
  }
  const [invoice, transactions, lineItems] = res.value;

  const secondRes = Result.all(await Promise.all([
    invoice.billToId ? wrapPromise(store.getClient(invoice.billToId)) : Promise.resolve(Ok(undefined)),
    wrapPromise(store.listJobs({ type: "fileNumber", fileNumbers: invoice.associatedFileNumbers ?? [] })),
  ] as const));

  if (secondRes.isErr()) {
    return secondRes;
  }

  const [billTo, jobs] = secondRes.value;

  const doc = await patchDocument(template, {
    keepOriginalStyles: true,
    patches: {
      invoiceNumber: {
        type: PatchType.PARAGRAPH,
        children: [makeTextRun(invoice.invoiceNumber, true)],
      },
      invoiceDateLong: {
        type: PatchType.PARAGRAPH,
        children: [
          makeTextRun(dayjs(invoice.deliveredOn).format("MMMM D, YYYY"), false),
        ],
      },
      invoiceDateShort: {
        type: PatchType.PARAGRAPH,
        children: [
          makeTextRun(dayjs(invoice.deliveredOn).format("MM-DD-YYYY"), true),
        ],
      },
      billTo: {
        type: PatchType.DOCUMENT,
        children: billTo ? [
          billTo?.name ? simpleParagraph(billTo.name ?? "") : null,
          (billTo?.contactName || billTo?.title) ? simpleParagraph(
            (billTo.contactName ?? "") +
            (billTo.title ? `${billTo.contactName ? ", " : ""}${billTo.title}` : "")
          ) : null,
          billTo?.address1 ? simpleParagraph(billTo.address1) : null,
          billTo?.address2 ? simpleParagraph(billTo.address2) : null,
          billTo?.city ? simpleParagraph(`${billTo.city}, ${billTo.state ?? "NC"} ${billTo.zipcode ?? ""}`.trim()) : null,
          billTo?.email ? simpleParagraph(`Email: ${billTo.email}`) : null,
        ].filter(i => i !== null) :
          [
            new Paragraph("INSERT CLIENT HERE"),
          ],
      },
      title: {
        type: PatchType.PARAGRAPH,
        children: [
          makeTextRun(invoice.title ?? "Services Rendered"),
        ],
      },
      lineItems: {
        type: PatchType.DOCUMENT,
        children: [
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: lineItemTable(jobs, lineItems, transactions, invoice.totalDollars ?? 0, invoice.paidDollars ?? 0),
          }),
        ],
      },
    },
  });

  saveAs(new Blob([doc]), `invoice-${(billTo?.name ?? "").replace(/[ \.]/g, '-')}.docx`);

  return Ok(null);
}

function lineItemTable(jobs: Job[], lineItems: LineItem[], payments: Transaction[], totalDollars: number, paidDollars: number) {
  const jobLineItems = (jobs ?? []).map(a => new TableRow({
    children: [
      new TableCell({
        children: [
          new Paragraph({
            children: [makeTextRun(`File # ${a.fileNumber} - ${a.title ?? ""}`)],
          }),
        ],
        verticalAlign: VerticalAlign.CENTER,
        width: { size: '70%' },
        margins: {
          top: convertInchesToTwip(0.25),
          bottom: convertInchesToTwip(0.25),
        }
      }),
      new TableCell({
        children: [
          new Paragraph({
            alignment: "right",
            children: [makeTextRun(formatDollarsCents(a.totalFeeDollars ?? 0))],
          })
        ],
        width: { size: '30%' },
        verticalAlign: VerticalAlign.CENTER,
      }),
    ],
  }));

  const extraLineItems = lineItems.map(li => new TableRow({
    children: [
      new TableCell({
        children: [
          new Paragraph({
            children: [makeTextRun(li.description ?? "Additional expense")],
          }),
        ],
        verticalAlign: VerticalAlign.CENTER,
        margins: {
          top: convertInchesToTwip(0.25),
          bottom: convertInchesToTwip(0.25),
        }
      }),
      new TableCell({
        children: [
          new Paragraph({
            alignment: "right",
            children: [makeTextRun(formatDollarsCents(li.amountDollars ?? 0))],
          })
        ],
        verticalAlign: VerticalAlign.CENTER,
      }),
    ],
  }));

  const subtotalRow = new TableRow({
    children: [
      new TableCell({
        children: [
          new Paragraph({
            children: [makeTextRun("Total Fee: ", true)],
          })
        ],
        verticalAlign: VerticalAlign.CENTER,
        margins: {
          top: convertInchesToTwip(0.25),
          bottom: convertInchesToTwip(0.25),
        }
      }),
      new TableCell({
        children: [
          new Paragraph({
            alignment: "right",
            children: [makeTextRun(formatDollarsCents(totalDollars), true)],
          })
        ],
        verticalAlign: VerticalAlign.CENTER,
      }),
    ],
  });

  if (payments.length === 0) {
    payments.push({
      id: '',
      invoiceNumber: '',
      amountDollars: 0,
    });
  }
  const prepaymentRows = payments.map(tr => new TableRow({
    children: [
      new TableCell({
        children: [
          new Paragraph({
            children: [makeTextRun(
              tr.amountDollars ? `Retainer received ${dayjs(tr.appliedOn).format("MM-DD-YYYY")}${tr.description ? ` (${tr.description})` : ""}` :
                `Retainer`,
              true)]
          })
        ],
        verticalAlign: VerticalAlign.CENTER,
        margins: {
          top: convertInchesToTwip(0.25),
          bottom: convertInchesToTwip(0.25),
        }
      }),
      new TableCell({
        children: [
          new Paragraph({
            alignment: "right",
            children: [makeTextRun("-" + formatDollarsCents(tr.amountDollars ?? 0), true)],
          })
        ],
        verticalAlign: VerticalAlign.CENTER,
      }),
    ],
  }));

  const totalRow = new TableRow({
    children: [
      new TableCell({
        children: [
          new Paragraph({
            children: [makeTextRun("Total Amount Due Upon Receipt", true)],
          })
        ],
        verticalAlign: VerticalAlign.CENTER,
        margins: {
          top: convertInchesToTwip(0.25),
          bottom: convertInchesToTwip(0.25),
        }
      }),
      new TableCell({
        children: [
          new Paragraph({
            alignment: "right",
            children: [makeTextRun(formatDollarsCents(totalDollars - paidDollars), true)],
          })
        ],
        verticalAlign: VerticalAlign.CENTER,
      }),
    ],
  });

  return [...jobLineItems, ...extraLineItems, subtotalRow, ...prepaymentRows, totalRow];
}

const font = "Bookman Old Style";

function makeTextRun(text: string, bold: boolean = false): TextRun {
  return new TextRun({
    text,
    bold,
    font,
  });
}

function simpleParagraph(text: string): Paragraph {
  return new Paragraph({
    children: [makeTextRun(text)],
  });
}
