import { Payout } from "@/model";
import { Chip } from "@mui/material";
import React from "react";

export default function PayoutStatusChip({ payout }: { payout: Payout }) {
  if (payout.paidOn) {
    return <Chip label="Paid/Closed" variant="outlined" color="success" />;
  } else {
    return <Chip label="Pending" color="info" />;
  }
}
