import { StoreContext } from "@/hooks/useStore";
import { useStore } from "@/hooks/useStore";
import { FileNumber, Job } from "@/model";
import { Alert, Autocomplete, Box, Button, CircularProgress, InputAdornment, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form";
import { useLocation } from "wouter";
import Section from "../helpers/Section";
import { Tags } from "@/tags";
import { zodResolver } from "@hookform/resolvers/zod";
import DatePickerField from "../helpers/DatePickerField";
import ClientInput from "./ClientInput";
import { z } from "zod";

export function JobEditByFileNumber({ fileNumber }: { fileNumber: FileNumber }) {
  const [job, setJob] = useState<Job | null | undefined>(undefined);
  useStore(async store => {
    const jobs = await store.listJobs({ type: "fileNumber", fileNumbers: [fileNumber] });
    if (jobs.length === 0) {
      setJob(null);
    } else {
      setJob(jobs[0]);
    }
  }, [fileNumber]);

  if (job === undefined) {
    return <CircularProgress />;
  }
  if (job === null) {
    return <Box>Job not found for file number {fileNumber}</Box>;
  }
  return <JobEdit job={job} />;
}

interface Props {
  job?: Job;
}

const EditableJob = Job.extend({
  clientId: Job.shape.clientId.nullable(),
  clientName: Job.shape.clientName.nullable(),
});

type EditableJob = z.infer<typeof EditableJob>;

export default function JobEdit({ job }: Props) {
  const [_, setLocation] = useLocation();
  const store = useContext(StoreContext);
  const [saving, setSaving] = useState(false);
  const [saveErr, setSaveErr] = useState<null | string>(null);

  const methods = useForm<EditableJob>({
    defaultValues: {
      ...(job || {}),
    },
    resolver: async (values, context, options) => {
      if (!job) {
        try {
          const jobs = await store.listJobs({
            type: "fileNumber",
            fileNumbers: [values.fileNumber]
          });
          if (jobs.length > 0) {
            return {
              values: {},
              errors: { fileNumber: { type: "unique", message: "File number is already in use" } }
            };
          }
        } catch (e) {
          console.error("Failed to validate file number", e);
          return {
            values: {},
            errors: { fileNumber: { type: "error", message: "Failed to validate file number" } }
          };
        }
      }
      return zodResolver(EditableJob)(values, context, options);
    },
  });
  const { control, register, watch, setValue, handleSubmit } = methods;

  const onSubmit = useCallback(async (value: EditableJob) => {
    setSaveErr(null);
    setSaving(true);
    try {
      await store.addOrUpdateJob(value);
      setLocation(`/jobs/${value.fileNumber}`, { replace: true });
    } catch (e) {
      setSaveErr("Failed to save job");
      console.error(e);
    } finally {
      setSaving(false);
    }
  }, [store]);

  const clientId = watch("clientId");
  const clientName = watch("clientName");

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Box my={2}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" spacing={2}>
            <FileNumberInput job={job} />
            <TextField {...register("title")} sx={{ maxWidth: '20em' }} variant="standard" label="Job Title" />
            <TagsEdit />
            <TextField {...register("project")} sx={{ maxWidth: '20em' }} variant="standard" label="Project" />
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
              <TextField
                {...register("comments")}
                multiline
                fullWidth
                sx={{ maxWidth: '30em' }}
                variant="standard"
                label="Notes"
              />
            </Stack>

            <Stack direction="row" spacing={2}>
              <Section title="Assignment">
                <Typography>Set assignments in job view page</Typography>
              </Section>
            </Stack>

            <Section title="Property Info">
              <TextField {...register("streetAddress")} sx={{ maxWidth: '20em' }} variant="standard" label="Address" />
              <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
                <Controller
                  control={control}
                  name={`city`}
                  render={({ field }) =>
                    <TextField {...field} value={field.value ?? ""} variant="standard" label="City" />}
                />
                <Controller
                  control={control}
                  name={`state`}
                  render={({ field }) =>
                    <TextField {...field} value={field.value ?? ""} sx={{ width: '3em' }} variant="standard" label="State" />
                  }
                />
                <Controller
                  control={control}
                  name={`zipcode`}
                  render={({ field }) =>
                    <TextField {...field} value={field.value ?? ""} sx={{ width: '6em' }} variant="standard" label="Zipcode" />}
                />
              </Stack>
              <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
                <Controller
                  control={control}
                  name={`county`}
                  render={({ field }) =>
                    <TextField {...field} value={field.value ?? ""} sx={{ width: '9em' }} variant="standard" label="County" />}
                />
                <Controller
                  control={control}
                  name={`subdivisionOrProject`}
                  render={({ field }) =>
                    <TextField {...field} value={field.value ?? ""} sx={{ width: '12em' }} variant="standard" label="Subdivision/Neighborhood" />}
                />
              </Stack>
            </Section>

            <Stack direction="column" spacing={2}>
              <Section title="Fee">
                <FeeInput />
              </Section>
              <Section title="Client" sx={{ minWidth: '20em' }}>
                <ClientInput
                  value={clientId ? { id: clientId, name: clientName } : null}
                  onChange={v => {
                    setValue("clientId", v?.id ?? null);
                    setValue("clientName", v?.name ?? null);
                  }}
                  label="Client"
                />
              </Section>
            </Stack>

            <Section title="Dates">
              <DatePickerField
                name="receivedOn"
                label="Received On"
              />
              <DatePickerField
                name="onHoldOn"
                label="On Hold On"
              />
              <DatePickerField
                name="offHoldOn"
                label="Off Hold On"
              />
              <DatePickerField
                name="dueOn"
                label="Due On"
              />
              <DatePickerField
                name="inspectedOn"
                label="Inspected On"
              />
              <DatePickerField
                name="effectiveDate"
                label="Effective Date"
              />
              <DatePickerField
                name="reviewedOn"
                label="Reviewed On"
              />
              <DatePickerField
                name="deliveredOn"
                label="Delivered On"
              />
              <DatePickerField
                name="closedOn"
                label="Closed On"
              />
            </Section>

            <Stack direction="row" spacing={2}>
              <Section title="Owner">
                <AddressFields prefix="owner" />
              </Section>
              <Section title="Occupant">
                <AddressFields prefix="occupant" />
              </Section>
              <Section title="Contact">
                <AddressFields prefix="contact" />
              </Section>
            </Stack>
            <Stack direction="row" spacing={2} alignSelf="center">
              {saving ?
                <CircularProgress /> :
                <Button type="submit" variant="contained">Submit</Button>}
              <Button variant="text" disabled={saving} onClick={() => {
                setLocation(`/jobs` + (job ? `/${job.fileNumber}` : ""));
              }}>Cancel</Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
      {saveErr ?
        <Alert severity="error">{saveErr}</Alert> :
        null}
    </Box>
  </LocalizationProvider >;
}

function FileNumberInput({ job }: { job?: Job }) {
  const store = useContext(StoreContext);
  const [nextFileNumber, setNextFileNumber] = useState<string | undefined>(undefined);
  const { register, formState: { errors }, setValue } = useFormContext<EditableJob>();

  useEffect(() => {
    if (!job) {
      store.suggestNextFileNumber().then(setNextFileNumber);
    }
  });

  if (job) {
    return <Typography fontWeight="bold">{job.fileNumber}</Typography>
  }

  if (!nextFileNumber) {
    return <CircularProgress />;
  }

  return <TextField
    {...register("fileNumber")}
    error={!!errors.fileNumber}
    defaultValue={nextFileNumber}
    helperText={
      errors.fileNumber ?
        errors.fileNumber?.message :
        <>Suggested file # is {nextFileNumber} <Button sx={{ padding: 0, margin: 0, minWidth: 0 }} size="small" variant="text" onClick={() => { setValue("fileNumber", nextFileNumber) }}>Use</Button></>
    }
    label="File #"
    variant="standard"
  />;
}

function FeeInput() {
  const { control, formState: { errors } } = useFormContext<Job>();
  console.log(errors);
  return <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
    <Controller
      control={control}
      name="fee"
      defaultValue={{ model: "fixed", priceDollars: 0 }}
      render={({ field }) => <>
        <TextField
          {...field}
          error={!!errors.fee}
          helperText={errors.fee ? (errors.fee.message ?? "Fee must be a number") : ""}
          variant="standard"
          sx={{ width: '10em' }}
          disabled={field.value?.model !== "fixed"}
          onChange={(e) => {
            const val = e.target.value;
            field.onChange({ ...field.value, priceDollars: Number.parseInt(val), })
          }}
          label="Fee"
          value={
            field.value?.model === "fixed" ?
              field.value.priceDollars ? field.value.priceDollars : ""
              : ""
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <Box>
          <ToggleButtonGroup
            color="primary"
            value={field.value?.model}
            exclusive
            onChange={(_, val) => { field.onChange({ model: val }) }}
          >
            <ToggleButton defaultChecked value="fixed">Fixed</ToggleButton>
            <ToggleButton value="hourly">Hourly</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </>
      }
    />
  </Stack>
}

function TagsEdit() {
  const { control } = useFormContext<Job>();
  return <Controller
    name="tags"
    control={control}
    render={({ field }) =>
      <Autocomplete
        {...field}
        value={field.value ?? []}
        onChange={(_, v) => {
          field.onChange(v);
        }}
        multiple
        getOptionLabel={o => Tags.get(o)!}
        options={[...Tags.keys()]}
        defaultValue={[]}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ maxWidth: "20em" }}
            variant="standard"
            label="Tags"
          />
        )}
      />}
  />;
}

function AddressFields({ prefix }: { prefix: "owner" | "occupant" | "contact" }) {
  const { register } = useFormContext<Job>();
  return <Stack direction="column" spacing={2} useFlexGap flexWrap="wrap">
    <TextField {...register(`${prefix}Name`)} sx={{ maxWidth: '20em' }} variant="standard" label="Name" />
    <TextField {...register(`${prefix}Address`)} sx={{ maxWidth: '20em' }} variant="standard" label="Address" />
    <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
      <TextField {...register(`${prefix}City`)} sx={{ width: '9em' }} variant="standard" label="City" />
      <TextField {...register(`${prefix}State`)} sx={{ width: '3em' }} variant="standard" label="State" />
      <TextField {...register(`${prefix}Zipcode`)} sx={{ width: '6em' }} variant="standard" label="Zipcode" />
    </Stack>
    <TextField {...register(`${prefix}Email`)} variant="standard" sx={{ maxWidth: '20em' }} label="Email" />
    <TextField {...register(`${prefix}Phone`)} variant="standard" sx={{ maxWidth: '20em' }} label="Phone" />
  </Stack>
}
