import { StoreContext } from "@/hooks/useStore";
import { Transaction } from "@/model";
import { Alert, Button, CircularProgress, Dialog, DialogTitle, IconButton, InputAdornment, List, ListItem, ListItemText, Stack, TextField, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect } from "react";
import useDialog, { DialogProps } from "@/hooks/useDialog";
import wrapPromise from "@/util/wrapPromise";
import formatDollarsCents from "@/format/formatDollarsCents";
import { Delete, Edit } from "@mui/icons-material";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { formatDate } from "../helpers/DateDisplay";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePickerField from "../helpers/DatePickerField";
import useHover from "@/hooks/useHover";
import { useStoreWatchCollection } from "@/hooks/useStoreWatch";
import { errorToString } from "@/format/errors";
import { nanoid } from "nanoid";

export function TransactionList({ invoiceNumber }: { invoiceNumber: string; }) {
  const store = useContext(StoreContext);

  const transactionsResult = useStoreWatchCollection(s => s.watchTransactions, invoiceNumber);

  const addToStore = useCallback(async (v: Transaction) => {
    return wrapPromise(store.addOrUpdateTransaction(v));
  }, [store, invoiceNumber]);

  const { showDialog, props } = useDialog((v: Transaction) => addToStore(v), [addToStore]);

  if (!transactionsResult) {
    return <CircularProgress />;
  }

  if (transactionsResult.isErr()) {
    return <Alert severity="error">{errorToString(transactionsResult.error)}</Alert>;
  }

  const transactions = transactionsResult.value;

  return <Stack direction="column" spacing={2} alignItems="flex-start">
    <List dense>
      {transactions ?
        transactions.map((t, index) =>
          <PaymentItem
            key={index}
            transaction={t}
            startEditing={() => { showDialog(t); }}
            onDelete={() => {
              return wrapPromise(store.removeTransaction(invoiceNumber, t.id));
            }}
          />
        )
        : <Typography color="text.secondary">None</Typography>}
    </List>
    <Button onClick={() => showDialog({ id: nanoid(), invoiceNumber })}>Add Payment</Button>
    <AddOrEditTransactionDialog {...props} />
  </Stack>;
}

export function PaymentItem({ transaction, startEditing, onDelete }: { transaction: Transaction, startEditing: () => void, onDelete: () => void }) {
  const [hovering, hoverProps] = useHover();
  return <ListItem {...hoverProps} divider dense sx={{ minWidth: "20em" }} secondaryAction={
    hovering ? <>
      <IconButton onClick={startEditing}><Edit /></IconButton>
      <IconButton onClick={onDelete}><Delete /></IconButton>
    </> : null
  }>
    <ListItemText
      primary={(transaction.amountDollars ? formatDollarsCents(transaction.amountDollars) : "$?")
        + (transaction.appliedOn ? ` on ${formatDate(transaction.appliedOn)}` : "")}
      secondary={transaction.description}
    />
  </ListItem>
}

export function AddOrEditTransactionDialog({ open, onClose, onSave, value, error }: DialogProps<Transaction>) {
  const methods = useForm<Transaction>({
    resolver: zodResolver(Transaction),
  });
  const { handleSubmit, formState: { errors }, register, reset } = methods;

  useEffect(() => {
    reset(value ?? { description: "" });
  }, [value, open]);

  return <Dialog onClose={() => { onClose() }} open={open}>
    <DialogTitle>{value?.amountDollars ? "Edit" : "Add"} Payment</DialogTitle>
    <form onSubmit={handleSubmit(vals => onSave(vals), err => { console.error(err) })}>
      <FormProvider {...methods}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="column" spacing={2} sx={{ padding: 2 }} useFlexGap>
            <TextField
              {...register("amountDollars", {
                setValueAs: v => v ? Number.parseFloat(v) : 0,
              })}
              error={!!errors.amountDollars}
              helperText={errors.amountDollars?.message}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              variant="standard"
              label="Amount"
            />
            <DatePickerField
              name="appliedOn"
              label="Date"
            />
            <TextField
              {...register("description", { required: false })}
              variant="standard"
              error={!!errors.description}
              helperText={errors.description?.message}
              label="Description"
            />
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button variant="text" onClick={() => onClose()}>Cancel</Button>
              <Button type="submit">{value?.amountDollars ? "Edit" : "Add"}</Button>
            </Stack>
            {error ? <Alert severity="error">{error}</Alert> : null}
          </Stack>
        </LocalizationProvider>
      </FormProvider>
    </form>
  </Dialog>;
}

