import { Invoice, invoiceIsPaid } from "@/model";
import { Chip } from "@mui/material";
import React from "react";

export default function InvoiceStatusChip({ invoice }: { invoice: Invoice }) {
  switch (invoice.status) {
    case undefined:
      return <Chip label="Unknown" variant="outlined" color="info" />;
    case "closed":
      if (invoiceIsPaid(invoice)) {
        return <Chip label="Paid" variant="outlined" color="success" />
      } else if (invoice.totalDollars && invoice.totalDollars > 0) {
        if (invoice.paidDollars ?? 0 > 0) {
          return <Chip label="Partial Payment" color="warning" />;
        } else {
          return <Chip label="Written Off" color="warning" />;
        }
      } else {
        return <Chip label="Canceled" color="info" />;
      }
    case "sent":
      return <Chip label="Billed" color="primary" />;
    case "generated":
      return <Chip label="Generated" color="info" />;
  }
}
