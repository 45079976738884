import { errorToString } from "@/format/errors";
import { DependencyList, useCallback, useState } from "react";
import { Result } from "ts-results-es";

export default function useDialog<T>(doSave: (v: T, ...args: unknown[]) => Promise<Result<unknown, unknown>>, deps: DependencyList): { showDialog: (v?: T) => unknown, props: DialogProps<T> } {
  const [open, setOpen] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [value, setValue] = useState<T | undefined>(undefined);

  const showDialog = useCallback((v?: T) => {
    setError(undefined);
    setSaving(false);
    setValue(v);
    setOpen(true);
  }, [setError, setValue, setOpen, setSaving]);

  const onSave = useCallback(async (v: T) => {
    setError(undefined);
    setSaving(true);
    const res = await doSave(v);
    if (res.isErr()) {
      setError(errorToString(res.error));
    } else {
      setOpen(false);
    }
    setSaving(false);
  }, [...deps, doSave, setError, setSaving, setOpen]);

  return {
    showDialog,
    props: {
      open,
      onClose: () => { setOpen(false) },
      error,
      value,
      onSave,
      saving,
    },
  }
}

export interface DialogProps<T> {
  open: boolean;
  onClose: () => void;
  saving: boolean;
  error?: string;
  value?: T;
  onSave: (v: T) => unknown;
}
