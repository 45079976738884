import { StoreContext } from "@/hooks/useStore";
import { Assignment, FileNumber, TimeBlock } from "@/model";
import React, { useCallback, useContext } from "react";
import { Button, Divider, IconButton, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import ItemWithTitle from "@/components/helpers/ItemWithTitle";
import useDialog from "@/hooks/useDialog";
import wrapPromise from "@/util/wrapPromise";
import { Delete, Edit } from "@mui/icons-material";
import formatHours from "@/format/formatHours";
import { formatDate } from "../helpers/DateDisplay";
import AddOrEditTimeBlockDialog, { TimeBlockWithIndex } from "./AddOrEditTimeBlockDialog";
import formatDollarsCents from "@/format/formatDollarsCents";
import useHover from "@/hooks/useHover";
import { Link } from "wouter";
import useCurrentUser from "@/hooks/useUser";

interface Props {
  assignment: Assignment;
  fileNumber: FileNumber;
  startEditing: () => void;
  onDelete: () => void;
}

export function AssignmentRow({ assignment, fileNumber, startEditing, onDelete }: Props) {
  const store = useContext(StoreContext);
  const user = useCurrentUser();

  const addTimesheetToStore = useCallback(async (v: TimeBlockWithIndex, doDelete?: boolean) => {
    let newTimeBlocks = [...(assignment.timesheet ?? [])];
    if (doDelete) {
      newTimeBlocks.splice(v.index!, 1);
    } else {
      newTimeBlocks[v.index ?? assignment.timesheet?.length ?? 0] = v.timeBlock;
    }
    return wrapPromise(store.addOrUpdateAssignment({
      initials: assignment.initials,
      fileNumber,
      timesheet: newTimeBlocks,
    }));
  }, [store, assignment, fileNumber]);

  const { showDialog, props: dialogProps } = useDialog((v: TimeBlockWithIndex) => addTimesheetToStore(v), [addTimesheetToStore]);

  const [hovering, hoverProps] = useHover();

  return <Stack direction="column" spacing={2}>
    <Stack direction="row" spacing={1} alignItems="flex-start">
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2} {...hoverProps}>
          <ItemWithTitle title="Appraiser">
            <Stack direction="row" spacing={1}>
              <Typography fontWeight="bold">{assignment.initials}</Typography>
              {assignment.isReviewer ? <Typography fontStyle="italic">(Reviewer)</Typography> : null}
            </Stack>
          </ItemWithTitle>
          <ItemWithTitle title="commission">
            <Typography>{assignment.commissionPercent}%</Typography>
          </ItemWithTitle>
          {assignment.hourlyRateDollars ?
            <ItemWithTitle title="hourly rate">
              <Typography>{formatDollarsCents(assignment.hourlyRateDollars)}/hr</Typography>
            </ItemWithTitle> :
            null}
          {assignment.payoutId && user.isAdmin ?
            <ItemWithTitle title="payroll">
              <Link href={`/payroll/${assignment.payoutId}`}><Typography>See Payout</Typography></Link>
            </ItemWithTitle> :
            null}
          {hovering && user.isAdmin ? <>
            <IconButton onClick={startEditing} title="Edit Assignment" ><Edit /></IconButton>
            <IconButton onClick={onDelete} title="Delete Assignment"><Delete /></IconButton>
          </> : null}
        </Stack>
        <ItemWithTitle title="timesheet">
          {assignment.timesheet && assignment.timesheet.length > 0 ?
            <List dense>
              {assignment.timesheet.map((t, index) =>
                <TimeBlockItem
                  key={index}
                  timeBlock={t}
                  startEditing={() => { showDialog({ timeBlock: t, index }); }}
                  onDelete={() => { addTimesheetToStore({ timeBlock: t, index }, true); }} />
              )}
            </List>
            : <Typography color="text.secondary">None</Typography>}
          <Button variant="text" onClick={() => showDialog()}>Add Time Block for {assignment.initials}</Button>
          <AddOrEditTimeBlockDialog {...dialogProps} appraiserInitials={assignment.initials} />
        </ItemWithTitle>
      </Stack>
    </Stack>
    <Divider />
  </Stack>;
}

export function TimeBlockItem({ timeBlock, startEditing, onDelete }: { timeBlock: TimeBlock; startEditing: () => void; onDelete: () => void; }) {
  const [hovering, hoverProps] = useHover();
  return <ListItem {...hoverProps} divider dense secondaryAction={hovering ? <>
    <IconButton onClick={startEditing}><Edit /></IconButton>
    <IconButton onClick={onDelete}><Delete /></IconButton>
  </> : null}>
    <ListItemText
      primary={`${formatHours(timeBlock.hoursSpent)}${timeBlock.day ? ` on ${formatDate(timeBlock.day)}` : ""}`}
      secondary={timeBlock.description ?? ""} />
  </ListItem>;
}
