import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, initializeFirestore } from "firebase/firestore";

declare const FIREBASE_API_KEY: string;
declare const FIREBASE_AUTH_DOMAIN: string;
declare const FIREBASE_PROJECT_ID: string;
declare const FIREBASE_STORAGE_BUCKET: string;
declare const FIREBASE_MESSAGING_SENDER_ID: string;
declare const FIREBASE_APP_ID: string;

declare const USE_FIREBASE_EMULATORS: boolean;
declare const FIRESTORE_EMULATOR_HOST: string;
declare const FIREBASE_AUTH_EMULATOR_HOST: string;

let authEmulatorInitialed = false;

export function initFirebase(databaseId: string = "(default)") {
  const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
  };

  const app = initializeApp(firebaseConfig);

  const auth = getAuth(app);

  const store = initializeFirestore(app, { ignoreUndefinedProperties: true }, databaseId);

  if (USE_FIREBASE_EMULATORS) {
    const [firestore_host, firestore_port] = FIRESTORE_EMULATOR_HOST.split(":");
    if (!firestore_host || !firestore_port) {
      throw new Error("Must specify FIRESTORE_EMULATOR_HOST as host:port when using emulator, got: " + FIRESTORE_EMULATOR_HOST);
    }
    connectFirestoreEmulator(store, firestore_host, Number.parseInt(firestore_port));

    // Make sure the auth emulator only gets setup once (e.g. in tests) or else it has random
    // failures.
    if (!authEmulatorInitialed) {
      connectAuthEmulator(auth, `http://${FIREBASE_AUTH_EMULATOR_HOST}`, { disableWarnings: true });
      authEmulatorInitialed = true;
    }
  }

  return [app, auth, store] as const;
}
