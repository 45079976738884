import { useState } from "react";

export default function useHover() {
  const [hovering, setHovering] = useState(false);

  return [hovering, {
    onMouseEnter: () => setHovering(true),
    onMouseLeave: () => setHovering(false),
  }] as const;
}
