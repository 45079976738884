import { errorToString } from "@/format/errors";
import { StoreContext } from "@/hooks/useStore";
import { EasyTracImporter, identifyEasyTracFiles } from "@/import/easytrac";
import wrapPromise from "@/util/wrapPromise";
import { CloudUpload } from "@mui/icons-material";
import { Button, CircularProgress, Paper, Stack, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import VisuallyHiddenInput from "../helpers/VisuallyHiddenInput";


export default function Import() {
  const store = useContext(StoreContext);
  const [startYear, setStartYear] = useState(2023);
  const [easytracImporter, setEasytracImporter] = useState<EasyTracImporter | undefined>();

  const onFileSelect = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) return;
    const res = identifyEasyTracFiles(e.target.files);
    if (res.isErr()) {
      console.error(res.error);
      return;
    }
    const importer = new EasyTracImporter(res.value, store);

    setEasytracImporter(importer);
    e.target.files = null;
  }, [store]);

  return <Stack direction="column" spacing={2}>
    <Stack direction="row" spacing={2}>
      <Button
        component="label"
        variant="contained"
        startIcon={<CloudUpload />}
      >
        Upload CSVs
        <VisuallyHiddenInput onChange={onFileSelect} multiple type="file" />
      </Button>
      <TextField value={startYear} onChange={e => { setStartYear(Number.parseInt(e.target.value)) }} label="Starting Year" type="number" />
    </Stack>
    <Stack direction="row" spacing={2}>
      <EasyTracImport importer={easytracImporter} startYear={startYear} />
    </Stack>
  </Stack>;
}

function EasyTracImport({ importer, startYear }: { importer?: EasyTracImporter, startYear: number }) {
  const [status, setStatus] = useState("Upload EasyTrac/Log CSV files to continue");
  const [error, setError] = useState<string | undefined>();
  const [done, setDone] = useState(false);
  const [latestProgress, setLatestProgress] = useState<EasyTracImporter['progress'] | undefined>();
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    if (importer && !latestProgress) {
      setLatestProgress(importer.progress);
      setStatus("Running");
      wrapPromise(importer.import(startYear)).then(res => {
        setDone(true);
        if (res.isErr()) {
          console.error(res.error);
          setError("EasyTrac import failed with error: " + errorToString(res.error));
          return;
        } else {
          setStatus("Import successful");
        }
      });
    }
  }, [importer, latestProgress]);

  useEffect(() => {
    if (importer && latestProgress && !done) {
      const startTime = dayjs();
      const int = setInterval(() => {
        setElapsedTime(dayjs().diff(startTime));
      }, 100);

      return () => { clearInterval(int) };
    }
    return;
  }, [importer, latestProgress, done]);

  return <Paper variant="outlined" sx={{ padding: 2 }}>
    <Stack direction="column" spacing={2}>
      <Typography variant="h5">EasyTrac Import</Typography>
      {latestProgress ?
        [...latestProgress.entries()].map(([k, v]) => <Typography key={k}>
          {k}: {v}
        </Typography>) :
        null}
      <Typography>{status}</Typography>
      {!done ?
        <Stack direction="row" spacing={1}>
          <CircularProgress size="small" />
          <Typography>{Math.floor(elapsedTime / 1000)}s</Typography>
        </Stack> :
        null}
      {error ? <Typography color="error">{error}</Typography> : null}
    </Stack>
  </Paper>;
}
