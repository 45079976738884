import { StoreContext } from "@/hooks/useStore";
import { Payout, PayoutID } from "@/model";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { useCallback, useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { errorToString } from "@/format/errors";
import DatePickerField from "./helpers/DatePickerField";
import { useStoreWatchOne } from "@/hooks/useStoreWatch";

export function PayoutEditByID({ id }: { id: PayoutID }) {
  const payoutResult = useStoreWatchOne(s => s.watchPayout, id);

  if (!payoutResult) {
    return <CircularProgress />;
  }
  if (payoutResult.isErr()) {
    return <Alert severity="error">Failed to load payout {id}: {errorToString(payoutResult.error)}</Alert>
  }

  const payout = payoutResult.value;
  if (!payout) {
    return <Typography color="text.error">Payout {id} not found</Typography>;
  }

  return <PayoutEdit payout={payout} />;
}

export default function PayoutEdit({ payout }: { payout: Payout | null }) {
  const [_, setLocation] = useLocation();
  const [saving, setSaving] = useState(false);
  const [saveErr, setSaveErr] = useState<null | string>(null);
  const store = useContext(StoreContext);
  const methods = useForm<Payout>({
    mode: "onBlur",
    defaultValues: payout ?? {},
    resolver: zodResolver(Payout),
  });
  const { handleSubmit, formState: { errors }, register } = methods;

  const onSubmit = useCallback(async (value: Payout) => {
    setSaveErr(null);
    setSaving(true);
    try {
      await store.addOrUpdatePayout(value);
      setLocation(`/payroll/${value.id}`, { replace: true });
    } catch (e) {
      setSaveErr("Failed to save payout");
      console.error(e);
    } finally {
      setSaving(false);
    }
  }, [store]);

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" spacing={2}>
          <TextField
            disabled
            {...register("initials")}
            label="Appraiser"
            variant="standard"
          />
          <TextField
            {...register("description")}
            autoFocus
            error={!!errors.description}
            helperText={errors.description?.message}
            label="Notes"
            variant="standard"
          />
          <DatePickerField<Payout>
            name="paidOn"
            label="Paid On"
          />
          <Stack direction="row" spacing={2} alignSelf="center">
            <Button type="submit" variant="contained" disabled={saving}>Submit</Button>
            <Button variant="text" disabled={saving} onClick={() => {
              setLocation(`/payroll` + (payout ? `/${payout.id}` : ""));
            }}>Cancel</Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
    {saveErr ?
      <Alert severity="error">{saveErr}</Alert> :
      null}
  </LocalizationProvider>;
}


