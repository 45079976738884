import { Appraiser, AppraiserInitials } from "@/model";
import { Store } from "@/store/store";
import { DependencyList, createContext, useContext, useEffect, useState } from "react";
import { Err, Ok, Result } from "ts-results-es";


export const StoreContext = createContext<Store>(null as unknown as Store);

export function useStore(fn: (s: Store) => Promise<void>, deps: DependencyList | undefined) {
  const s = useContext(StoreContext);
  useEffect(() => {
    fn(s).catch(err => {
      console.error("useStore failed", err);
    });
  }, deps);
}

export function useStoreValue<T>(fn: (s: Store) => Promise<T>, deps: DependencyList | undefined): undefined | Result<T, unknown> {
  const [result, setResult] = useState<undefined | Result<T, unknown>>(undefined);

  useStore(async s => {
    setResult(undefined);
    try {
      setResult(Ok(await fn(s)));
    } catch (e) {
      setResult(Err(e));
    }
  }, deps);

  return result;
}

export function useActiveAppraisers(): Map<AppraiserInitials, Appraiser> | undefined {
  const [appraisers, setAppraisers] = useState<Map<AppraiserInitials, Appraiser> | undefined>();
  useStore(async s => {
    if (!appraisers) {
      const aps = await s.listAppraisers({ type: "appraiserIsActive", isActive: true });
      setAppraisers(new Map(aps.map(a => [a.initials, a])))
    }
  }, [appraisers]);
  return appraisers;
}
