import { errorToString } from "@/format/errors";
import { StoreContext } from "@/hooks/useStore";
import { Client } from "@/model";
import wrapPromise from "@/util/wrapPromise";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Button, Stack, TextField } from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

export const ClientNameRequired = Client.extend({
  name: z.string(),
});
export type ClientNameRequired = z.infer<typeof ClientNameRequired>;

interface Props {
  client: Client;
  onSaved(client: ClientNameRequired): void;
  onCancel(): void;
}

export default function ClientEditForm(props: Props) {
  const { register, formState: { errors }, handleSubmit } = useForm<ClientNameRequired>({
    defaultValues: props.client,
    resolver: zodResolver(ClientNameRequired),
  });

  const store = useContext(StoreContext);
  const [error, setError] = useState<string | undefined>(undefined);
  const [saving, setSaving] = useState(false);

  const onSubmit = useCallback(async (client: ClientNameRequired) => {
    console.log("Adding client", client);
    setSaving(true);
    const res = await wrapPromise(store.addOrUpdateClient(client));
    setSaving(false);
    console.log("done");
    if (res.isErr()) {
      setError(errorToString(res.error));
    } else {
      props.onSaved(client);
    }
  }, [props.onSaved, store]);

  return <form onSubmit={e => {
    e.stopPropagation();
    handleSubmit(onSubmit)(e);
  }}>
    <Stack direction="column" spacing={2} sx={{ padding: 2 }} useFlexGap flexWrap="wrap">
      <TextField {...register(`name`)}
        error={!!errors.name}
        helperText={errors.name?.message}
        sx={{ maxWidth: '20em' }}
        variant="standard"
        label="Name" />
      <TextField {...register(`address1`)} sx={{ maxWidth: '20em' }} variant="standard" label="Address" />
      <TextField {...register(`address2`)} sx={{ maxWidth: '20em' }} variant="standard" label="Address Line 2" />
      <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
        <TextField {...register(`city`)} sx={{ width: '9em' }} variant="standard" label="City" />
        <TextField {...register(`state`)} sx={{ width: '3em' }} variant="standard" label="State" />
        <TextField {...register(`zipcode`)} sx={{ width: '6em' }} variant="standard" label="Zipcode" />
      </Stack>
      <TextField {...register(`contactName`)} variant="standard" sx={{ maxWidth: '20em' }} label="Contact Name" />
      <TextField {...register(`title`)} variant="standard" sx={{ maxWidth: '20em' }} label="Title" />
      <TextField {...register(`email`)} variant="standard" sx={{ maxWidth: '20em' }} label="Email" />
      <TextField {...register(`phone`)} variant="standard" sx={{ maxWidth: '20em' }} label="Phone" />
      <Stack direction="row" spacing={2} alignSelf="center">
        <Button type="submit" variant="contained" disabled={saving}>Submit</Button>
        <Button variant="text" disabled={saving} onClick={() => { props.onCancel() }} >Cancel</Button>
      </Stack>
      {error ? <Alert severity="error">Failed to save: {error}</Alert> : null}
    </Stack>
  </form>
}
