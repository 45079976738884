import dayjs from "dayjs";
import { Typography } from "@mui/material";
import React from "react";

interface Props {
  date: Date | null | undefined;
  missingText?: string;
}

export function formatDate(d: Date, forceFull: boolean = true): string {
  if (d.getFullYear() !== (new Date()).getFullYear() || forceFull) {
    //if (Math.abs(d.getTime() - (new Date()).getTime()) >= 1000*60*60*24*180) {
    return dayjs(d).format("MM/DD/YY");
  } else {
    return dayjs(d).format("MMM D");
  }
}

export default function DateDisplay({ date, missingText }: Props) {
  return date ?
    <Typography component="span" whiteSpace="nowrap">{formatDate(date)}</Typography> :
    missingText ?
      <Typography color="grey.500" whiteSpace="nowrap" component="span">{missingText}</Typography> :
      null;
}
