import { errorToString } from "@/format/errors";
import { useStoreValue } from "@/hooks/useStore";
import { Appraiser } from "@/model";
import { AppraiserFilter } from "@/store/store";
import { Alert, Button, CircularProgress, FormControlLabel, FormGroup, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "wouter";


export default function AppriaserList() {
  const [_, setLocation] = useLocation();
  const [showInactive, setShowInactive] = useState(false);

  const appraisersResult = useStoreValue(async s => {
    const filters = [] as AppraiserFilter[];
    if (!showInactive) {
      filters.push({ type: "appraiserIsActive", isActive: true });
    }
    const apprs = await s.listAppraisers(...filters);
    return apprs.sort((a, b) => {
      return a.initials.localeCompare(b.initials);
    });
  }, [showInactive]);

  if (!appraisersResult) {
    return <CircularProgress />;
  }

  if (appraisersResult.isErr()) {
    return <Alert severity="error">Failed to load appraiser list: {errorToString(appraisersResult.error)}</Alert>
  }

  const appraisers = appraisersResult.value;

  return <Stack direction="column" spacing={2}>
    <Stack direction="row" spacing={2} sx={{ alignSelf: "flex-end" }}>
      <Button onClick={() => { setLocation("/appraisers/new") }} variant="contained">New Appraiser</Button>
      <FormGroup>
        <FormControlLabel control={
          <Switch
            checked={showInactive}
            onChange={e => {
              setShowInactive(e.target.checked);
            }}
          />}
          label="Show inactive" />
      </FormGroup>
    </Stack>
    <AppraiserTable appraisers={appraisers} />
  </Stack>
}

function AppraiserTable({ appraisers }: { appraisers: Appraiser[] }) {
  const [_, setLocation] = useLocation();
  return <TableContainer component={Paper}>
    <Table stickyHeader size="small">
      <TableHead>
        <TableRow>
          <TableCell>Initials</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Is Active</TableCell>
          <TableCell>Commission</TableCell>
          <TableCell>License #</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {appraisers.length === 0 ?
          <TableRow><TableCell>No appraisers</TableCell></TableRow> :
          appraisers.map(a =>
            <TableRow
              hover
              sx={{ cursor: 'pointer' }}
              key={a.initials}
              onClick={() => setLocation(`/appraisers/${a.initials}`)}
            >
              <TableCell><Typography fontWeight="bold">{a.initials}</Typography></TableCell>
              <TableCell><Typography>{a.name}</Typography></TableCell>
              <TableCell>{a.isActive ?
                <Typography>Active</Typography> :
                <Typography color="text.secondary">Inactive</Typography>}</TableCell>
              <TableCell><Typography>{a.commissionPercent ? `${a.commissionPercent}%` : ""}</Typography></TableCell>
              <TableCell><Typography>{a.licenseNumber}</Typography></TableCell>
            </TableRow>
          )}
      </TableBody>
    </Table>
  </TableContainer >;
}
