import { useActiveAppraisers, useStoreValue } from "@/hooks/useStore";
import { Job } from "@/model";
import React, { useMemo, useState } from "react";
import { PriceDisplay } from "./helpers/PriceDisplay";
import { Alert, Autocomplete, Box, Button, CircularProgress, IconButton, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useLocation } from "wouter";
import DateDisplay from "./helpers/DateDisplay";
import { exportJobsToExcel } from "@/export/excel";
import { JobFilter, JobStatusFilter, UNASSIGNED } from "@/store/store";
import { z } from "zod";
import { Result } from "ts-results-es";
import { errorToString } from "@/format/errors";
import { Tags } from "@/tags";
import { LocalSaver } from "@/util/localSaver";
import ClientNameAutocomplete from "./helpers/ClientNameAutocomplete";
import { Clear } from "@mui/icons-material";
import JobStatusChip from "./helpers/JobStatusChip";
import { useSingleFieldSorting, useSorting } from "./helpers/sorting";

export enum JobStatusOption {
  OPEN = "Open",
  RECEIVED = "Received",
  ON_HOLD = "On Hold",
  ASSIGNED = "Assigned",
  INSPECTED = "Inspected",
  REVIEWED = "Reviewed",
  DELIVERED = "Delivered",
  CLOSED = "Closed",
}

const JobFilterSet = z.object({
  status: z.nativeEnum(JobStatusOption).optional(),
  initials: z.string().optional(),
  receivedOnRange: z.tuple([z.date().optional(), z.date().optional()]).optional(),
  tags: z.array(z.string()).optional(),
  city: z.string().optional(),
  projectPrefix: z.string().optional(),
  clientNamePrefix: z.string().optional(),
  ownerName: z.string().optional(),
  fileNumberPrefix: z.string().optional(),
  isPaid: z.boolean().optional(),
});
type JobFilterSet = z.infer<typeof JobFilterSet>;

interface Props {
  defaultFilters?: JobFilterSet;
}

export function JobList(props: Props) {
  const [_, setLocation] = useLocation();

  const filterSaver = new LocalSaver("job-filters", JobFilterSet);

  const [filterOptions, setFilterOptions] = useState<JobFilterSet>(() => {
    if (history.state?.filterOptions) {
      return history.state.filterOptions as JobFilterSet;
    }
    return filterSaver.load(props.defaultFilters ?? {});
  });

  const jobsResult = useStoreValue(async store => {
    const filters: JobFilter[] = [];
    if (filterOptions.status) {
      filters.push(jobStatusOptionToFilter(filterOptions.status));
    }
    if (filterOptions.initials) {
      filters.push({ type: 'appraiserInitials', initials: filterOptions.initials === UNASSIGNED ? UNASSIGNED : [filterOptions.initials] });
    }
    if (filterOptions.tags) {
      filters.push({ type: "tags", tags: filterOptions.tags });
    }
    if (filterOptions.projectPrefix) {
      filters.push({ type: "project", projectPrefix: filterOptions.projectPrefix });
    }
    if (filterOptions.clientNamePrefix) {
      filters.push({ type: "clientNamePrefix", clientNamePrefix: filterOptions.clientNamePrefix });
    }
    if (filterOptions.fileNumberPrefix) {
      filters.push({ type: "fileNumberPrefix", prefix: filterOptions.fileNumberPrefix });
    }
    if (filterOptions.isPaid !== undefined) {
      filters.push({ type: "isPaid", isPaid: filterOptions.isPaid });
    }
    return (await store.listJobs(...filters)).sort((a, b) => (b.receivedOn?.getTime() ?? 0) - (a.receivedOn?.getTime() ?? 0));
  }, [filterOptions]);

  return <Stack direction="column" spacing={2} maxWidth="100%">
    <Typography variant="h5">Jobs</Typography>
    <Stack direction="row" className="hidden-print" alignItems="center">
      <FilterBar filters={filterOptions} onChange={fs => {
        setFilterOptions(fs);
        filterSaver.save(fs);
      }} />
    </Stack>
    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
      {jobsResult?.isOk() ? <Typography>{jobsResult.value.length} jobs found</Typography> : <Typography>Loading...</Typography>}
      <Box>
        {jobsResult?.isOk() ? <Button onClick={() => { exportJobsToExcel(jobsResult.value) }} variant="text">Export to Excel</Button> : null}
        <Button onClick={() => { setLocation("/jobs/new") }} variant="contained">New Job</Button>
      </Box>
    </Stack>
    <JobTable jobsResult={jobsResult} />
  </Stack>;
}

function JobTable({ jobsResult }: { jobsResult?: Result<Job[], any> }) {
  const [_, setLocation] = useLocation();

  const [sortField, direction, SortingLabel] = useSingleFieldSorting<keyof Job>("fileNumber", "desc");
  const jobs = useSorting(jobsResult?.unwrapOr(undefined), sortField, direction);

  if (!jobsResult) {
    return <CircularProgress />;
  }

  if (jobsResult.isErr()) {
    return <Alert severity="error">Failed to fetch jobs: {errorToString(jobsResult.error)}</Alert>;
  }

  return <TableContainer component={Paper}>
    <Table stickyHeader size="small">
      <TableHead>
        <TableRow>
          <TableCell><SortingLabel field="fileNumber">File #</SortingLabel></TableCell>
          <TableCell align="right"><SortingLabel field="title">Title</SortingLabel></TableCell>
          <TableCell align="right"><SortingLabel field="clientName">Client</SortingLabel></TableCell>
          <TableCell align="right">Assigned To</TableCell>
          <TableCell align="right"><SortingLabel field="totalFeeDollars">Price</SortingLabel></TableCell>
          <TableCell align="right"><SortingLabel field="receivedOn">Received On</SortingLabel></TableCell>
          <TableCell align="right"><SortingLabel field="dueOn">Due Date</SortingLabel></TableCell>
          <TableCell align="right">Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {jobs.length === 0 ?
          <TableRow><TableCell>No jobs</TableCell></TableRow> :
          jobs.map(job => {
            return <TableRow
              hover
              sx={{ cursor: 'pointer' }}
              key={job.fileNumber}
              onClick={() => setLocation(`/jobs/${job.fileNumber}`)}
            >
              <TableCell><Typography>{job.fileNumber}</Typography></TableCell>
              <TableCell align="right"><Stack direction="column">
                <Typography sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{job.title ?? job.comments}</Typography>
                {job.project ? <Stack direction="row" alignItems="flex-end"><Typography color="text.secondary" sx={{ fontVariant: "small-caps", fontVariantCaps: "all-petite-caps", fontWeight: "bold" }}>Project:</Typography> <Typography sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", fontStyle: "italic" }}>{job.project}</Typography></Stack> : null}
              </Stack></TableCell>
              <TableCell align="right">{job.clientName ? <Typography whiteSpace="nowrap" textOverflow="ellipsis">{job.clientName}</Typography> : <Typography color="grey.500">Unknown</Typography>}</TableCell>
              <TableCell align="right">{job.mainAssignedInitials ? <Typography>{job.mainAssignedInitials.join("; ")}</Typography> : <Typography color="text.secondary">Unassigned</Typography>}</TableCell>
              <TableCell align="right"><PriceDisplay fee={job.fee} /></TableCell>
              <TableCell align="right"><DateDisplay date={job.receivedOn} missingText="Unknown" /></TableCell>
              <TableCell align="right"><DateDisplay date={job.dueOn} missingText="No due date" /></TableCell>
              <TableCell align="right">
                <Stack direction="row" spacing={1}>
                  <JobStatusChip job={job} />
                </Stack>
              </TableCell>
            </TableRow>;
          })}
      </TableBody>
    </Table>
  </TableContainer >;
}

interface FilterBarProps {
  onChange: (filters: JobFilterSet) => void;
  filters: JobFilterSet;
}

function jobStatusOptionToFilter(f: JobStatusOption): JobStatusFilter {
  switch (f) {
    case JobStatusOption.OPEN:
      return {
        type: 'status', statuses: [
          "received",
          "assigned",
          "inspected",
          "reviewed",
          "delivered",
        ]
      };
    case JobStatusOption.RECEIVED:
      return { type: 'status', statuses: ["received"] };
    case JobStatusOption.ASSIGNED:
      return { type: 'status', statuses: ["assigned"] };
    case JobStatusOption.INSPECTED:
      return { type: 'status', statuses: ["inspected"] };
    case JobStatusOption.REVIEWED:
      return { type: 'status', statuses: ["reviewed"] };
    case JobStatusOption.DELIVERED:
      return { type: 'status', statuses: ["delivered"] };
    case JobStatusOption.ON_HOLD:
      return { type: 'status', statuses: ["onHold"] };
    case JobStatusOption.CLOSED:
      return { type: 'status', statuses: ["closed"] };
  }
}

function FilterBar({ onChange, filters: initialFilters }: FilterBarProps) {
  const appraisers = useActiveAppraisers();
  const [pendingFilters, setPendingFilters] = useState(initialFilters);

  const isChanged = useMemo(() => {
    return JSON.stringify(initialFilters) !== JSON.stringify(pendingFilters);
  }, [initialFilters, pendingFilters]);

  return <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap" flexGrow={1}>

    {/* Status filter */}
    <TextField
      sx={{ minWidth: '10em' }}
      select
      value={pendingFilters.status ?? ""}
      onChange={e => {
        const v = e.target.value;
        setPendingFilters({ ...pendingFilters, status: v ? v as JobStatusOption : undefined });
      }}
      label="Status"
    >
      <MenuItem value="">
        <em>Any</em>
      </MenuItem>
      {Object.values(JobStatusOption).map(v =>
        <MenuItem key={v} value={v}><Typography fontWeight="bold">{v}</Typography></MenuItem>)}
    </TextField>

    {/* Is Paid filter */}
    <TextField
      sx={{ minWidth: '10em' }}
      select
      value={pendingFilters.isPaid === true ? "Yes" : pendingFilters.isPaid === false ? "No" : ""}
      onChange={e => {
        const v = e.target.value;
        setPendingFilters({ ...pendingFilters, isPaid: v ? (v === "Yes") : undefined });
      }}
      label="Is Paid?"
    >
      <MenuItem value="">
        <em>Any</em>
      </MenuItem>
      <MenuItem value="Yes">
        Yes
      </MenuItem>
      <MenuItem value="No">
        No
      </MenuItem>
    </TextField>

    {/* Appraiser filter */}
    <TextField
      sx={{ minWidth: '10em' }}
      select
      value={(appraisers && pendingFilters.initials) ? pendingFilters.initials : ""}
      onChange={e => {
        setPendingFilters({ ...pendingFilters, initials: e.target.value });
      }}
      label="Appraiser"
    >
      <MenuItem value="">
        <em>Any</em>
      </MenuItem>
      <MenuItem value={UNASSIGNED}>
        <em>Unassigned</em>
      </MenuItem>
      {appraisers ? [...appraisers.values()].map((a) => <MenuItem key={a.initials} value={a.initials}>{a.initials} ({a.name})</MenuItem>) : null}
    </TextField>

    {/* File Number filter */}
    <TextField
      value={pendingFilters.fileNumberPrefix ?? ""}
      onChange={(e) => {
        setPendingFilters({ ...pendingFilters, fileNumberPrefix: e.target.value ?? undefined });
      }}
      sx={{ minWidth: "10em" }}
      label="File Number"
      placeholder="Starts with"
      InputProps={{
        endAdornment: (
          pendingFilters.fileNumberPrefix ?
            <IconButton onClick={() => { setPendingFilters({ ...pendingFilters, fileNumberPrefix: undefined }) }} >
              <Clear />
            </IconButton> :
            null
        ),
      }}
    />

    {/* Tag filter */}
    <Autocomplete
      value={pendingFilters.tags ?? []}
      onChange={(_, v) => {
        setPendingFilters({ ...pendingFilters, tags: v.length > 0 ? v : undefined });
      }}
      multiple
      sx={{ minWidth: '10em' }}
      getOptionLabel={o => Tags.get(o)!}
      options={[...Tags.keys()]}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ maxWidth: "20em" }}
          label="Tags"
        />
      )}
    />

    {/* Project filter */}
    <TextField
      value={pendingFilters.projectPrefix ?? ""}
      onChange={(e) => {
        setPendingFilters({ ...pendingFilters, projectPrefix: e.target.value ?? undefined });
      }}
      sx={{ minWidth: "10em" }}
      label="Project"
      placeholder="Starts with"
      InputProps={{
        endAdornment: (
          pendingFilters.projectPrefix ?
            <IconButton onClick={() => { setPendingFilters({ ...pendingFilters, projectPrefix: undefined }) }} >
              <Clear />
            </IconButton> :
            null
        ),
      }}
    />

    {/* Client filter */}
    <ClientNameAutocomplete
      value={pendingFilters.clientNamePrefix}
      onChange={name => {
        setPendingFilters({ ...pendingFilters, clientNamePrefix: name ?? undefined });
      }}
      label="Client name"
      placeholder="Starts with"
    />

    {isChanged ? <Button variant="outlined" onClick={() => { onChange(pendingFilters) }}>Apply Filters</Button> : null}
  </Stack >;
}

