export function errorToString(e: any): string {
  if (typeof e === "string") return e;

  if ('format' in e && typeof e.format === "function" && e.format.length === 0) {
    return e.format();
  }

  if ('message' in e) {
    return e.message;
  }

  if ('toString' in e && typeof e.toString === "function") {
    return e.toString();
  }

  return JSON.stringify(e);
}
