import { Job } from "@/model"
import { Chip } from "@mui/material";
import React from "react";

export default function JobStatusChip({ job }: { job: Job }) {
  if (job.dueOn && job.dueOn <= new Date() && !["closed", "delivered"].includes(job.status ?? "")) {
    return <Chip label="Past Due" color="error" variant="outlined" />;
  }

  switch (job.status) {
    case "received":
      return <Chip label="Received" variant="outlined" color="info" />;
    case "onHold":
      return <Chip label="On Hold" variant="outlined" color="warning" />;
    case "assigned":
      return <Chip label="Assigned" variant="outlined" color="info" />;
    case "inspected":
      return <Chip label="Inspected" variant="outlined" color="info" />;
    case "reviewed":
      return <Chip label="Reviewed" variant="outlined" color="success" />;
    case "delivered":
      return <Chip label="Delivered" variant="outlined" color="success" />;
    case "closed":
      if (job.isInvoicePaid) {
        return <Chip label="Closed/Paid" variant="outlined" color={"success"} />;
      } else {
        return <Chip label="Closed/Unpaid" variant="outlined" color="warning" />;
      }
    case undefined:
      return <Chip label="?" variant="outlined" color="info" />;
  }
}
