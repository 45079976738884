import formatDollarsCents from "@/format/formatDollarsCents";
import { Job } from "@/model";
import { Typography } from "@mui/material";
import React from "react";


export function PriceDisplay({ fee }: { fee: Job['fee'] }) {
  if (!fee) {
    return <Typography color="text.secondary" component="span">Unknown</Typography>;
  }
  if (fee.model === "fixed") {
    return <Typography component="span">{formatDollarsCents(fee.priceDollars ?? 0)}</Typography>;
  } else {
    return <Typography component="span">Hourly</Typography>;
  }
}
