import useDialog, { DialogProps } from "@/hooks/useDialog";
import useClientQueryDebounced from "@/hooks/useClientQueryDebounced";
import { Client, ClientID } from "@/model";
import { generateId } from "@/store/store";
import { Autocomplete, CircularProgress, Dialog, DialogTitle, TextField, TextFieldVariants } from "@mui/material";
import React, { useState } from "react";
import { useRef } from "react";
import ClientEditForm from "../ClientEditForm";
import { Ok } from "ts-results-es";
import { errorToString } from "@/format/errors";


interface Props {
  value: { id: ClientID, name: string | null | undefined } | null;
  onChange(val: { id: ClientID, name: string | null | undefined } | null): void;
  label?: string;
  variant?: TextFieldVariants;
}

export default function ClientInput({ value, onChange, label, variant }: Props) {
  const clientNameRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | undefined>(undefined);

  const { showDialog, props: dialogProps } = useDialog<Client>(async client => {
    onChange({ id: client.id, name: client.name ?? "" });
    return Ok(null);
  }, [onChange]);

  const [clientsResult, loading] = useClientQueryDebounced({
    ref: clientNameRef,
  });

  let clients;
  if (clientsResult === undefined) { }
  else if (clientsResult.isErr()) {
    setError(errorToString(clientsResult.error));
  } else {
    clients = clientsResult.value;
  }

  return <>
    <Autocomplete
      value={value}
      loading={loading}
      onChange={(_, newValue) => {
        console.log(newValue);
        if (typeof newValue === "string") {
          showDialog({ id: generateId(newValue.toUpperCase().replace(/\s/g, '')), name: newValue });
        } else if (newValue && !newValue.id) {
          showDialog({ id: generateId((newValue.name ?? "").toUpperCase().replace(/\s/g, '')), name: newValue.name ?? "" });
        } else {
          onChange(newValue)
        }
      }}
      filterOptions={(options, params) => (
        params.inputValue ?
          [...options, { id: "", name: params.inputValue }] :
          options)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          // Prevent's default 'Enter' behavior.
          event.defaultMuiPrevented = true;
          // your handler code
        }
      }}
      freeSolo
      selectOnFocus
      autoHighlight
      clearOnBlur
      includeInputInList
      handleHomeEndKeys
      getOptionKey={opt => typeof opt === "string" ? opt : opt.id}
      getOptionLabel={opt => {
        return typeof opt === "string" ?
          `Add new: '${opt}'` :
          !opt.id ?
            `Add new: '${opt.name}'` :
            (opt.name ?? "");
      }}
      options={clients ? [...clients.values()].map(a => ({ id: a.id, name: a.name })) : []}
      renderInput={(params) => (
        <TextField
          {...params}
          ref={clientNameRef}
          variant={variant}
          label={(label ?? "Client") + " Name"}
          helperText={error}
          sx={{ minWidth: '15em' }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
    <AddOrEditClientDialog {...dialogProps} />
  </>;
}

function AddOrEditClientDialog({ onClose, value, open, onSave }: DialogProps<Client>) {
  return <Dialog onClose={() => { onClose(); }} open={open}>
    <DialogTitle>Add Client</DialogTitle>
    {value ? <ClientEditForm
      client={value}
      onSaved={onSave}
      onCancel={onClose}
    /> : null}
  </Dialog>
}
