import { Client } from "@/model";
import { Alert, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "wouter";
import { errorToString } from "@/format/errors";
import { useStoreWatchOne } from "@/hooks/useStoreWatch";
import ClientEditForm from "./ClientEditForm";
import { nanoid } from "nanoid";

export function ClientEditByID({ id }: { id: string }) {
  const clientResult = useStoreWatchOne(s => s.watchClient, id);

  if (!clientResult) {
    return <CircularProgress />;
  }
  if (clientResult.isErr()) {
    return <Alert severity="error">Failed to load client {id}: {errorToString(clientResult.error)}</Alert>
  }

  const client = clientResult.value;
  if (!client) {
    return <Typography color="text.error">Client {id} not found</Typography>;
  }

  return <ClientEdit client={client} />;
}

export default function ClientEdit({ client }: { client: Client | null }) {
  const [_, setLocation] = useLocation();


  return <ClientEditForm
    client={client ?? { id: nanoid() }}
    onSaved={c => {
      setLocation(`/clients/${c.id}`, { replace: true });
    }}
    onCancel={() => {
      if (client) {
        setLocation(`/clients/${client.id}`);
      } else {
        setLocation(`/clients`);
      }
    }}
  />;
}


