import { StoreContext } from "@/hooks/useStore";
import { LineItem } from "@/model";
import { Alert, Button, CircularProgress, Dialog, DialogTitle, IconButton, InputAdornment, List, ListItem, ListItemText, Stack, TextField, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect } from "react";
import useDialog, { DialogProps } from "@/hooks/useDialog";
import wrapPromise from "@/util/wrapPromise";
import formatDollarsCents from "@/format/formatDollarsCents";
import { Delete, Edit } from "@mui/icons-material";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import useHover from "@/hooks/useHover";
import { useStoreWatchCollection } from "@/hooks/useStoreWatch";
import { errorToString } from "@/format/errors";
import { nanoid } from "nanoid";

export function LineItemList({ invoiceNumber }: { invoiceNumber: string; }) {
  const store = useContext(StoreContext);

  const lineItemsResult = useStoreWatchCollection(s => s.watchLineItems, invoiceNumber);

  const addToStore = useCallback(async (v: LineItem) => {
    return wrapPromise(store.addOrUpdateLineItem(v));
  }, [store, invoiceNumber]);

  const { showDialog: showCustomLineItemDialog, props: customDialogProps } = useDialog((v: LineItem) => addToStore(v), [addToStore]);

  if (!lineItemsResult) {
    return <CircularProgress />;
  }

  if (lineItemsResult.isErr()) {
    return <Alert severity="error">{errorToString(lineItemsResult.error)}</Alert>;
  }

  const lineItems = lineItemsResult.value;

  return <Stack direction="column" spacing={2} alignItems="flex-start">
    <List dense>
      {lineItems ?
        lineItems.map((t) =>
          <LineItemRow
            key={t.id}
            lineItem={t}
            startEditing={() => { showCustomLineItemDialog(t); }}
            onDelete={() => {
              return wrapPromise(store.removeLineItem(invoiceNumber, t.id));
            }}
          />
        )
        : <Typography color="text.secondary">None</Typography>}
    </List>
    <Stack direction="row" spacing={2}>
      <Button onClick={() => showCustomLineItemDialog({ id: nanoid(), invoiceNumber })}>Add Line Item</Button>
    </Stack>
    <AddOrEditLineItemDialog {...customDialogProps} />
  </Stack >;
}

interface RowProps {
  lineItem: LineItem;
  startEditing: () => void;
  onDelete: () => void;
}

export function LineItemRow({ lineItem, startEditing, onDelete }: RowProps) {
  const [hovering, hoverProps] = useHover();
  return <ListItem {...hoverProps} divider dense sx={{ minWidth: "20em" }} secondaryAction={
    hovering ?
      <>
        <IconButton onClick={startEditing}><Edit /></IconButton>
        <IconButton onClick={onDelete}><Delete /></IconButton>
      </> :
      null
  }>
    <ListItemText
      primary={
        lineItem.amountDollars !== undefined ?
          formatDollarsCents(lineItem.amountDollars) :
          "$?"
      }
      secondary={lineItem.description}
    />
  </ListItem>
}

export function AddOrEditLineItemDialog({ open, onClose, onSave, value, error }: DialogProps<LineItem>) {
  const methods = useForm<LineItem>({
    resolver: zodResolver(LineItem),
  });
  const { handleSubmit, formState: { errors }, register, reset } = methods;

  useEffect(() => {
    reset(value ?? { description: "" });
  }, [value, open]);

  return <Dialog onClose={() => { onClose() }} open={open}>
    <DialogTitle>{value?.amountDollars ? "Edit" : "Add"} Line Item</DialogTitle>
    <form onSubmit={handleSubmit(li => onSave(li), err => { console.error(err) })}>
      <FormProvider {...methods}>
        <Stack direction="column" spacing={2} sx={{ padding: 2 }} useFlexGap>
          <TextField
            {...register("amountDollars", {
              setValueAs: v => v ? Number.parseFloat(v) : 0,
            })}
            error={!!errors.amountDollars}
            helperText={errors.amountDollars?.message}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            variant="standard"
            label="Amount"
          />
          <TextField
            {...register("description", { required: false })}
            variant="standard"
            label="Description"
          />
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button variant="text" onClick={() => onClose()}>Cancel</Button>
            <Button type="submit">{value?.amountDollars ? "Edit" : "Add"}</Button>
          </Stack>
          {error ? <Alert severity="error">{error}</Alert> : null}
        </Stack>
      </FormProvider>
    </form>
  </Dialog>;
}
