import { StoreContext } from "@/hooks/useStore";
import { Appraiser } from "@/model";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { useCallback, useContext, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { errorToString } from "@/format/errors";
import DatePickerField from "./helpers/DatePickerField";
import { z } from "zod";
import { useStoreWatchOne } from "@/hooks/useStoreWatch";

export function AppraiserEditByInitials({ initials }: { initials: string }) {
  const appraiserResult = useStoreWatchOne(s => s.watchAppraiser, initials);

  if (!appraiserResult) {
    return <CircularProgress />;
  }
  if (appraiserResult.isErr()) {
    return <Alert severity="error">Failed to load appraiser {initials}: {errorToString(appraiserResult.error)}</Alert>
  }

  const appraiser = appraiserResult.value;
  if (!appraiser) {
    return <Typography color="text.error">Appraiser {initials} not found</Typography>;
  }

  return <AppraiserEdit appraiser={appraiser} />;
}

export default function AppraiserEdit({ appraiser }: { appraiser: Appraiser | null }) {
  const [_, setLocation] = useLocation();
  const [saving, setSaving] = useState(false);
  const [saveErr, setSaveErr] = useState<null | string>(null);
  const store = useContext(StoreContext);
  const methods = useForm<Appraiser>({
    mode: "onBlur",
    defaultValues: appraiser ?? {},
    resolver: zodResolver(Appraiser.extend({
      name: z.string().min(1),
      initials: z.string().min(1).refine(async v => {
        if (appraiser) {
          return true;
        }
        try {
          const appraisers = await store.listAppraisers({ type: "appraiserInitials", initials: [v] });
          return !appraisers.length;
        } catch (e) {
          console.error("Failed to validate initials", e);
          return false;
        }

      }, { message: "This set of initials is already used" }),
    })),
  });
  const { handleSubmit, formState: { errors }, register } = methods;

  const onSubmit = useCallback(async (value: Appraiser) => {
    setSaveErr(null);
    setSaving(true);
    try {
      await store.addOrUpdateAppraiser(value);
      setLocation(`/appraisers/${value.initials}`, { replace: true });
    } catch (e) {
      setSaveErr("Failed to save appraiser");
      console.error(e);
    } finally {
      setSaving(false);
    }
  }, [store]);

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            {appraiser ?
              <Typography variant="h6">{appraiser.initials}</Typography> :
              <TextField
                {...register("initials")}
                autoFocus
                error={!!errors.initials}
                helperText={errors.initials?.message}
                label="Initials"
                variant="standard"
              />}
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              {...register("name")}
              error={!!errors.name}
              helperText={errors.name?.message}
              sx={{ width: '15em' }}
              variant="standard"
              label="Name"
            />
            <DatePickerField
              name="hireDate"
              label="Hire Date"
            />
            <TextField {...register("licenseNumber", { required: false })} sx={{ width: '15em' }} variant="standard" label="License Number" />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              {...register("commissionPercent", {
                required: false,
                setValueAs: v => v ? Number.parseFloat(v) : 0,
              })}
              variant="standard"
              label="Commission"
              error={!!errors.commissionPercent}
              helperText={errors.commissionPercent?.message}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }} />
            <TextField
              {...register("hourlyRateDollars", {
                required: false,
                setValueAs: v => v ? Number.parseFloat(v) : 0,
              })}
              variant="standard"
              error={!!errors.hourlyRateDollars}
              helperText={errors.hourlyRateDollars?.message}
              label="Hourly Rate"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                endAdornment: <InputAdornment position="end">/hr</InputAdornment>,
              }} />
          </Stack>
          <FormGroup>
            <FormControlLabel control={
              <Controller
                name="isActive"
                render={({ field }) =>
                  <Checkbox {...field} checked={!!field.value} />
                } />
            } label="Is Active" />
          </FormGroup>
          <Stack direction="row" spacing={2} alignSelf="center">
            <Button type="submit" variant="contained" disabled={saving}>Submit</Button>
            <Button variant="text" disabled={saving} onClick={() => {
              setLocation(`/appraisers` + (appraiser ? `/${appraiser.initials}` : ""));
            }}>Cancel</Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
    {saveErr ?
      <Alert severity="error">{saveErr}</Alert> :
      null}
  </LocalizationProvider>;
}


