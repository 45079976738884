import App from "./components/App";
import { createRoot } from "react-dom/client";
import { User } from "firebase/auth";
import React from "react";
import Login from "./components/Login";

import './app.css';
import { initFirebase } from "./store/firestore/init";
import FireStoreStore from "./store/firestore/store";
import { LocalUser } from "./model";

declare const JT_DEV_MODE: boolean;
declare const FIRESTORE_DATABASE: string | undefined;

if (JT_DEV_MODE) {
  new EventSource('/esbuild').addEventListener('change', () => location.reload())
}

async function main() {
  const [, auth, firestore] = initFirebase(FIRESTORE_DATABASE);

  const root = createRoot(document.getElementsByTagName("div")[0]!);

  const userPromise = new Promise<User>((resolve, reject) => {
    auth.authStateReady().then(() => {
      if (auth.currentUser && !auth.currentUser.isAnonymous) {
        resolve(auth.currentUser);
      }
      auth.onAuthStateChanged(user => {
        if (user !== null) {
          resolve(user);
        }
      });
      root.render(<Login firebaseAuth={auth} />);
    }).catch(reject);
  });

  const authUser = await userPromise;
  console.log(await authUser.getIdTokenResult());
  const user: LocalUser = LocalUser.strict().parse({
    isAdmin: (await authUser.getIdTokenResult()).claims["role"] === "admin",
    displayName: authUser.displayName ?? "",
    email: authUser.email ?? "",
    uid: authUser.uid,
  });
  const store = new FireStoreStore(firestore);
  root.render(<App auth={auth} store={store} user={user} isDevMode={JT_DEV_MODE} />);
}

main().then(() => {
});
