import { TimeBlock } from "@/model";
import React, { useEffect } from "react";
import { Alert, Button, Dialog, DialogTitle, InputAdornment, Stack, TextField } from "@mui/material";
import { DialogProps } from "@/hooks/useDialog";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePickerField from "@/components/helpers/DatePickerField";

export type TimeBlockWithIndex = { timeBlock: TimeBlock, index?: number };

interface Props extends DialogProps<TimeBlockWithIndex> {
  appraiserInitials: string;
}

export default function AddOrEditTimeBlockDialog({ appraiserInitials, open, onClose, onSave, value, error }: Props) {
  const methods = useForm<TimeBlock>({
    resolver: zodResolver(TimeBlock),
  });
  const { handleSubmit, formState: { errors }, register, reset } = methods;

  useEffect(() => {
    reset(value?.timeBlock ?? { description: "" });
  }, [value, open]);

  return <Dialog onClose={() => { onClose(); }} open={open}>
    <DialogTitle>{value?.timeBlock ? "Edit" : "Add"} Time Block for {appraiserInitials}</DialogTitle>
    <form onSubmit={handleSubmit(vals => onSave({ timeBlock: vals, index: value?.index }))}>
      <FormProvider {...methods}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="column" spacing={2} sx={{ padding: 2 }} useFlexGap>
            <TextField
              {...register("hoursSpent", {
                required: true,
                setValueAs: v => v ? Number.parseFloat(v) : 0,
              })}
              error={!!errors.hoursSpent}
              helperText={errors.hoursSpent?.message}
              InputProps={{
                endAdornment: <InputAdornment position="end">hrs</InputAdornment>,
              }}
              variant="standard"
              label="Hours" />
            <DatePickerField
              name="day"
              label="Date" />
            <TextField
              {...register("description", { required: false })}
              variant="standard"
              multiline
              label="Description" />
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button variant="text" onClick={() => onClose()}>Cancel</Button>
              <Button type="submit">{value?.timeBlock ? "Edit" : "Add"}</Button>
            </Stack>
            {error ? <Alert severity="error">{error}</Alert> : null}
          </Stack>
        </LocalizationProvider>
      </FormProvider>
    </form>
  </Dialog>;
}

