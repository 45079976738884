import React, { useRef } from "react";
import { useLocation } from "wouter";
import { Alert, CircularProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Result } from "ts-results-es";
import { Client, ClientID } from "@/model";
import { errorToString } from "@/format/errors";
import useClientQueryDebounced from "@/hooks/useClientQueryDebounced";

export default function ClientList() {
  const clientNameRef = useRef<HTMLInputElement>(null);

  const [clientsResult, loading] = useClientQueryDebounced({
    ref: clientNameRef,
  });

  return <Stack direction="column" spacing={2} my={2} maxWidth="100%">
    <Typography variant="h5">Clients</Typography>
    <Stack direction="row" className="hidden-print" alignItems="center">
      <TextField
        ref={clientNameRef}
        label="Client Name"
        placeholder="Starting with..."
      />
    </Stack>
    <ClientTable clientsResult={clientsResult} loading={loading} />
  </Stack>;
}

function ClientTable({ clientsResult, loading }: { clientsResult?: Result<Map<ClientID, Client>, any>, loading: boolean }) {
  const [_, setLocation] = useLocation();

  if (loading) {
    return <CircularProgress />;
  }

  if (!clientsResult) {
    return <Typography>Start typing a client name to search (at least two letters)</Typography>;
  }

  if (clientsResult.isErr()) {
    return <Alert severity="error">Failed to fetch clients: {errorToString(clientsResult.error)}</Alert>;
  }

  const clients = [...clientsResult.value.values()];

  return <TableContainer component={Paper}>
    <Table stickyHeader size="small">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>City</TableCell>
          <TableCell>State</TableCell>
          <TableCell>Contact</TableCell>
          <TableCell># Jobs</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {clients.length === 0 ?
          <TableRow><TableCell>No clients</TableCell></TableRow> :
          clients.map(client => {
            return <TableRow
              hover
              sx={{ cursor: 'pointer' }}
              key={client.id}
              onClick={() => setLocation(`/clients/${client.id}`)}
            >
              <TableCell><Typography>{client.name}</Typography></TableCell>
              <TableCell><Typography>{client.city}</Typography></TableCell>
              <TableCell><Typography>{client.state}</Typography></TableCell>
              <TableCell><Typography>{client.contactName}</Typography></TableCell>
              <TableCell><Typography>{client.associatedJobs?.length ?? 0}</Typography></TableCell>
            </TableRow>;
          })}
      </TableBody>
    </Table>
  </TableContainer >;
}
