import { z } from "zod";
import { nanoid } from "nanoid";
import { Appraiser, Job, Client, AppraiserInitials, Invoice, InvoiceStatus, JobStatus, FileNumber, Assignment, LineItem, Transaction, Payout } from "@/model";
import { Result } from "ts-results-es";

export type Unsubscriber = () => void;
export type WatchOneCallback<T> = (res: Result<T | undefined, unknown>) => void;
export type WatchCollectionCallback<T> = (res: Result<T[], unknown>) => void;

export interface Store {
  listAppraisers(...filters: AppraiserFilter[]): Promise<Appraiser[]>;
  addOrUpdateAppraiser(appraiser: Appraiser): Promise<void>;
  watchAppraiser(initials: string, cb: WatchOneCallback<Appraiser>): Unsubscriber;

  addOrUpdatePayout(payout: Payout): Promise<void>;
  listPayouts(resultLimit: number, ...filters: PayoutFilter[]): Promise<Payout[]>;
  watchPayout(id: string, cb: WatchOneCallback<Payout>): Unsubscriber;
  updatePayoutWithPaidJobs({id, initials}: Payout): Promise<void>;

  listClients(resultLimit: number, ...filters: ClientFilter[]): Promise<Client[]>;
  addOrUpdateClient(client: Client): Promise<void>;
  watchClient(id: string, cb: WatchOneCallback<Client>): Unsubscriber;
  getClient(id: string): Promise<Client>;

  getInvoice(invoiceNumber: string): Promise<Invoice>;
  listInvoices(...filters: InvoiceFilter[]): Promise<Invoice[]>;
  watchInvoice(invoiceNumber: string, cb: WatchOneCallback<Invoice>): Unsubscriber;
  addOrUpdateInvoice(invoice: Invoice): Promise<void>;
  suggestNextInvoiceNumber(): Promise<string>;

  listLineItems(invoiceNumber: string): Promise<LineItem[]>;
  watchLineItems(invoiceNumber: string, cb: WatchCollectionCallback<LineItem>): Unsubscriber;
  addOrUpdateLineItem(lineItem: LineItem): Promise<void>;
  removeLineItem(invoiceNumber: string, id: LineItem['id']): Promise<void>;

  listTransactions(invoiceNumber: string): Promise<Transaction[]>;
  watchTransactions(invoiceNumber: string, cb: WatchCollectionCallback<Transaction>): Unsubscriber;
  addOrUpdateTransaction(transaction: Transaction): Promise<void>;
  removeTransaction(invoiceNumber: string, id: Transaction['id']): Promise<void>;

  listJobs(...filters: JobFilter[]): Promise<Job[]>;
  watchJob(fileNumber: FileNumber, cb: WatchOneCallback<Job>): Unsubscriber;
  addOrUpdateJob(job: Job): Promise<void>;
  updateJobTags(fileNumber: FileNumber, ...newTags: string[]): Promise<void>;
  suggestNextFileNumber(): Promise<string>;

  listAssignments(filters: AssignmentFilter[]): Promise<Assignment[]>;
  watchAssignments(filters: AssignmentFilter[], cb: WatchCollectionCallback<Assignment>): Unsubscriber;
  addOrUpdateAssignment(assignment: Assignment): Promise<void>;
  removeAssignment(fileNumber: FileNumber, initials: AppraiserInitials): Promise<void>;
}

export function generateId(prefix: string): string {
  return `${prefix}_${nanoid()}`;
}

export const FileNumberFilter = z.object({
  type: z.literal("fileNumber"),
  fileNumbers: z.array(z.string()),
});
export type FileNumberFilter = z.infer<typeof FileNumberFilter>;

export const FileNumberPrefixFilter = z.object({
  type: z.literal("fileNumberPrefix"),
  prefix: z.string(),
});
export type FileNumberPrefixFilter = z.infer<typeof FileNumberPrefixFilter>;

export const ProjectFilter = z.object({
  type: z.literal("project"),
  projectPrefix: z.string(),
});
export type ProjectFilter = z.infer<typeof ProjectFilter>;

export const UNASSIGNED = "Unassigned";

export const AppraiserInitialsFilter = z.object({
  type: z.literal("appraiserInitials"),
  initials: z.union([z.literal(UNASSIGNED), z.array(AppraiserInitials)]),
});
export type AppraiserInitialsFilter = z.infer<typeof AppraiserInitialsFilter>;

export const CreatedAfterFilter = z.object({
  type: z.literal("createdAfter"),
  startDate: z.coerce.date(),
});
export type CreatedAfterFilter = z.infer<typeof CreatedAfterFilter>;

export const CreatedBeforeFilter = z.object({
  type: z.literal("createdBefore"),
  endDate: z.coerce.date(),
});
export type CreatedBeforeFilter = z.infer<typeof CreatedBeforeFilter>;

export const JobStatusFilter = z.object({
  type: z.literal("status"),
  statuses: z.array(JobStatus),
});
export type JobStatusFilter = z.infer<typeof JobStatusFilter>;

export const ClientNameFilter = z.object({
  type: z.literal("clientName"),
  clientName: z.string(),
});
export type ClientNameFilter = z.infer<typeof ClientNameFilter>;

export const ClientNamePrefixFilter = z.object({
  type: z.literal("clientNamePrefix"),
  clientNamePrefix: z.string(),
});
export type ClientNamePrefixFilter = z.infer<typeof ClientNamePrefixFilter>;

export const ClientFilter = z.discriminatedUnion("type", [ClientNameFilter, ClientNamePrefixFilter]);
export type ClientFilter = z.infer<typeof ClientFilter>;

export const TagFilter = z.object({
  type: z.literal("tags"),
  tags: z.array(z.string()),
});
export type TagFilter = z.infer<typeof TagFilter>;

export const IsPaidFilter = z.object({
  type: z.literal("isPaid"),
  isPaid: z.boolean(),
});
export type IsPaidFilter = z.infer<typeof IsPaidFilter>;

export const JobFilter = z.discriminatedUnion("type", [FileNumberFilter, FileNumberPrefixFilter, ProjectFilter, AppraiserInitialsFilter, CreatedAfterFilter, CreatedBeforeFilter, JobStatusFilter, TagFilter, ClientNameFilter, ClientNamePrefixFilter, IsPaidFilter]);
export type JobFilter = z.infer<typeof JobFilter>;

export const PayoutIDFilter = z.object({
  type: z.literal("payoutId"),
  id: z.union([z.null(), z.string()]),
});
export type PayoutIDFilter = z.infer<typeof PayoutIDFilter>;

export const PaidAfterFilter = z.object({
  type: z.literal("paidAfter"),
  startDate: z.coerce.date(),
});
export type PaidAfterFilter = z.infer<typeof PaidAfterFilter>;

export const PayoutFilter = z.discriminatedUnion("type", [AppraiserInitialsFilter, PaidAfterFilter, PayoutIDFilter, IsPaidFilter]);
export type PayoutFilter = z.infer<typeof PayoutFilter>;

export const InvoiceNumberFilter = z.object({
  type: z.literal("invoiceNumbers"),
  invoiceNumbers: z.array(z.string()).min(1),
});

export const InvoiceStatusFilter = z.object({
  type: z.literal("invoiceStatus"),
  statuses: z.array(InvoiceStatus),
});
export type InvoiceStatusFilter = z.infer<typeof InvoiceStatusFilter>;

export const InvoiceFilter = z.discriminatedUnion("type", [ClientNameFilter, ClientNamePrefixFilter, InvoiceNumberFilter, InvoiceStatusFilter, CreatedAfterFilter]);
export type InvoiceFilter = z.infer<typeof InvoiceFilter>;

export const AppraiserIsActiveFilter = z.object({
  type: z.literal("appraiserIsActive"),
  isActive: z.boolean(),
});
export type AppraiserIsActiveFilter = z.infer<typeof AppraiserIsActiveFilter>;

export const AppraiserFilter = z.discriminatedUnion("type", [AppraiserInitialsFilter, AppraiserIsActiveFilter]);
export type AppraiserFilter = z.infer<typeof AppraiserFilter>;

export const AssignmentFilter = z.discriminatedUnion("type", [AppraiserInitialsFilter, FileNumberFilter, IsPaidFilter, PayoutIDFilter, JobStatusFilter]);
export type AssignmentFilter = z.infer<typeof AssignmentFilter>;
