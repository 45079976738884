import wrapPromise from "@/util/wrapPromise";
import { Err, Result } from "ts-results-es";

const templateFileName = "invoice.template.docx";

export async function saveInvoiceTemplate(template: Blob) {
  const dir = await navigator.storage.getDirectory();
  const f = await dir.getFileHandle(templateFileName, { create: true });
  const w = await f.createWritable({ keepExistingData: false });
  await w.write(template);
  await w.close();
}

export async function getInvoiceTemplate(): Promise<Result<Blob, unknown>> {
  const dir = await navigator.storage.getDirectory();
  const fileRes = await wrapPromise(dir.getFileHandle(templateFileName, { create: false }));
  if (fileRes.isErr()) {
    return Err(fileRes.error);
  }

  return await wrapPromise(fileRes.value.getFile());
}
