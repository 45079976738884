import { StoreContext, useActiveAppraisers, useStoreValue } from "@/hooks/useStore";
import { Alert, Autocomplete, Box, Button, CircularProgress, Dialog, DialogTitle, FormControlLabel, MenuItem, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { useLocation } from "wouter";
import { formatDate } from "./helpers/DateDisplay";
import { AppraiserInitials, Payout } from "@/model";
import { PayoutFilter } from "@/store/store";
import PayoutStatusChip from "./helpers/PayoutStatusChip";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { errorToString } from "@/format/errors";
import formatDollarsCents from "@/format/formatDollarsCents";
import { LocalSaver } from "@/util/localSaver";
import { z } from "zod";
import { Ok, Result } from "ts-results-es";
import useDialog from "@/hooks/useDialog";
import wrapPromise from "@/util/wrapPromise";
import { nanoid } from "nanoid";
import { useSingleFieldSorting, useSorting } from "./helpers/sorting";

export enum PayoutStatusOption {
  PENDING = "Pending",
  CLOSED = "Paid/Closed",
}

export const PayoutFilterSet = z.object({
  status: z.nativeEnum(PayoutStatusOption).optional(),
  startDate: z.coerce.date().optional(),
  initials: z.string().optional(),
});
export type PayoutFilterSet = z.infer<typeof PayoutFilterSet>;

interface Props {
  defaultFilters?: PayoutFilterSet;
}

export default function PayoutList(props: Props) {
  const filterSaver = new LocalSaver("payout-filters", PayoutFilterSet);

  const [filterOptions, setFilterOptions] = useState<PayoutFilterSet>(() => {
    if (history.state?.filterOptions) {
      return history.state.filterOptions;
    }
    return filterSaver.load(props.defaultFilters ?? {});
  });

  const payoutsResult = useStoreValue(async s => {
    const filters: PayoutFilter[] = [];
    if (filterOptions.status) {
      filters.push(statusOptionToFilter(filterOptions.status));
    }
    if (filterOptions.startDate) {
      filters.push({ type: 'paidAfter', startDate: filterOptions.startDate });
    }
    if (filterOptions.initials) {
      filters.push({ type: 'appraiserInitials', initials: [filterOptions.initials] });
    }

    const ds = await s.listPayouts(1000, ...filters);
    return ds.sort((a, b) => {
      return (b.paidOn?.getTime() ?? Number.MAX_SAFE_INTEGER) - (a.paidOn?.getTime() ?? Number.MAX_SAFE_INTEGER);
    });
  }, [filterOptions]);

  return <Stack direction="column" spacing={2}>
    <Typography variant="h5">Payouts</Typography>
    <Stack direction="row" className="hidden-print" alignItems="center">
      <FilterBar initialFilters={filterOptions} onChange={fs => {
        setFilterOptions(fs);
        filterSaver.save(fs);
      }} />
      <Box>
        <NewPayoutButton />
      </Box>
    </Stack>
    <PayoutTable payoutsResult={payoutsResult} />
  </Stack>;
}

function PayoutTable({ payoutsResult }: { payoutsResult: Result<Payout[], any> | undefined }) {
  const [_, setLocation] = useLocation();
  const [sortField, direction, SortingLabel] = useSingleFieldSorting<keyof Payout>("paidOn", "desc");
  const payouts = useSorting(payoutsResult?.unwrapOr(undefined), sortField, direction);

  if (!payoutsResult) {
    return <CircularProgress />;
  }

  if (payoutsResult.isErr()) {
    return <Alert severity="error">Failed to fetch appraiser pay: {errorToString(payoutsResult.error)}</Alert>;
  }

  return <TableContainer component={Paper}>
    <Table stickyHeader size="small">
      <TableHead>
        <TableRow>
          <TableCell><SortingLabel field="initials">Appraiser</SortingLabel></TableCell>
          <TableCell align="right"><SortingLabel field="paidOn">Paid Out On</SortingLabel></TableCell>
          <TableCell align="right"><SortingLabel field="paidDollars">$ Paid</SortingLabel></TableCell>
          <TableCell align="right">Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {payouts.length === 0 ?
          <TableRow><TableCell>No payments matching filters</TableCell></TableRow> :
          payouts.map(d => {
            return <TableRow
              hover
              sx={{ cursor: 'pointer' }}
              key={d.id}
              onClick={() => setLocation(`/payroll/${d.id}`)}
            >
              <TableCell><Typography fontWeight="bold">{d.initials}</Typography></TableCell>
              <TableCell align="right"><Typography>{d.paidOn ? formatDate(d.paidOn, true) : "Unpaid"}</Typography></TableCell>
              <TableCell align="right"><Typography>{!d.earnedDollars ? "Unknown" : formatDollarsCents(d.earnedDollars)}</Typography></TableCell>
              <TableCell>
                <Stack direction="row" spacing={1} justifyContent="right">
                  <PayoutStatusChip payout={d} />
                </Stack>
              </TableCell>
            </TableRow>;
          })}
      </TableBody>
    </Table>
  </TableContainer>;
}

function statusOptionToFilter(o: PayoutStatusOption): PayoutFilter {
  switch (o) {
    case PayoutStatusOption.PENDING:
      return { type: 'isPaid', isPaid: false };
    case PayoutStatusOption.CLOSED:
      return { type: 'isPaid', isPaid: true };
  }
}

interface FilterBarProps {
  onChange: (filters: PayoutFilterSet) => void;
  initialFilters: PayoutFilterSet;
}

function FilterBar({ onChange, initialFilters }: FilterBarProps) {
  const [pendingFilters, setPendingFilters] = useState(initialFilters);
  const appraisers = useActiveAppraisers();

  const isChanged = useMemo(() => {
    return JSON.stringify(initialFilters) !== JSON.stringify(pendingFilters);
  }, [initialFilters, pendingFilters]);

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap" flexGrow={1}>
      {/* Status filter */}
      <TextField
        sx={{ minWidth: '10em' }}
        select
        value={pendingFilters.status ?? ""}
        onChange={e => {
          setPendingFilters({ ...pendingFilters, status: e.target.value ? e.target.value as PayoutStatusOption : undefined });
        }}
        label="Status"
      >
        <MenuItem value="">
          <em>Any</em>
        </MenuItem>
        {Object.values(PayoutStatusOption).map((v) => <MenuItem key={v} value={v}><Typography fontWeight="bold">{v}</Typography></MenuItem>)}
      </TextField>

      {/* Since date filter */}
      <DatePicker
        label="Paid On or After"
        onChange={v => setPendingFilters({ ...pendingFilters, startDate: v?.toDate() })}
        value={pendingFilters.startDate ? dayjs(pendingFilters.startDate) : null}
        slotProps={{
          field: { clearable: true },
        }}
      />

      {/* Appraiser filter */}
      <TextField
        sx={{ minWidth: '10em' }}
        select
        value={(appraisers && pendingFilters.initials) ? pendingFilters.initials : ""}
        onChange={e => {
          setPendingFilters({ ...pendingFilters, initials: e.target.value });
        }}
        label="Appraiser"
      >
        <MenuItem value="">
          <em>Any</em>
        </MenuItem>
        {appraisers ? [...appraisers.values()].map((a) => <MenuItem key={a.initials} value={a.initials}>{a.initials} ({a.name})</MenuItem>) : null}
      </TextField>

      {isChanged ? <Button variant="outlined" onClick={() => { onChange(pendingFilters) }}>Apply Filters</Button> : null}
    </Stack>
  </LocalizationProvider>;
}

function NewPayoutButton() {
  const store = useContext(StoreContext);
  const [, setLocation] = useLocation();

  const { showDialog, props: dialogProps } = useDialog<Payout>(async payout => {
    const existingRes = await wrapPromise(store.listPayouts(1,
      { type: "appraiserInitials", initials: [payout.initials] },
      { type: "isPaid", isPaid: false }));
    if (existingRes.isErr()) {
      return existingRes;
    }
    const existing = existingRes.value;
    if (existing.length > 0) {
      payout = existing[0]!;
    } else {
      const res = await wrapPromise(store.addOrUpdatePayout(payout));
      if (res.isErr()) {
        return res;
      }
    }
    const updateRes = await wrapPromise(store.updatePayoutWithPaidJobs(payout));
    if (updateRes.isErr()) {
      return updateRes;
    }
    setLocation(`/payroll/${payout.id}`);
    return Ok(null);
  }, [store]);

  const activeApprisers = useActiveAppraisers();
  const [currentAppraiser, setCurrentAppraiser] = useState<AppraiserInitials | null>(null);
  const [includeDelivered, setIncludeDelivered] = useState(false);

  return <>
    <Button onClick={() => { showDialog() }} variant="contained">New Payout</Button>
    <Dialog onClose={() => { dialogProps.onClose(); }} open={dialogProps.open}>
      <DialogTitle>Select Appraiser for Payout</DialogTitle>
      <Stack direction="column" spacing={2} sx={{ p: 2 }}>
        <Autocomplete
          value={currentAppraiser}
          onChange={(_, v) => setCurrentAppraiser(v)}
          loading={activeApprisers === undefined}
          filterOptions={(options) => options}
          selectOnFocus
          disabled={!!dialogProps.saving}
          autoHighlight
          clearOnBlur
          includeInputInList
          handleHomeEndKeys
          options={activeApprisers ? [...activeApprisers.values()].map(a => a.initials) : []}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              error={!!dialogProps.error}
              helperText={dialogProps.error}
              label="Appraiser Initials" />
          )} />
        <FormControlLabel control={<Switch onChange={e => { setIncludeDelivered(e.target.checked) }} />} label="Include unpaid but delivered jobs" />
        <Button variant="contained" onClick={() => {
          if (currentAppraiser) {
            dialogProps.onSave({
              id: `${currentAppraiser}_${nanoid()}`,
              createdOn: new Date(),
              initials: currentAppraiser,
              includeDelivered,
            });
          }
        }}>Create Payout
        </Button>
      </Stack>
    </Dialog>
  </>
}
