import { Job, Appraiser, AppraiserInitials, Client, ClientID, Payout, Fee, FileNumber, Invoice, InvoiceNumber, Transaction } from "@/model"
import { Store } from "@/store/store";
import { z } from "zod";
import { parse } from "papaparse";
import { validateTagSet } from "./tags";
import { Err, Ok, Result } from "ts-results-es";

const EasyTracInvoice = z.object({
  INVOICE: z.string().optional(),
  CUST_ID: z.string().optional(),
  INVOICED: z.date().optional(),
  PAID: z.date().optional(),
  QTY: z.number().optional(),
  AMOUNT: z.number().optional(),
  PAYMENTS: z.number().optional(),
  CREDITS: z.number().optional(),
  CLOSED: z.boolean().optional(),
  BALANCE: z.number().optional(),
});
type EasyTracInvoice = z.infer<typeof EasyTracInvoice>;

const EasyTracAppraisal = z.object({
  APSL_ID: z.string().optional(),
  APR_ID: z.string().optional(),
  CUST_ID: z.string().optional(),
  CUST_REF: z.string().optional(),
  STATUS: z.string().optional(),
  INVOICE: z.string().optional(),
  REV_APR_ID: z.string().optional(),
  HOUSE_NUM: z.string().optional(),
  ADDRESS1: z.string().optional(),
  CITY: z.string().optional(),
  STATE: z.string().optional(),
  ZIP: z.string().optional(),
  COUNTY: z.string().optional(),
  CONTACT: z.string().optional(),
  APP1_FIRST: z.string().optional(),
  APP1_LAST: z.string().optional(),
  APP1_HPHON: z.string().optional(),
  APP1_WPHON: z.string().optional(),
  APP2_FIRST: z.string().optional(),
  APP2_LAST: z.string().optional(),
  APP2_WPHON: z.string().optional(),
  TYPE: z.string().optional(),
  FF_TYPE: z.string().optional(),
  STYLE: z.string().optional(),
  COMM_PCT: z.number().optional(),
  REV_PCT: z.number().optional(),
  FEE: z.number().optional(),
  OTHER_FEE: z.number().optional(),
  PAYMENTS: z.number().optional(),
  CREDITS: z.number().optional(),
  BALANCE: z.number().optional(),
  COMM: z.number().optional(),
  REV_COMM: z.number().optional(),
  RECEIVED: z.date().optional(),
  ASSIGNED: z.date().optional(),
  CONTACTED: z.date().optional(),
  INSPECTED: z.date().optional(),
  REVIEWED: z.date().optional(),
  DELIVERED: z.date().optional(),
  INVOICED: z.date().optional(),
  PAID: z.date().optional(),
  APR_PAID: z.date().optional(),
  ON_HOLD: z.date().optional(),
  OFF_HOLD: z.date().optional(),
  DUE: z.date().optional(),
  HOURS: z.number().optional(),
  EST_VAL: z.number().optional(),
  SALES_DAT: z.date().optional(),
  SALES_PRC: z.number().optional(),
  TAXES: z.number().optional(),
  LOAN_AMT: z.number().optional(),
  LOT_DIM: z.string().optional(),
  LEGAL_DESC: z.string().optional(),
  COMMENT: z.string().optional(),
  CUSTM_SRCH: z.boolean().optional(),
  FIRST_TIME: z.boolean().optional(),
  MAPREF: z.string().optional(),
  CENSUS: z.string().optional(),
});
type EasyTracAppraisal = z.infer<typeof EasyTracAppraisal>;

const EasyTracCustomer = z.object({
  CUST_ID: z.string().optional(),
  NAME: z.string().optional(),
  ADDRESS1: z.string().optional(),
  ADDRESS2: z.string().optional(),
  CITY: z.string().optional(),
  STATE: z.string().optional(),
  ZIP: z.string().optional(),
  CONTACT: z.string().optional(),
  TITLE: z.string().optional(),
  PHONE: z.string().optional(),
  FAX: z.string().optional(),
  TERMS: z.string().optional(),
  BILLTYPE: z.string().optional(),
  MLINE_INV: z.boolean().optional(),
  BAL_FWD: z.number().optional(),
  MTDBILLED: z.number().optional(),
  MTDPAID: z.number().optional(),
  MTDCREDIT: z.number().optional(),
  BALANCE: z.number().optional(),
  MTDAVGDAYS: z.number().optional(),
  YTDBILLED: z.number().optional(),
  YTDPAID: z.number().optional(),
  YTDCREDIT: z.number().optional(),
  YTDAVGDAYS: z.number().optional(),
})
type EasyTracCustomer = z.infer<typeof EasyTracCustomer>;

const EasyTracAppraiser = z.object({
  APR_ID: z.string().optional(),
  FIRST: z.string().optional(),
  LAST: z.string().optional(),
  LICENSE: z.string().optional(),
  HIRED: z.date().optional(),
  COMMISSN: z.number().optional(),
  SSN: z.string().optional(),
  DOB: z.date().optional(),
  SPOUSE: z.string().optional(),
  ADDRESS1: z.string().optional(),
  ADDRESS2: z.string().optional(),
  CITY: z.string().optional(),
  STATE: z.string().optional(),
  ZIP: z.string().optional(),
  HOMEPHONE: z.string().optional(),
  WORKPHONE: z.string().optional(),
  OTHERPHONE: z.string().optional(),
  PAY_OPTION: z.boolean().optional(),
  REV_COMM: z.number().optional(),
  YTDPAID: z.number().optional(),
  YTDBILLED: z.number().optional(),
  YTDAVGDAYS: z.number().optional(),
  MTDPAID: z.number().optional(),
  MTDBILLED: z.number().optional(),
  MTDAVGDAYS: z.number().optional(),
  QUOTA: z.number().optional(),
  CURWRKLOAD: z.number().optional(),
  MAXWRKLOAD: z.number().optional(),
});
type EasyTracAppraiser = z.infer<typeof EasyTracAppraiser>;

const EasyTracARHistory = z.object({
  CUST_ID: ClientID.optional(),
  INVOICE: z.string().optional(),
  CLOSED: z.preprocess(v => v === "Y", z.boolean().optional()),
  ARTYPE: z.string().optional(),
  ARCODE: z.string().optional(),
  REFERENCE: z.string().optional(),
  TRN_DATE: z.date().optional(),
  TRN_AMOUNT: z.number().optional(),
});
type EasyTracARHistory = z.infer<typeof EasyTracARHistory>;

const EasyTracAppraiserPay = z.object({
  APR_ID: z.string().optional(),
  APR_PAID: z.date().optional(),
  REG_PAY: z.number().optional(),
  REV_PAY: z.number().optional(),
  BILLED: z.number().optional(),
  PAID: z.number().optional(),
});
type EasyTracAppraiserPay = z.infer<typeof EasyTracAppraiserPay>;

const EasyTracTypeListItem = z.object({
  LISTNO: z.string().optional(),
  INDEX: z.string().optional(),
  DESC: z.string().optional(),
  DRIVE: z.string().optional(),
  PATH: z.string().optional(),
  FORMEXE: z.string().optional(),
  FORMOPTN: z.string().optional(),
  PRINTEXE: z.string().optional(),
  PRINTOPTN: z.string().optional(),
});
type EasyTracTypeListItem = z.infer<typeof EasyTracTypeListItem>;

/*function fixDate(d?: Date) {
  if (d && d.getFullYear() < 1970) {
    return new Date(d.getUTCFullYear() + 100, d.getMonth(), d.getDate());
  }
  return d;
}
*/
const ImportFileNames = ["INVOICE", "APPRSL", "APPRSR", "ARHIST", "CUSTMR", "APRTYPE", "PAYHIST"] as const;
type ImportFileName = typeof ImportFileNames[number];
type ImportFiles = { [K in ImportFileName]: File };

const dueDateRegex = /^\s*(?<month>\d\d?)\/(?<day>\d\d?)\s*$/i;

export function identifyEasyTracFiles(l: FileList): Result<ImportFiles, unknown> {
  const out: Partial<ImportFiles> = {};
  for (let i = 0; i < l.length; i++) {
    const f = l.item(i);
    if (!f) continue;

    const baseName = f.name.toUpperCase().replace(".CSV", "") as ImportFileName;
    if (ImportFileNames.includes(baseName)) {
      out[baseName] = f;
    }
  }
  if (Object.keys(out).length === ImportFileNames.length) {
    return Ok(out as ImportFiles);
  }
  return Err(`Failed to find all files, found: ${Object.keys(out).join(", ")}`);
}

export class EasyTracImporter {
  readonly progress = new Map<string, number>();

  constructor(private files: ImportFiles, private store: Store) { }

  private async *parseCSV<T extends z.ZodObject<z.ZodRawShape>>(name: ImportFileName, rowModel: T) {
    try {
      for (const r of await parseCSVWithCallback<T>(this.files[name], rowModel)) {
        yield r;
      }
    } catch (e) {
      console.error(`Failed parsing ${name}`, e);
      throw e;
    }
  }

  async import(startYear?: number) {
    await this.importAppraisers();
    const typeMap = await this.importTypeMap();
    const allAppraisals = await parseCSVWithCallback(this.files["APPRSL"], EasyTracAppraisal);
    const [appraisalClients, invoiceNumbers] = await this.importAppraisals(allAppraisals, startYear ?? 1990, typeMap);
    await this.importInvoices(invoiceNumbers);
    await this.importTransactions(invoiceNumbers);
    await this.importClients(appraisalClients);
    await this.importAppraiserPay(allAppraisals, startYear ?? 1990);
  }

  private async importTypeMap(): Promise<AppraisalTypeMap> {
    const m: AppraisalTypeMap = new Map();
    for await (const i of this.parseCSV("APRTYPE", EasyTracTypeListItem)) {
      if (i.LISTNO && i.INDEX && i.DESC) {
        m.set([i.LISTNO, i.INDEX], i.DESC);
      }
    }
    return m;
  }

  private async importAppraisers() {
    let n = 0;
    for await (const a of this.parseCSV("APPRSR", EasyTracAppraiser)) {
      if (!a.APR_ID || a.APR_ID.length < 2) continue;

      await this.store.addOrUpdateAppraiser(Appraiser.strict().parse({
        initials: a.APR_ID,
        name: `${a.FIRST} ${a.LAST ?? ""}`.trim(),
        commissionPercent: a.COMMISSN,
        hireDate: a.HIRED,
        licenseNumber: a.LICENSE,
      }));
      n++
      this.progress.set("Appraisers", n);
    }
  }

  private async importInvoices(invoiceNumbers: Set<InvoiceNumber>) {
    let n = 0;
    for await (const i of this.parseCSV("INVOICE", EasyTracInvoice)) {
      if (!i.INVOICE || !invoiceNumbers.has(i.INVOICE)) continue;
      if (!i.CUST_ID) continue;

      await this.store.addOrUpdateInvoice(Invoice.strict().parse({
        invoiceNumber: i.INVOICE,
        createdOn: i.INVOICED,
        deliveredOn: i.INVOICED,
        billToId: fixCustomerID(i.CUST_ID),
      }));

      n++
      this.progress.set("Invoices", n);
    }

  }

  private async importTransactions(invoiceNumbers: Set<InvoiceNumber>) {
    const invoiceClosedDate = new Map<InvoiceNumber, Date>();

    let n = 0;
    for await (const a of this.parseCSV("ARHIST", EasyTracARHistory)) {
      if (!a.INVOICE) continue;
      if (!invoiceNumbers.has(a.INVOICE)) continue;
      if (a.ARTYPE !== "P" && a.ARTYPE !== "C") continue;
      if (!a.TRN_AMOUNT) continue;

      await this.store.addOrUpdateTransaction(Transaction.strict().parse({
        id: `${a.TRN_DATE ? a.TRN_DATE.toISOString() : 'unknown'}_${a.ARTYPE}${a.TRN_AMOUNT ?? 0}_import`,
        invoiceNumber: a.INVOICE,
        amountDollars: a.TRN_AMOUNT, //a.ARTYPE == "P" ? a.TRN_AMOUNT : -a.TRN_AMOUNT,
        appliedOn: a.TRN_DATE,
        description: a.REFERENCE,
      }));

      if (a.CLOSED && a.TRN_DATE) {
        // These are iterated in date order so just let the last one be the final value in the map.
        invoiceClosedDate.set(a.INVOICE, a.TRN_DATE)
      }

      n++;
      this.progress.set("Transactions", n);
    }

    for (const [invoiceNumber, closedOn] of [...invoiceClosedDate.entries()]) {
      await this.store.addOrUpdateInvoice({
        invoiceNumber,
        closedOn,
      });
    }
  }

  private async importAppraiserPay(allAppraisals: EasyTracAppraisal[], startYear: number) {
    let n = 0;
    const appraisalsByAppraiserPayDate = new Map<string, EasyTracAppraisal[]>();
    for (const a of allAppraisals) {
      if (!a.APR_PAID) continue;
      if (a.PAYMENTS === undefined) continue;

      const key = a.APR_PAID.toDateString();
      appraisalsByAppraiserPayDate.set(key, [...(appraisalsByAppraiserPayDate.get(key) ?? []), a]);
    }

    const groupedByDay = new Map<string, EasyTracAppraiserPay>();
    for await (const p of this.parseCSV("PAYHIST", EasyTracAppraiserPay)) {
      if (!p.APR_ID) continue;
      if (!p.APR_PAID || p.APR_PAID.getFullYear() < startYear) continue;

      const key = `${p.APR_ID}_${p.APR_PAID.toDateString()}`;
      const existing = groupedByDay.get(key);
      let newPay = p;
      if (existing) {
        newPay.REV_PAY = (newPay.REV_PAY ?? 0) + (existing.REV_PAY ?? 0);
        newPay.REG_PAY = (newPay.REG_PAY ?? 0) + (existing.REG_PAY ?? 0);
        newPay.BILLED = (newPay.BILLED ?? 0) + (existing.BILLED ?? 0);
        newPay.PAID = (newPay.PAID ?? 0) + (existing.PAID ?? 0);
      }
      groupedByDay.set(key, newPay);
    }

    for await (const p of [...groupedByDay.values()]) {
      if (!p.APR_ID) continue;
      if (!p.APR_PAID || p.APR_PAID.getFullYear() < startYear) continue;

      const paidDollars = (p.REG_PAY ?? 0) + (p.REV_PAY ?? 0);
      const mainAppraisals = appraisalsByAppraiserPayDate.get(p.APR_PAID.toDateString())?.filter(a =>
        a.APR_ID?.trim() === p.APR_ID?.trim()
      ) ?? [];
      const reviewAppraisals = appraisalsByAppraiserPayDate.get(p.APR_PAID.toDateString())?.filter(a =>
        a.REV_APR_ID === p.APR_ID
      ) ?? [];

      const regularCommission = mainAppraisals.reduce((acc, a) => acc + (a.COMM ?? 0), 0) ?? 0;
      const reviewCommission = reviewAppraisals.reduce((acc, a) => acc + (a.REV_COMM ?? 0), 0) ?? 0;

      if (Math.floor(regularCommission) !== Math.floor(p.REG_PAY ?? 0)) {
        console.error(`Regular pay for ${p.APR_ID} on ${p.APR_PAID.toDateString()} is mismatched: ${regularCommission} !== ${p.REG_PAY ?? 0}; ${JSON.stringify(mainAppraisals)}`);
        continue;
      }
      if (Math.floor(reviewCommission) !== Math.floor(p.REV_PAY ?? 0)) {
        console.error(`Review pay for ${p.APR_ID} on ${p.APR_PAID.toDateString()} is mismatched: ${reviewCommission} !== ${p.REV_PAY ?? 0}`);
        continue;
      }

      await this.store.addOrUpdatePayout(Payout.strict().parse({
        id: `${p.APR_ID}_${p.APR_PAID?.toISOString() ?? "unknown"}_${p.BILLED ?? 0}_import`,
        initials: p.APR_ID,
        billedDollars: p.BILLED,
        paidDollars,
        paidOn: p.APR_PAID,
        createdOn: p.APR_PAID,
        description: "Imported from EasyTrac",
        associatedFileNumbers: [
          ...mainAppraisals.map(a => fixFileNumber(a.APSL_ID!)),
          ...reviewAppraisals.map(a => fixFileNumber(a.APSL_ID!)),
        ],
      }));

      n++;
      this.progress.set("Payouts", n);
    }
  }

  private determineProjects(allAppraisals: EasyTracAppraisal[], startYear: number): Map<FileNumber, string> {
    const baseRE = /^\d+$/;
    const out = new Map<FileNumber, string>();
    const baseNames = new Map<number, string>();

    for (const a of allAppraisals) {
      if (!a.APSL_ID) continue;
      if (!a.RECEIVED) continue;
      if (a.RECEIVED.getFullYear() < startYear && (a.APR_PAID?.getFullYear() ?? 0) < startYear) continue;

      if (baseRE.test(a.APSL_ID)) {
        const title = `${a.HOUSE_NUM ?? ""}${a.ADDRESS1 ?? ""}`.trim();
        baseNames.set(parseInt(a.APSL_ID), title);
      } else {
        const base = parseInt(a.APSL_ID);
        // parseInt will strip off any non-number suffix before converting to an int.
        const group = baseNames.get(base);
        if (group) {
          out.set(a.APSL_ID, group);
          out.set(base.toString(), group);
        }
      }
    }

    return out;
  }

  private async importAppraisals(allAppraisals: EasyTracAppraisal[], startYear: number, typeMap: AppraisalTypeMap): Promise<[Set<ClientID>, Set<InvoiceNumber>]> {
    const invoicesSeen = new Set<InvoiceNumber>();
    const clientsSeen = new Set<ClientID>();
    const activeAppraisers = new Set<AppraiserInitials>();
    const projectsByFilenumber = this.determineProjects(allAppraisals, startYear);

    let n = 0;
    for (const a of allAppraisals) {
      if (!a.APSL_ID) continue;
      if (!a.RECEIVED) continue;
      if (a.RECEIVED.getFullYear() < startYear && (a.APR_PAID?.getFullYear() ?? 0) < startYear) continue;

      if (a.APR_ID) {
        activeAppraisers.add(a.APR_ID);
      }
      const customerID = a.CUST_ID ? fixCustomerID(a.CUST_ID) : undefined;
      if (customerID) {
        clientsSeen.add(customerID);
      }

      let closedOn: Date | undefined = undefined;
      if (a.STATUS == "9" || a.PAID) {
        closedOn = a.PAID ??
          a.DELIVERED ??
          a.ASSIGNED ??
          a.ON_HOLD ??
          a.RECEIVED;
      }

      let fee: Fee | undefined;
      if (a.FEE === 1) {
        fee = {
          "model": "hourly",
        }
      } else if (a.FEE) {
        fee = {
          "model": "fixed",
          "priceDollars": a.FEE,
        }
      }

      const fileNumber = fixFileNumber(a.APSL_ID);

      if (a.APR_ID) {
        await this.store.addOrUpdateAssignment({
          initials: a.APR_ID,
          fileNumber,
          commissionPercent: a.COMM_PCT,
          assignedOn: a.ASSIGNED,
          isReviewer: false,
        });
      }

      if (a.REV_APR_ID && a.REV_APR_ID.length > 2) {
        await this.store.addOrUpdateAssignment({
          initials: a.REV_APR_ID,
          fileNumber,
          commissionPercent: a.REV_PCT,
          assignedOn: a.ASSIGNED,
          isReviewer: true,
        });
      }

      const commentLines: string[] = [];
      if (a.LOT_DIM || a.LEGAL_DESC) {
        commentLines.push(`${a.LOT_DIM ?? ""} ${a.LEGAL_DESC ?? ""}`.trim());
      }
      if (a.APP1_FIRST || a.APP1_LAST || a.APP1_HPHON || a.APP1_WPHON) {
        commentLines.push(`${a.APP1_FIRST ?? ""} ${a.APP1_LAST ?? ""} ${a.APP1_HPHON ?? ""} ${a.APP1_WPHON ?? ""}`.trim());
      }
      if (a.APP2_FIRST || a.APP2_LAST || a.APP2_WPHON) {
        commentLines.push(`${a.APP2_FIRST ?? ""} ${a.APP2_LAST ?? ""} ${a.APP2_WPHON ?? ""}`.trim());
      }

      const tags = tagsForAppraisal(a, typeMap);

      let streetAddress = `${a.HOUSE_NUM ?? ""}${Number.isInteger(a.HOUSE_NUM) ? " " : ""}${a.ADDRESS1 ?? ""}`.trim()
      if (a.HOUSE_NUM?.toLowerCase().includes("verbal")) {
        tags.add("VERBAL");
        streetAddress = a.ADDRESS1 ?? "";
      }
      let onHoldOn = a.ON_HOLD;
      if (a.HOUSE_NUM?.toUpperCase().includes("HOLD")) {
        streetAddress = a.ADDRESS1 ?? "";
        if (!onHoldOn) {
          onHoldOn = a.ASSIGNED ?? a.RECEIVED;
        }
      }
      if (a.HOUSE_NUM?.toUpperCase().includes("BV")) {
        tags.add("BV")
      }

      let dueDate = a.DUE;
      if (!dueDate) {
        const match = a.HOUSE_NUM?.match(dueDateRegex);
        if (match && match.groups) {
          const [month, day] = [
            Number.parseInt(match.groups['month'] ?? ""),
            Number.parseInt(match.groups['day'] ?? "")
          ];
          const d = new Date(
            month >= a.RECEIVED.getMonth() ?
              a.RECEIVED.getFullYear() :
              a.RECEIVED.getFullYear() + 1,
            month - 1,
            day,
          );
          if (Number.isNaN(d.getTime())) {
            console.error(`Failed to process bad due date: ${a.HOUSE_NUM}`);
          } else {
            dueDate = d;
          }
          streetAddress = a.ADDRESS1 ?? "";
        }
      }

      if (a.INVOICE) {
        invoicesSeen.add(a.INVOICE);
      }

      const title = `${a.HOUSE_NUM ?? ""} ${a.ADDRESS1 ?? ""}`.trim();

      const project = projectsByFilenumber.get(fileNumber);

      await this.store.addOrUpdateJob(Job.strict().parse({
        fileNumber,
        title,
        clientId: customerID,
        fee,
        streetAddress,
        project,
        comments: commentLines.join("\n\n"),
        city: a.CITY,
        state: a.STATE,
        zipcode: a.ZIP,
        county: a.COUNTY,
        tags: [...tags.values()],
        clientRefNumber: a.CUST_REF,
        contactName: a.CONTACT,
        createdOn: a.RECEIVED,
        receivedOn: a.RECEIVED,
        contactedOn: a.CONTACTED,
        inspectedOn: a.INSPECTED,
        reviewedOn: a.REVIEWED,
        dueOn: dueDate,
        deliveredOn: a.DELIVERED,
        effectiveDate: a.INSPECTED,
        onHoldOn: correctCentury(onHoldOn),
        offHoldOn: correctCentury(a.OFF_HOLD),
        closedOn,
        invoiceNumber: a.INVOICE,
        isInvoicePaid: !!a.PAID || !!a.APR_PAID,
        paidOn: a.PAID,
        appraisedValueDollars: a.EST_VAL,
      }));

      n++;
      this.progress.set("Appraisals", n);
    }

    for (const initials of activeAppraisers.values()) {
      await this.store.addOrUpdateAppraiser({
        initials,
        isActive: true,
      });
    }

    return [clientsSeen, invoicesSeen];
  }

  private async importClients(clientIds: Set<ClientID>) {
    let n = 0;
    for await (const c of this.parseCSV("CUSTMR", EasyTracCustomer)) {
      if (!c.CUST_ID) continue;

      const id = fixCustomerID(c.CUST_ID);

      if (!clientIds.has(id)) continue;

      await this.store.addOrUpdateClient(Client.strict().parse({
        id,
        name: c.NAME ?? id ?? "",
        address1: c.ADDRESS1,
        address2: c.ADDRESS2,
        city: c.CITY,
        state: c.STATE,
        zipcode: c.ZIP,
        phone: c.PHONE,
        fax: c.FAX,
        contactName: c.CONTACT,
        title: c.TITLE,
      }));

      n++;
      this.progress.set("Clients", n);
    }
  }
}

type DBaseType = "C" | "N" | "D" | "L";

export function parseCSVWithCallback<T extends z.ZodObject<z.ZodRawShape>>(
  file: File,
  model: T,
): Promise<z.infer<T>[]> {
  const fieldTypes = new Map<string, DBaseType>();

  return new Promise<z.infer<T>[]>((resolve, reject) => {
    parse<z.infer<T>>(file, {
      header: true,
      quoteChar: '"',
      error(err, _) {
        reject(err);
      },
      complete: results => {
        try {
          resolve(results.data.map(d => model.strict().parse(d)));
        } catch (e) {
          reject(e);
        }
      },
      transformHeader(name: string, index: number) {
        const [fieldName, typ] = name.split(",");
        if (fieldName === undefined || typ === undefined || typ.length !== 1) {
          reject(new Error(`failed to parse field header ${name} at index ${index}`));
          return "";
        }
        fieldTypes.set(fieldName, typ as DBaseType);
        return fieldName;
      },
      dynamicTyping: false,
      transform(value: string, field: string) {
        const typ = fieldTypes.get(field);
        if (!typ) {
          throw new Error("field was not mapped to type: " + field);
        }
        switch (typ) {
          case "C":
            return value ? value : undefined;
          case "N":
            return value ? Number.parseFloat(value) : undefined;
          case "D":
            return value ? new Date(value) : undefined;
          case "L":
            if (!value) return undefined;
            if (value.toUpperCase() === "TRUE") return true;
            else if (value.toUpperCase() === "FALSE") return false;
            else {
              throw new Error("Unrecognized logic value: " + value);
            }
        }
      },
    });
  });
}

type AppraisalTypeMap = Map<[LISTNO: string, INDEX: string], string>;

const PropertyTypeToTag: Record<string, string> = {
  "Residential Single Family": "SFR",
  "Residential Multi Family": "MF",
  "Residential Condominium": "CONDO",
  "Residential Land": "RS",
  "Multifamily": "MF",
  "Medical Clinic": "MC",
  "Office Building": "OFF",
  "Retail Building": "RETAIL",
  "Restaurant": "REST",
  "Business": "BV",
  "Tract": "VS",
  "Industrial Building": "IP",
  "Industrial Site": "IS",
  "Commercial Site": "CSITE",
  "Commercial Building": "CP",
  "Office Site": "CSITE",
  "Group Home": "GH",
  "Convenience Store": "CS",
  "Mobile Home Park": "MHP",
  "Motel/Hotel": "HOTEL",
  "Church": "CHURCH",
  "Library": "LIB",
  "Branch Bank": "BANK",
  "Bank Home Office": "BANK",
  "Dealership": "DEALER",
  "Recreational Facility": "REC",
  "Shopping Center": "SC",
  "Subdivision": "PND",
  "Airport & Buildings": "AIR",
  "Estimate of Fair Market Rent": "MRENTR",
  "Golf Course": "GOLF",
  "Nursing Home": "NURS",
  "Rental Rate Opinion": "MRENTR",
  "Mini Warehouse": "WHSE",
  "Rest Home": "RH",
  "Funeral Home": "FH",
  "Day Care": "DC",
  "Service Garage": "GAR",
  "Plant Nursery": "PNUR",
  "Miscellaneous": "MISC",
};

const ReportTypeToTag: Record<string, string> = {
  "Restricted Report": "RESTRICT",
  "Business Report, Limited": "BV",
  "Business Report, Full": "BV",
  "Multi-Family Report": "MF",
  "Consulting Report": "CONSULT",
  "URAR SFR Report": "SFR",
};

function tagsForAppraisal(a: EasyTracAppraisal, typeMap: AppraisalTypeMap): Set<string> {
  const tags = new Set<string>();
  const jobType = a.TYPE ? typeMap.get(["1", a.TYPE]) : undefined;
  const propertyType = a.STYLE ? typeMap.get(["3", a.STYLE]) : undefined;
  const reportType = a.FF_TYPE ? typeMap.get(["2", a.FF_TYPE]) : undefined;

  if (propertyType == "Residential Single Family" &&
    reportType == "Self-Contained Report" &&
    jobType == "Standard Appraisal") {
    // In the later appraisals, these fields appear to just be left at the default and so are
    // useless.
    return tags;
  }

  if (propertyType) {
    const t = PropertyTypeToTag[propertyType];
    if (!t) {
      throw new Error("Failed to map property type: " + propertyType);
    }
    tags.add(t);
  }

  if (reportType) {
    const t = ReportTypeToTag[reportType];
    if (!t) {
      throw new Error("Failed to map report type: " + reportType);
    }
    tags.add(t);
  }

  validateTagSet(tags)
  return tags;
}

function correctCentury(d: Date | undefined): Date | undefined {
  if (!d) {
    return undefined;
  }
  if (d.getFullYear() < 1970) {
    d.setFullYear(d.getFullYear() + 100);
  }
  return d;
}

function fixFileNumber(f: string): string {
  return f.replace(/\//, '_').replace(/\\/, '1');
}

function fixCustomerID(i: string): string {
  return i.replace(/\//, '_');
}
