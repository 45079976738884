import { errorToString } from "@/format/errors";
import { Payout, PayoutID } from "@/model";
import { Alert, Button, CircularProgress, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { useStoreWatchOne } from "@/hooks/useStoreWatch";
import React, { useCallback, useContext } from "react";
import ItemWithTitle from "./helpers/ItemWithTitle";
import formatDollarsCents from "@/format/formatDollarsCents";
import { Link, useLocation } from "wouter";
import PayoutStatusChip from "./helpers/PayoutStatusChip";
import { StoreContext, useStoreValue } from "@/hooks/useStore";
import DateDisplay from "./helpers/DateDisplay";


export default function PayoutDetailByID({ id }: { id: PayoutID }) {
  const payoutResult = useStoreWatchOne(s => s.watchPayout, id);

  if (!payoutResult) {
    return <CircularProgress />;
  }

  if (payoutResult.isErr()) {
    return <Alert severity="error">Failed to load payroll {id}: {errorToString(payoutResult.error)}</Alert>
  }

  const payout = payoutResult.value;
  if (!payout) {
    return <Typography color="text.error">Payroll item {id} not found</Typography>;
  }

  return <PayoutDetail payout={payout} />;
}

export function PayoutDetail({ payout: payout }: { payout: Payout }) {
  const [, setLocation] = useLocation();

  const store = useContext(StoreContext);

  const assignmentsByFileNumberResult = useStoreValue(async store => {
    if (!payout.associatedFileNumbers || !payout.associatedFileNumbers.length) {
      return new Map();
    }

    const assigns = await store.listAssignments([
      { type: "fileNumber", fileNumbers: payout.associatedFileNumbers },
      { type: "appraiserInitials", initials: [payout.initials] },
    ]);
    return new Map(assigns.map(a => [a.fileNumber, a]));
  }, [payout]);

  const markPaid = useCallback(() => {
    store.addOrUpdatePayout({
      id: payout.id,
      initials: payout.initials,
      paidOn: new Date(),
    });
  }, [payout, store]);

  const updateLatest = useCallback(() => {
    store.updatePayoutWithPaidJobs(payout);
  }, [payout]);

  if (!assignmentsByFileNumberResult) {
    return <CircularProgress />
  }
  if (assignmentsByFileNumberResult.isErr()) {
    return <Alert severity="error">Failed to load assignments: {errorToString(assignmentsByFileNumberResult.error)}</Alert>;
  }
  const assignments = assignmentsByFileNumberResult.value;

  return <Stack direction="column" spacing={2}>
    <Typography variant="h4">Appraiser Payout</Typography>
    <Stack direction="row" spacing={2}>
      <Button variant="contained" onClick={() => setLocation(`/payroll/${payout.id}/edit`)}>Edit</Button>
      {payout.paidOn ? null : <Button variant="contained" onClick={markPaid}>Mark Paid Out</Button>}
      {payout.paidOn ? null : <Button variant="contained" onClick={updateLatest}>Update</Button>}
    </Stack>
    <Stack direction="row" spacing={2}>
      <ItemWithTitle title="Appraiser">
        <Typography fontWeight="bold">{payout.initials}</Typography>
      </ItemWithTitle>
      <ItemWithTitle title="Notes">
        <Typography>{payout.description}</Typography>
      </ItemWithTitle>
    </Stack>
    <Stack direction="row" spacing={2}>
      <ItemWithTitle title="status">
        <PayoutStatusChip payout={payout} />
      </ItemWithTitle>
      <ItemWithTitle title="paid out on">
        <DateDisplay missingText="N/A" date={payout.paidOn} />
      </ItemWithTitle>
    </Stack>
    <ItemWithTitle title="Earned Commission">
      <Typography fontWeight="bold">{formatDollarsCents(payout.earnedDollars ?? 0)}</Typography>
    </ItemWithTitle>
    <ItemWithTitle title="Jobs">
      <List dense>
        {payout.associatedFileNumbers?.map(fileNumber => {
          const a = assignments.get(fileNumber)!;
          if (!a) {
            return null;
          }
          return <ListItem key={fileNumber} dense disablePadding>
            <ListItemText primary={
              <Link href={`/jobs/${fileNumber}`}><Typography>Job {fileNumber}</Typography></Link>
            }
              secondary={`Commission of ${formatDollarsCents(a.commissionDollars ?? 0)} @ ${a.commissionPercent}%`}
            />
          </ListItem>
        })}
      </List>
    </ItemWithTitle>
  </Stack>;
}
