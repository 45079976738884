import { Job, Client, Invoice } from "@/model";
import { Tags } from "@/tags";
import dayjs from "dayjs";
import { generateExcel } from "mr-excel";

export async function exportJobsToExcel(jobs: Job[]): Promise<void> {
  await generateExcel({
    creator: "Job Tracker",
    fileName: "joblist",
    styles: {
      header: {},
      currency: {
        format: "dollar",
      },
      date: {
        format: "short_date",
      },
      percent: {
        format: "percentage",
      },
      body: {},
    },
    generateType: "binarystring",
    sheet: [
      {
        name: "Jobs",
        sortAndFilter: {
          mode: 'ref',
          ref: "A1:Z1",
        },
        viewOption: {
          frozenOption: {
            type: 'BOTH',
            index: 1,
          },
        },
        styleCellCondition(_1, _2, _3, colIndex, fromHeader) {
          if (fromHeader) {
            return "header";
          }

          const l = this.headers[colIndex]!.label;

          if (l == "fee" || l == "paidAmount") {
            return "currency";
          }
          if (["startedOn", "completedOn", "dueOn", "paidOn", "receivedOn"].indexOf(l) > -1) {
            return "date";
          }
          return "body";
        },
        headers: [
          { label: "fileno", text: "File #" },
          { label: "project", text: "Project" },
          { label: "tags", text: "Tags" },
          { label: "comments", text: "Comments" },
          { label: "propertyAddress", text: "Property Address" },
          { label: "propertyCity", text: "City" },
          { label: "propertyState", text: "State" },
          { label: "propertyCounty", text: "County" },
          { label: "propertyZipcode", text: "Zipcode" },
          { label: "propertySubdivision", text: "Subdivision" },
          { label: "intendedUser", text: "Intended User" },
          { label: "fee", text: "Fee" },
          { label: "appraisers", text: "Appraiser(s)" },
          { label: "reviewers", text: "Reviewer(s)" },
          { label: "receivedOn", text: "Received On" },
          { label: "delievered", text: "Delivered On" },
          { label: "dueOn", text: "Due On" },
          { label: "paidOn", text: "Paid On" },
          { label: "client", text: "Client" },
          { label: "occupant", text: "Occupant" },
          { label: "owner", text: "Owner" },
          { label: "estimatedValue", text: "Appraised Value" },
        ],
        data: jobs.map(a => ({
          fileno: a.fileNumber,
          tags: [...(a.tags?.values() ?? [])].map(t => Tags.get(t)).join(";"),
          project: a.project ?? "",
          comments: a.comments,
          propertyAddress: a.streetAddress,
          propertyCity: a.city,
          propertyState: a.state,
          propertyCounty: a.county,
          propertyZipcode: a.zipcode,
          propertySubdivision: a.subdivisionOrProject,
          intendedUser: a.intendedUserName ?? "",
          estimatedValue: a.appraisedValueDollars !== null ? `$${a.appraisedValueDollars}` : "",
          fee: a.fee?.model === "fixed" ?
            a.fee.priceDollars :
            a.fee?.model === "hourly" ?
              "Hourly" :
              "",
          appraisers: (a.mainAssignedInitials ?? []).join("; "),
          reviewers: (a.reviewerAssignedInitials ?? []).join("; "),
          receivedOn: a.receivedOn ? dayjs(a.receivedOn).format('MM/DD/YYYY') : "",
          inspectedOn: a.inspectedOn ? dayjs(a.inspectedOn).format('MM/DD/YYYY') : "",
          dueOn: a.dueOn ? dayjs(a.dueOn).format('MM/DD/YYYY') : "",
          deliveredOn: a.deliveredOn ? dayjs(a.deliveredOn).format('MM/DD/YYYY') : "",
          effectiveDate: a.effectiveDate ? dayjs(a.effectiveDate).format('MM/DD/YYYY') : "",
          paidOn: a.paidOn ? dayjs(a.paidOn).format('MM/DD/YYYY') : "",
          client: a.clientName ?? "",
          occupant: a.occupantName ?? "",
          owner: a.ownerName ?? "",
        })),
      },
    ],
  });
}

export async function exportInvoicesToExcel(invoices: Invoice[]): Promise<void> {
  await generateExcel({
    creator: "Job Tracker",
    fileName: "invoices",
    styles: {
      header: {},
      currency: {
        format: "dollar",
      },
      date: {
        format: "short_date",
      },
      percent: {
        format: "percentage",
      },
      body: {},
    },
    generateType: "binarystring",
    sheet: [
      {
        name: "Invoices",
        sortAndFilter: {
          mode: 'ref',
          ref: "A1:Z1",
        },
        viewOption: {
          frozenOption: {
            type: 'BOTH',
            index: 1,
          },
        },
        styleCellCondition(_1, _2, _3, colIndex, fromHeader) {
          if (fromHeader) {
            return "header";
          }

          const l = this.headers[colIndex]!.label;

          if (l == "paidDollars" || l == "totalDollars") {
            return "currency";
          }
          if (["createdOn", "deliveredOn", "closedOn"].indexOf(l) > -1) {
            return "date";
          }
          return "body";
        },
        headers: [
          { label: "invoiceNumber", text: "Invoice #" },
          { label: "title", text: "Title" },
          { label: "billToName", text: "Client Name" },
          { label: "createdOn", text: "Created On" },
          { label: "deliveredOn", text: "Billed On" },
          { label: "closedOn", text: "Closed On" },
          { label: "status", text: "Status" },
          { label: "totalDollars", text: "Billed Amount" },
          { label: "paidDollars", text: "Paid Amount" },
          { label: "fileNumbers", text: "Job File Numbers" },
        ],
        data: invoices.map(a => ({
          ...a,
          createdOn: a.createdOn ? dayjs(a.createdOn).format('MM/DD/YYYY') : "",
          deliveredOn: a.deliveredOn ? dayjs(a.deliveredOn).format('MM/DD/YYYY') : "",
          closedOn: a.closedOn ? dayjs(a.closedOn).format('MM/DD/YYYY') : "",
          fileNumbers: a.associatedFileNumbers?.join("; "),
        })),
      },
    ],
  });
}

export async function exportClientsToExcel(clients: Client[]): Promise<void> {
  await generateExcel({
    creator: "Job Tracker",
    fileName: "clientlist",
    styles: {
      header: {},
      date: {
        format: "short_date",
      },
      body: {},
    },
    generateType: "binarystring",
    sheet: [
      {
        name: "Clients",
        sortAndFilter: {
          mode: 'ref',
          ref: "A1:Z1",
        },
        protectionOption: {
          sheet: 1,
          formatCells: 0,
          formatColumns: 0,
          formatRows: 0,
          insertColumns: 0,
          insertRows: 0,
          insertHyperlinks: 0,
          deleteColumns: 0,
          deleteRows: 0,
          sort: 0,
          autoFilter: 0,
          pivotTables: 0,
        },
        viewOption: {
          frozenOption: {
            type: 'BOTH',
            index: 1,
          },
        },
        styleCellCondition(_1, _2, _3, colIndex, fromHeader) {
          if (fromHeader) {
            return "header";
          }

          const l = this.headers[colIndex]!.label;

          if (["mostRecent"].indexOf(l) > -1) {
            return "date";
          }
          return "body";
        },
        headers: [
          { label: "name", text: "Name" },
          { label: "address1", text: "Address 1" },
          { label: "address2", text: "Address 2" },
          { label: "city", text: "City" },
          { label: "state", text: "State" },
          { label: "zipcode", text: "Zipcode" },
          { label: "phone", text: "Phone" },
          { label: "fax", text: "Fax" },
          { label: "email", text: "Email" },
          { label: "contactName", text: "Contact Name" },
          { label: "title", text: "Contact Title" },
          { label: "jobCount", text: "# of Jobs" },
        ],
        data: clients.map(c => ({
          name: c.name,
          address1: c.address1,
          address2: c.address2,
          city: c.city,
          state: c.state,
          zipcode: c.zipcode,
          phone: c.phone,
          fax: c.fax,
          email: c.email,
          contactName: c.contactName,
          title: c.title,
          jobCount: c.associatedJobs?.length,
        })),
      },
    ],
  });
}
