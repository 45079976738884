import { StoreContext } from "@/hooks/useStore";
import { useStore } from "@/hooks/useStore";
import { Invoice, InvoiceNumber } from "@/model";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useMemo } from "react";
import { useCallback, useContext, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useLocation } from "wouter";
import DatePickerField from "./helpers/DatePickerField";
import ClientInput from "./JobEdit/ClientInput";
import { z } from "zod";

export function InvoiceEditByInvoiceNumber({ invoiceNumber }: { invoiceNumber: string }) {
  const [invoice, setInvoice] = useState<Invoice | null | undefined>(undefined);
  useStore(async store => {
    setInvoice(undefined);
    const invoices = await store.listInvoices({ type: "invoiceNumbers", invoiceNumbers: [invoiceNumber] });
    if (invoices.length === 0) {
      setInvoice(null);
    } else {
      setInvoice(invoices[0]);
    }
  }, [invoiceNumber]);

  if (invoice === undefined) {
    return <CircularProgress />;
  }
  if (invoice === null) {
    return <Box>Invoice #{invoiceNumber} not found.</Box>;
  }
  return <InvoiceEdit invoice={invoice} />;
}


export default function InvoiceEdit({ invoice }: { invoice: Invoice | null }) {
  const [_, setLocation] = useLocation();
  const [saving, setSaving] = useState(false);
  const [saveErr, setSaveErr] = useState<null | string>(null);
  const store = useContext(StoreContext);

  const EditableInvoice = useMemo(() => Invoice.extend({
    invoiceNumber: InvoiceNumber.refine(async v => {
      if (invoice) {
        return true;
      }
      try {
        const invoices = await store.listInvoices({ type: "invoiceNumbers", invoiceNumbers: [v] });
        return invoices.length === 0;
      } catch (e) {
        console.error("Failed to validate invoice number", e);
        return false;
      }
    }, { message: "This invoice number is already used" }),
    deliveredOn: Invoice.shape.deliveredOn.nullable(),
    closedOn: Invoice.shape.deliveredOn.nullable(),
    billToId: Invoice.shape.billToId.nullable(),
    billToName: Invoice.shape.billToName.nullable(),
  }), [invoice]);

  const methods = useForm<z.infer<typeof EditableInvoice>>({
    mode: "onBlur",
    defaultValues: invoice ?? {},
    resolver: zodResolver(EditableInvoice),
  }),
    { watch, setValue, formState: { errors }, register, handleSubmit } = methods;

  const billToId = watch("billToId");
  const billToName = watch("billToName");

  const onSubmit = useCallback(async (value: z.infer<typeof EditableInvoice>) => {
    console.log("submitting invoice");
    setSaveErr(null);
    setSaving(true);
    try {
      await store.addOrUpdateInvoice(value);
      setLocation(`/invoices/${value.invoiceNumber}`, { replace: true });
    } catch (e) {
      setSaveErr("Failed to save invoice");
      console.error(e);
    } finally {
      setSaving(false);
    }
  }, [store]);

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit, err => console.error(err))}>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <InvoiceNumberInput invoice={invoice} />
          </Stack>
          <TextField
            {...register("title")}
            helperText={errors.title?.message}
            error={!!errors.title}
            sx={{ width: '35em' }}
            variant="standard"
            label="Invoice Title"
          />
          {invoice ? <>
            <DatePickerField
              name="deliveredOn"
              label="Billed On"
            />
            <DatePickerField
              name="closedOn"
              label="Closed Date"
            />
          </> :
            null}
          <Stack direction="row" spacing={2}>
            <ClientInput
              value={billToId ? { id: billToId, name: billToName } : null}
              onChange={v => {
                console.log("client updated", v);
                setValue("billToId", v?.id ?? null);
                setValue("billToName", v?.name ?? null);
              }}
              label="Client"
              variant="standard"
            />
          </Stack>
          <Stack direction="row" spacing={2} alignSelf="center">
            <Button type="submit" variant="contained" disabled={saving}>Submit</Button>
            <Button variant="text" disabled={saving} onClick={() => {
              setLocation(`/invoices` + (invoice ? `/${invoice.invoiceNumber}` : ""));
            }}>Cancel</Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
    {saveErr ?
      <Alert severity="error">{saveErr}</Alert> :
      null}
  </LocalizationProvider>;
}

function InvoiceNumberInput(props: { invoice: Invoice | null }) {
  const store = useContext(StoreContext);
  const [nextInvoiceNumber, setNextInvoiceNumber] = useState<string | undefined>(undefined);
  const { formState: { errors }, register } = useFormContext<Invoice>();

  useEffect(() => {
    if (!props.invoice) {
      store.suggestNextInvoiceNumber().then(num => {
        setNextInvoiceNumber(num);
      });
    }
  }, [props.invoice]);

  if (props.invoice) {
    return <Box><Typography variant="h6">Invoice #{props.invoice.invoiceNumber}</Typography></Box>;
  }

  return props.invoice === null && !nextInvoiceNumber ?
    <CircularProgress /> :
    <Stack direction="row" spacing={2}>
      <TextField
        {...register("invoiceNumber")}
        autoFocus
        error={!!errors.invoiceNumber}
        helperText={errors.invoiceNumber ? errors.invoiceNumber.message : !props.invoice ? `Suggested invoice number is ${nextInvoiceNumber}` : ""}
        label="Invoice #"
        defaultValue={nextInvoiceNumber}
        InputProps={{
          endAdornment: nextInvoiceNumber ? null : <CircularProgress color="inherit" size={20} />,
        }}
        variant="standard"
      />
    </Stack>
}


