import { TableSortLabel } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

export type Direction = "asc" | "desc";

interface FactoryProps<T extends string> {
  field: T;
  children: React.ReactNode;
}

export function useSingleFieldSorting<T extends string>(
  defaultField: T,
  defaultDirection: Direction
): [T, Direction, React.JSXElementConstructor<FactoryProps<T>>] {
  const [sortField, setField] = useState<T>(defaultField);
  const [direction, setDirection] = useState<Direction>(defaultDirection);

  const onSortChanged = useCallback((newField: T) => {
    if (sortField === newField) {
      setDirection(direction === "asc" ? "desc" : "asc");
    } else {
      setField(newField);
      setDirection("asc");
    }
  }, [sortField, direction]);

  const labelFactory = useMemo(() => (props: FactoryProps<T>) =>
    <SortLabel
      field={props.field}
      sortField={sortField}
      direction={direction}
      onSortChanged={onSortChanged}
    >{props.children}</SortLabel>,
    [sortField, direction, onSortChanged]);

  return [sortField, direction, labelFactory];
}

export function useSorting<T extends Record<string, any>>(data: T[] | undefined, sortField: keyof T, direction: Direction): T[] {
  return useMemo(() =>
    [...data || []].sort((a, b) => {
      if (a[sortField] < b[sortField]) return direction === "asc" ? -1 : 1;
      if (a[sortField] > b[sortField]) return direction === "asc" ? 1 : -1;
      else return 0;
    }), [data, sortField, direction]
  );
}

interface SortLabelProps<T extends string> {
  field: T;
  sortField: T;
  direction: Direction;
  onSortChanged: (field: T) => void;
  children?: React.ReactNode;
}

export function SortLabel<T extends string>({ field, sortField, direction, onSortChanged, children }: SortLabelProps<T>) {
  const active = field === sortField;
  return <TableSortLabel
    active={active}
    direction={active ? direction : undefined}
    onClick={() => onSortChanged(field)}
  >
    {children}
  </TableSortLabel>;
}
