import useClientQueryDebounced from "@/hooks/useClientQueryDebounced";
import { Client } from "@/model";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useRef } from "react";


interface Props {
  value?: string,
  onChange: (v: string | null) => void;
  label?: string;
  placeholder?: string;
}

export default function ClientNameAutocomplete({ value, onChange, label, placeholder }: Props) {
  const clientNameRef = useRef<HTMLInputElement>(null);

  const [clientsResult, loading] = useClientQueryDebounced({
    ref: clientNameRef,
  });

  let clients = [] as Client[];
  if (clientsResult === undefined) { }
  else if (clientsResult.isErr()) {
    console.error("Failed to fetch client autocomplete results", clientsResult.error);
  } else {
    clients = [...clientsResult.value.values()];
  }

  return <Autocomplete
    value={value ?? null}
    loading={clients === null}
    onChange={(_, newValue) => { onChange(newValue ?? null) }}
    filterOptions={(options) => options}
    freeSolo
    autoSelect
    selectOnFocus
    //autoHighlight
    includeInputInList
    handleHomeEndKeys
    options={clients ? clients.map(a => a.name) : []}
    renderInput={(params) => (
      <TextField
        {...params}
        ref={clientNameRef}
        label={label ?? "Client Name"}
        sx={{ minWidth: '15em' }}
        placeholder={placeholder}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />
    )}
  />;
}
