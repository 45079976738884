import { Appraiser, Client, Job, Payout, Invoice, Assignment, LineItem, Transaction } from "@/model";
import { CollectionReference, DocumentData, DocumentReference, Firestore, FirestoreDataConverter, PartialWithFieldValue, QueryDocumentSnapshot, collection, doc } from "firebase/firestore";
import { z } from "zod";

export interface Converter<T> extends FirestoreDataConverter<T, DocumentData> {
  fromDoc(doc: DocumentData): T;
  generateID(o: T): string;
  readonly collectionName: string;
  collectionRef(db: Firestore): CollectionReference<T>;
  docPath(o: T): string;
  docRef(db: Firestore, o: T): DocumentReference<T>;
}

export interface ConverterWithParent<T, P> extends Converter<T> {
  readonly parentConverter: Converter<P>;
  generateParentID(o: T): string;
  subcollectionRef(db: Firestore, parent: P): CollectionReference<T>;
}

function convertTimestampsToDate(doc: DocumentData) {
  for (const k of Object.keys(doc)) {
    if (!doc[k]) continue;

    if (typeof doc[k].toDate === "function") {
      doc[k] = doc[k].toDate();
    } else if (typeof doc[k] === "object") {
      convertTimestampsToDate(doc[k]);
    }
  }
}

function generateConverter<T extends z.ZodObject<any>>(collectionName: string, model: T, generateID: (o: z.infer<T>) => string): Converter<z.infer<T>> {
  return {
    collectionName,
    generateID,
    toFirestore(o: PartialWithFieldValue<z.infer<T>>): z.infer<T> {
      return o;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): z.infer<T> {
      return this.fromDoc(snapshot.data());
    },
    fromDoc(doc: DocumentData): z.infer<T> {
      convertTimestampsToDate(doc);
      return model.passthrough().parse(doc);
    },
    collectionRef(db: Firestore) {
      return collection(db, this.collectionName).withConverter(this);
    },
    docPath(o: z.infer<T>) {
      return `/${this.collectionName}/${this.generateID(o)}`;
    },
    docRef(db: Firestore, o: z.infer<T>) {
      return doc(this.collectionRef(db), this.generateID(o)).withConverter(this);
    },
  }
}

function generateConverterWithParent<T extends z.ZodObject<any>, P extends z.ZodObject<any>>(
  collectionName: string,
  model: T,
  generateID: (o: z.infer<T>) => string,
  generateParentID: (o: z.infer<T>) => string,
  parentConverter: Converter<z.infer<P>>
): ConverterWithParent<z.infer<T>, z.infer<P>> {
  return {
    ...generateConverter(collectionName, model, generateID),
    generateParentID,
    parentConverter,
    subcollectionRef(db: Firestore, o: z.infer<T>): CollectionReference {
      return collection(db,
        this.parentConverter.collectionName, this.generateParentID(o),
        this.collectionName).withConverter(this);
    },
    docPath(o: z.infer<T>) {
      return `/${this.parentConverter.collectionName}/${this.generateParentID(o)}/${this.collectionName}/${this.generateID(o)}`;
    },
    docRef(db: Firestore, o: z.infer<T>) {
      return doc(this.subcollectionRef(db, o), this.generateID(o)).withConverter(this);
    }
  };
}

export const JobConverter = generateConverter(
  "jobs",
  Job,
  a => a.fileNumber.toUpperCase()
);

export const AssignmentConverter = generateConverter(
  "assignments",
  Assignment,
  a => `${a.fileNumber}_${a.initials.toUpperCase()}`,
);

export const AppraiserConverter = generateConverter(
  "appraisers",
  Appraiser,
  a => a.initials.toUpperCase()
);

export const ClientConverter = generateConverter(
  "clients",
  Client,
  p => p.id,
);

export const InvoiceConverter = generateConverter(
  "invoices",
  Invoice,
  i => i.invoiceNumber
);

export const LineItemConverter = generateConverterWithParent<typeof LineItem, typeof Invoice>(
  "lineItems",
  LineItem,
  i => i.id,
  i => i.invoiceNumber,
  InvoiceConverter
);

export const TransactionConverter = generateConverterWithParent<typeof Transaction, typeof Invoice>(
  "transactions",
  Transaction,
  p => p.id,
  p => p.invoiceNumber,
  InvoiceConverter
);

export const PayoutConverter = generateConverter<typeof Payout>(
  "payouts",
  Payout,
  d => d.id,
);
