import { StoreContext } from "@/hooks/useStore";
import { Assignment, FileNumber } from "@/model";
import React, { useContext, useMemo } from "react";
import { Alert, Button, CircularProgress, Typography } from "@mui/material";
import useDialog from "@/hooks/useDialog";
import wrapPromise from "@/util/wrapPromise";
import { errorToString } from "@/format/errors";
import { AddOrEditAssignmentDialog } from "./AddOrEditAssignmentDialog";
import { AssignmentRow } from "./AssignmentRow";
import ItemWithTitle from "../helpers/ItemWithTitle";
import { useStoreWatchCollection } from "@/hooks/useStoreWatch";
import { AssignmentFilter } from "@/store/store";

export default function AssignmentsDisplay({ fileNumber }: { fileNumber: FileNumber; }) {
  const store = useContext(StoreContext);

  const { showDialog, props: dialogProps } = useDialog<Assignment>(async (assignment) => {
    console.log(assignment);
    return await wrapPromise(store.addOrUpdateAssignment(assignment));
  }, [fileNumber, store]);

  const filter = useMemo<AssignmentFilter[]>(() => ([{ type: "fileNumber", fileNumbers: [fileNumber] }]), [fileNumber]);
  const assignmentsResult = useStoreWatchCollection(s => s.watchAssignments, filter);

  if (!assignmentsResult) {
    return <CircularProgress />;
  }
  if (assignmentsResult.isErr()) {
    return <Alert severity="error">Failed to load assignments: {errorToString(assignmentsResult.error)}</Alert>;
  }

  const assignments = assignmentsResult.value;
  return <ItemWithTitle title="Assignments">
    {!assignments || assignments.length === 0 ?
      <Typography color="text.secondary">Unassigned</Typography> :
      [...assignments.filter(a => !a.isReviewer),
      ...assignments.filter(a => a.isReviewer)].map(a =>
        <AssignmentRow
          key={a.initials}
          assignment={a}
          fileNumber={fileNumber}
          startEditing={() => showDialog(a)}
          onDelete={async () => {
            const res = await wrapPromise(store.removeAssignment(fileNumber, a.initials));
            if (res.isErr()) {
              console.error("failed to delete assignment", res.error);
            }
          }}
        />)}
    <Button variant="text" onClick={() => showDialog({fileNumber, initials: ""})}>Add Assignment</Button>
    <AddOrEditAssignmentDialog {...dialogProps} />
  </ItemWithTitle>;
}

