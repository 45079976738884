import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "./useStore";
import { Result } from "ts-results-es";
import { Client, ClientID } from "@/model";
import { debounceTime, fromEvent } from "rxjs";
import { ClientFilter } from "@/store/store";
import wrapPromise from "@/util/wrapPromise";

interface Props {
  ref: React.RefObject<HTMLInputElement>;
  maxResults?: number;
  extraFilters?: ClientFilter[];
  debounceMillis?: number;
}

export default function useClientQueryDebounced(props: Props): [Result<Map<ClientID, Client>, unknown> | undefined, boolean] {
  const store = useContext(StoreContext);
  const [clientsResult, setClientsResult] = useState<Result<Map<ClientID, Client>, unknown> | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const sub = fromEvent(props.ref.current!, 'input').pipe(
      debounceTime(props.debounceMillis ?? 300),
    ).subscribe(async (e: Event) => {
      const val = (e.target as HTMLInputElement)?.value ?? "" as string;
      if (val.length < 2) {
        setClientsResult(undefined);
        return;
      }

      const filters: ClientFilter[] = [
        { type: 'clientNamePrefix', clientNamePrefix: val },
        ...props.extraFilters ?? [],
      ];
      setLoading(true);
      const clients = await wrapPromise(
        store.listClients(props.maxResults ?? 100, ...filters)
          .then(cs => cs.sort((a, b) => (a.name ?? "").localeCompare(b.name ?? "")))
      );
      setLoading(false);
      setClientsResult(clients.map(cs => new Map(cs.map(c => [c.id, c]))));
    });

    return () => sub.unsubscribe();
  }, [props.extraFilters]);

  return [clientsResult, loading];
}
