import { Err, Ok, Result } from "ts-results-es";

// Wrap a promise with a result that captures any errors thrown by the promise. This guarantees that
// you can await the promise without any exceptions.
export default async function wrapPromise<T>(p: Promise<T>): Promise<Result<T, unknown>> {
  try {
    return Ok(await p);
  } catch (e) {
    return Err(e);
  }
}
