import { Client } from "@/model";
import { Stack, Typography } from "@mui/material";
import React from "react";

export default function ClientDisplay({ client }: { client: Client }) {
  return <Stack direction="column" spacing={0}>
    <Typography>{client.name}</Typography>
    {client.address1 || client.city ? <>
      <Typography>{client.address1}</Typography>
      {client.address2 ? <Typography>{client.address2}</Typography> : null}
      {client.city || client.state || client.zipcode ? <Typography>{client.city}, {client.state} {client.zipcode}</Typography> : null}
    </> : null}
    {client.email ? <Typography><a href={`mailto:${client.email}`}>{client.email}</a></Typography > : null
    }
    {client.phone ? <Typography>{client.phone}</Typography> : null}
    {client.title || client.contactName ? <Typography>{client.title} {client.contactName}</Typography> : null}
  </Stack>;
}
