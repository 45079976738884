import { Client } from "@/model";
import { Alert, Button, CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";
import { errorToString } from "@/format/errors";
import ItemWithTitle from "./helpers/ItemWithTitle";
import ClientDisplay from "./helpers/ClientDisplay";
import { Link, useLocation } from "wouter";
import { useStoreWatchOne } from "@/hooks/useStoreWatch";

export function ClientDetailByID({ id }: { id: string }) {
  const clientResult = useStoreWatchOne(s => s.watchClient, id);

  if (!clientResult) {
    return <CircularProgress />;
  }

  if (clientResult.isErr()) {
    return <Alert severity="error">Failed to load client {id}: {errorToString(clientResult.error)}</Alert>
  }

  const client = clientResult.value;
  if (!client) {
    return <Typography>Client {id} not found</Typography>;
  }

  return <ClientDetail client={client} />;
}

export function ClientDetail({ client }: { client: Client }) {
  const [_, setLocation] = useLocation();

  return <Stack direction="column" spacing={2}>
    <Stack direction="row" spacing={2}>
      <Button variant="contained" onClick={() => setLocation(`/clients/${client.id}/edit`)}>Edit</Button>
    </Stack>
    <Typography variant="h4">Client: {client.name}</Typography>
    <ItemWithTitle title="Client">
      <ClientDisplay client={client} />
    </ItemWithTitle>
    <ItemWithTitle title="jobs">
      <Link href="/jobs" state={{ filterOptions: { clientNamePrefix: client.name } }}><Typography>View Jobs</Typography></Link>
    </ItemWithTitle>
    <ItemWithTitle title="invoices">
      <Link href="/invoices" state={{ filterOptions: { billToNamePrefix: client.name } }}><Typography>View Invoices</Typography></Link>
    </ItemWithTitle>
  </Stack>;
}
