import { Job, InvoiceNumber } from "@/model"
import React, { useCallback, useContext } from "react";
import { Alert, Box, Chip, CircularProgress, Stack, Typography } from "@mui/material";
import { Link, useLocation } from "wouter";
import DateDisplay, { formatDate } from "@/components/helpers/DateDisplay";
import { Tags } from "@/tags";
import ItemWithTitle from "@/components/helpers/ItemWithTitle";
import formatDollarsCents from "@/format/formatDollarsCents";
import { errorToString } from "@/format/errors";
import AssignmentsDisplay from "./AssignmentsDisplay";
import { useStoreWatchOne } from "@/hooks/useStoreWatch";
import useDialog from "@/hooks/useDialog";
import wrapPromise from "@/util/wrapPromise";
import { StoreContext } from "@/hooks/useStore";
import { LinkInvoiceDialog } from "./LinkInvoiceDialog";
import useCurrentUser from "@/hooks/useUser";
import ActionButton from "../helpers/ActionButton";
import JobStatusChip from "../helpers/JobStatusChip";

interface Props {
  fileNumber: Job['fileNumber'];
}

export function JobDetailByFileNumber(props: Props) {
  const jobResult = useStoreWatchOne(s => s.watchJob, props.fileNumber);

  if (!jobResult) {
    return <CircularProgress />;
  }

  if (jobResult.isErr()) {
    return <Alert severity="error">Failed to load job {props.fileNumber}: {errorToString(jobResult.error)}</Alert>
  }

  return <JobDetail job={jobResult.value} />;
}

export function JobDetail({ job }: { job: Job }) {
  const [_, setLocation] = useLocation();
  const store = useContext(StoreContext);
  const user = useCurrentUser();
  const fileNumber = job.fileNumber;

  const linkJob = useCallback(async (invoiceNumber: InvoiceNumber | null) => {
    if (invoiceNumber === null) {
      invoiceNumber = await store.suggestNextInvoiceNumber();
      await store.addOrUpdateInvoice({
        invoiceNumber,
        billToId: job.billToId ?? job.clientId,
        title: job.title,
      });
    }

    return store.addOrUpdateJob({
      fileNumber: job.fileNumber,
      invoiceNumber,
    });
  }, [store, job.fileNumber]);

  const { showDialog: showLinkInvoiceDialog, props: linkDialogProps } = useDialog((v: InvoiceNumber | null) => wrapPromise(linkJob(v)), [linkJob]);

  const reopenJob = useCallback(async () => {
    await store.addOrUpdateJob({
      fileNumber,
      closedOn: null,
    });
  }, [store, fileNumber]);

  const closeJob = useCallback(async () => {
    await store.addOrUpdateJob({
      fileNumber,
      closedOn: new Date(),
    });
  }, [store, fileNumber]);

  return <Stack direction="column" spacing={2} m={2}>
    <Stack direction="row" spacing={2}>
      <ActionButton onClick={() => setLocation(`/jobs/${job.fileNumber}/edit`)}>Edit</ActionButton>
      <ActionButton onClick={() => { showLinkInvoiceDialog() }}>Link to Invoice</ActionButton>
      {job.closedOn ?
        <ActionButton onClick={() => { reopenJob() }}>Reopen Job</ActionButton> :
        job.status !== "closed" ?
          <ActionButton onClick={() => { closeJob() }}>Close Job</ActionButton> :
          null
      }
      <LinkInvoiceDialog {...linkDialogProps} />
    </Stack>
    <Stack direction="row" spacing={2}>
      <ItemWithTitle title="file #">
        <Typography>{job.fileNumber}</Typography>
      </ItemWithTitle>
      <ItemWithTitle title="project">
        <Link href="/jobs" state={{ filterOptions: { projectPrefix: job.project } }}><Typography>{job.project}</Typography></Link>
      </ItemWithTitle>
      <ItemWithTitle title="Tags">
        {[...(job.tags?.values() ?? [])].map(t => <Chip key={t} label={Tags.get(t) ?? "?"} variant="outlined" />)}
      </ItemWithTitle>
    </Stack>
    <ItemWithTitle title="Notes">{(job.comments ?? "").split("\n").map(p => <Typography key={p}>{p}</Typography>)}</ItemWithTitle>
    <ItemWithTitle title="status">
      <Stack direction="row" spacing={1}>
        <JobStatusChip job={job} />
      </Stack>
    </ItemWithTitle>
    <Stack direction="row" spacing={2}>
      <ItemWithTitle title="Property Address">
        <Typography>{job.streetAddress}</Typography>
        <Typography>{job.city ? `${job.city}, ` : null}{job.state ?? ""} {job.zipcode}</Typography>
        {job.county ? <Typography>{job.county} County</Typography> : null}
      </ItemWithTitle>
      <ItemWithTitle title="Subdivision/Neighborhood">
        <Typography>{job.subdivisionOrProject ?? ""}</Typography>
      </ItemWithTitle>
    </Stack>
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2}>
        <ItemWithTitle title="Received On">
          <DateDisplay date={job.receivedOn} missingText="Not received" />
        </ItemWithTitle>
        <ItemWithTitle title="On Hold On">
          <DateDisplay date={job.onHoldOn} missingText="N/A" />
        </ItemWithTitle>
        <ItemWithTitle title="Off Hold On">
          <DateDisplay date={job.offHoldOn} missingText="N/A" />
        </ItemWithTitle>
        <ItemWithTitle title="due on">
          <DateDisplay date={job.dueOn} missingText="No due date" />
        </ItemWithTitle>
        <ItemWithTitle title="effective date">
          <DateDisplay date={job.effectiveDate} missingText={job.inspectedOn ? formatDate(job.inspectedOn) : "Unknown"} />
        </ItemWithTitle>
      </Stack>
      <Stack direction="row" spacing={2}>
        <ItemWithTitle title="inspected on">
          <DateDisplay date={job.inspectedOn} missingText="Not inspected" />
        </ItemWithTitle>
        <ItemWithTitle title="reviewed on">
          <DateDisplay date={job.reviewedOn} missingText="Not reviewed" />
        </ItemWithTitle>
        <ItemWithTitle title="delievered on">
          <DateDisplay date={job.deliveredOn} missingText="Not delivered" />
        </ItemWithTitle>
        <ItemWithTitle title="closed on">
          <DateDisplay date={job.closedOn ?? job.paidOn} missingText="Still open" />
        </ItemWithTitle>
      </Stack>
    </Stack>
    <Stack direction="row" spacing={2}>
      <ItemWithTitle title="Fee">
        <Box>{!job.fee ?
          <Typography color="text.secondary">Unknown</Typography>
          : (job.fee.model === "fixed") ?
            <Typography fontWeight={700}>{formatDollarsCents(job.totalFeeDollars ?? job.fee.priceDollars ?? 0)}</Typography> :
            <Typography fontWeight={700}>{formatDollarsCents(job.totalFeeDollars ?? 0)} (Hourly)</Typography>}
        </Box>
      </ItemWithTitle>
      {user.isAdmin ?
        <ItemWithTitle title="Invoice">
          {job.invoiceNumber ?
            <Typography><Link href={`/invoices/${job.invoiceNumber}`}>{job.invoiceNumber}</Link></Typography> :
            <Typography color="text.secondary">None</Typography>}
        </ItemWithTitle> :
        null}
      <ItemWithTitle title="paid on">
        <DateDisplay date={job.paidOn} missingText="Not paid" />
      </ItemWithTitle>
    </Stack>
    <Stack direction="row" spacing={2}>
      <ItemWithTitle title="Client">
        {job.clientId ?
          user.isAdmin ?
            <Link href={`/clients/${job.clientId}`}><Typography>{job.clientName}</Typography></Link> :
            <Typography>{job.clientName}</Typography>
          :
          <Typography color="text.secondary">None</Typography>}
      </ItemWithTitle>
      {job.intendedUserId ?
        <ItemWithTitle title="Intended User">
          <Link href={`/clients/${job.intendedUserId}`}><Typography>{job.intendedUserName}</Typography></Link>
        </ItemWithTitle> :
        null}
      {job.billToId ?
        <ItemWithTitle title="Bill To">
          <Link href={`/clients/${job.billToId}`}><Typography>{job.billToName}</Typography></Link>
        </ItemWithTitle> :
        null}
      <ItemWithTitle title="Owner">
        <AddressDisplay job={job} prefix="owner" />
      </ItemWithTitle>
      <ItemWithTitle title="Occupant">
        <AddressDisplay job={job} prefix="occupant" />
      </ItemWithTitle>
      <ItemWithTitle title="Contact">
        <AddressDisplay job={job} prefix="contact" />
      </ItemWithTitle>
    </Stack>
    <Stack direction="row" spacing={2}>
      <AssignmentsDisplay fileNumber={job.fileNumber} />
    </Stack>
  </Stack >;
}

function AddressDisplay({ job, prefix }: { job: Job, prefix: "owner" | "occupant" | "contact" }) {
  const name = job[`${prefix}Name`];
  const address = job[`${prefix}Address`];
  const city = job[`${prefix}City`];
  const state = job[`${prefix}State`];
  const zipcode = job[`${prefix}Zipcode`];
  const email = job[`${prefix}Email`];
  const phone = job[`${prefix}Phone`];

  return <Stack direction="column" spacing={0}>
    <Typography>{name}</Typography>
    {address || city ? <>
      <Typography>{address}</Typography>
      {city || state || zipcode ? <Typography>{city}, {state} {zipcode}</Typography> : null}
    </> : null}
    {email ? <Typography><a href={`mailto:${email}`}>{email}</a></Typography > : null
    }
    {phone ? <Typography>{phone}</Typography> : null}
  </Stack>;
}
