import { useStoreValue } from "@/hooks/useStore";
import { FileNumber, OpenJobStatuses } from "@/model";
import { Alert, CircularProgress, Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import { DialogProps } from "@/hooks/useDialog";
import { errorToString } from "@/format/errors";
import { PriceDisplay } from "../helpers/PriceDisplay";
import { Article } from "@mui/icons-material";


export function LinkJobDialog({ open, onClose, onSave, error }: DialogProps<FileNumber>) {
  const openJobs = useStoreValue(s =>
    s.listJobs({ type: "status", statuses: OpenJobStatuses }).then(apprs => apprs.sort((a, b) => b.fileNumber.localeCompare(a.fileNumber))),
    []);

  return <Dialog onClose={() => { onClose(); }} open={open}>
    <DialogTitle>Link Job to Invoice</DialogTitle>
    {!openJobs ?
      <CircularProgress /> :
      openJobs.isErr() ?
        <Alert severity="error">Failed to load open jobs: {errorToString(openJobs.error)}</Alert> :
        <List dense>
          {openJobs.value.map(a => <ListItem disablePadding key={a.fileNumber}>
            <ListItemButton onClick={() => {
              onSave(a.fileNumber);
            }}>
              <ListItemIcon>
                <Article />
              </ListItemIcon>
              <ListItemText
                primary={<>
                  <Typography fontWeight="bold" component="span">{a.fileNumber}</Typography>
                  <Typography component="span"> - {a.title ?? ""}</Typography>
                </>}
                secondary={<PriceDisplay fee={a.fee} />} />
            </ListItemButton>
          </ListItem>
          )}
        </List>}
    {error ? <Alert severity="error">{error}</Alert> : null}
  </Dialog>;
}

