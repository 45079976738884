import { ZodRawShape, z } from "zod";

export class LocalSaver<T extends z.ZodObject<ZodRawShape>> {
  constructor(readonly key: string, readonly model: T) { }

  load(defaultValue: z.infer<T>): z.infer<T>;
  load(): z.infer<T> | undefined;
  load(defaultValue?: z.infer<T>): z.infer<T> | undefined {
    const v = localStorage.getItem(this.key);
    if (v === null) {
      return defaultValue;
    }
    let parsed: T;
    try {
      parsed = JSON.parse(v);
      return this.model.parse(parsed);
    } catch (e) {
      console.error("Failed to parse saved object, returning undefined", e);
      localStorage.removeItem(this.key);
      return defaultValue;
    }
  }

  save(filters: z.infer<T>) {
    localStorage.setItem(this.key, JSON.stringify(filters));
  }
};

export interface Saver<T extends z.ZodObject<any>> {
  load(): z.infer<T>;
  save(v: z.infer<T>): void;
}
